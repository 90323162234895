import { ArticlesAndUpdates } from "../../openapi-typescript/common/articlesAndUpdates";
import { useAPIQueries } from "./useAPITypes";
import { ArticlesAndUpdatesFilterValue } from "../../modules/dashboards/widgets/insights/ArticlesAndUpdatesFilters";
import { convertRelativeRangeToNonEmptyRange } from "../../COREDesignSystem/Form/CORERangePicker";

type Pagination = { limit: number; offset: number };

export const useArticlesAndUpdates = (
  paginations: Pagination[],
  params: ArticlesAndUpdatesFilterValue
) => {
  const { categories, type, sortOrder, publishedDate } = params;
  const publishedDateRange =
    publishedDate && convertRelativeRangeToNonEmptyRange(publishedDate);

  const response = useAPIQueries<{
    items: ArticlesAndUpdates;
    pagination: Pagination & { total: number };
  }>(
    "insights",
    paginations.map((pagination) => ({
      params: {
        cmsLocaleId: "64f51b1270fc318bbf205a3f",
        ...(publishedDateRange && {
          "lastPublished[gte]": publishedDateRange?.from.toISOString(),
          "lastPublished[lte]": publishedDateRange?.to.toISOString(),
        }),
        ...pagination,
      },
    }))
  );

  const total = response.results?.[0].data?.pagination?.total;
  const data = response.results
    .flatMap((r) => r.data?.items)
    .filter(Boolean) as ArticlesAndUpdates[];

  let articlesAndUpdatesData = data.filter((d) => !d.isDraft);
  if (type?.length) {
    articlesAndUpdatesData = articlesAndUpdatesData.filter(
      (item) => item?.fieldData?.type && type?.includes(item.fieldData.type)
    );
  }

  if (categories?.length) {
    articlesAndUpdatesData = articlesAndUpdatesData.filter((item) =>
      item?.fieldData?.categories?.some((category) =>
        categories?.includes(category)
      )
    );
  }

  if (sortOrder) {
    articlesAndUpdatesData.sort((a, b) => {
      const dateA = a.fieldData.date ? new Date(a.fieldData.date) : 0;
      const dateB = b.fieldData.date ? new Date(b.fieldData.date) : 0;

      if (sortOrder === "asc") {
        return +dateA - +dateB;
      } else {
        return +dateB - +dateA;
      }
    });
  }

  return {
    loading: response.loading,
    hasMore: total !== data.length,
    error: response.error,
    sync: response.sync,
    data: articlesAndUpdatesData,
  };
};
