import React, { useState } from "react";
import { generateTestId } from "../../../../shared/testids/testids";
import {
  currentWidgetFilters,
  onSelectFilters,
  WidgetProps,
} from "../../WidgetPanel";
import { NewsAndAlertsCard } from "../../../insights/newsAndAlerts/NewsAndAlertsCard";
import { NewsAndAlertFilters } from "../../../admin/contentManagement/newsAndAlerts/NewsAndAlertsTableWithFilters";
import { useDeepCompareEffect } from "react-use";

type NewsAndAlertsWidgetProps<T, WidgetFilterType extends object> = {
  props: WidgetProps<T, WidgetFilterType>;
};

export const NewsAndAlertsWidget = ({
  props: widgetProps,
}: NewsAndAlertsWidgetProps<"news-and-alert", NewsAndAlertFilters>) => {
  const testID = generateTestId("dashboard", "news-and-alerts-widget");
  const defaultWidgetFilters = currentWidgetFilters(widgetProps)?.filters;
  const [widgetFilters, setWidgetFilters] = useState<
    NewsAndAlertFilters | undefined
  >(defaultWidgetFilters);

  useDeepCompareEffect(() => {
    setWidgetFilters(defaultWidgetFilters);
  }, [defaultWidgetFilters]);

  const onFilterChange = (v: NewsAndAlertFilters) => {
    onSelectFilters(widgetProps, v);
    setWidgetFilters(v);
  };

  return (
    <NewsAndAlertsCard
      testID={testID}
      filterValues={widgetFilters}
      setFilterValues={onFilterChange}
      isWidget={true}
    />
  );
};
