import {
  getDateFromRelativeDateRange,
  RelativeRangeValue,
} from "../../../COREDesignSystem/Form/CORERangePicker";
import React, { useRef } from "react";
import { Col, Row } from "antd";
import { ChartBuilderSelector } from "./ChartBuilderSelector";
import { ChartBuilderTopBar } from "./ChartBuilderTopBar";
import { generateTestId } from "../../../shared/testids/testids";
import {
  AggregatesChartV2,
  ConfigYAxisValue,
  getTodayDate,
  hasTodayDate,
  Trace,
} from "./AggregatesChartV2";
import {
  ChartBuilderTreeSelection,
  ChartSelect,
  generateChartSelection,
  getChartTitle,
  RpcAggFuncType,
  RpcAggPeriodType,
  transformTreeSelectV2,
} from "./useChartBuilderApiV2";
import { useDownloadCsvButtonFeature } from "../../../shared/customHoooks/useFeatureFlags";
import {
  COREAggregatesPanelChart,
  PlotRefType,
} from "../../../COREDesignSystem/Chart/ChartHOC";
import { spacing } from "../../../shared/global";

export type ChartBuilderTreeSelect = {};

export type ChartConfig = {
  resetTree: boolean;
  treeSelections?: ChartBuilderTreeSelection;
  title?: string;
  aggPeriod?: RpcAggPeriodType;
  aggFunc?: RpcAggFuncType;
  dateRange: RelativeRangeValue;
  version?: number;
};

const currentChartVersion: number = 2;

export function ChartBuilderComponent({
  editMode = false,
  config,
  configRangeOfYAxis,
  onEdit,
  chartCardMode = false,
  containerBordered,
  empty,
}: {
  editMode: boolean;
  config: ChartConfig;
  configRangeOfYAxis?: ConfigYAxisValue[];
  onEdit?: (args: any) => void;
  chartCardMode?: boolean;
  containerBordered?: boolean;
  empty: React.ReactElement;
}) {
  const {
    resetTree,
    treeSelections,
    title,
    aggPeriod,
    aggFunc,
    dateRange,
    version,
  }: ChartConfig = config;
  const setTreeSelections = (newSelections) =>
    onEdit &&
    onEdit({
      ...config,
      treeSelections: newSelections,
      version: currentChartVersion,
    });
  const onFilterChange = ({ aggPeriod, dateRange, aggFunc }) =>
    onEdit &&
    onEdit({
      ...config,
      aggPeriod,
      dateRange,
      aggFunc,
      version: currentChartVersion,
    });
  const undoResetTree = () =>
    onEdit &&
    onEdit({ ...config, version: currentChartVersion, resetTree: false });
  const plotRef = useRef<PlotRefType>();

  const allSelections: ChartSelect[] | undefined =
    treeSelections &&
    Object.entries(treeSelections)
      .flatMap(([tree, selections]) =>
        selections.hasOwnProperty("change") && selections.change
          ? selections.change.map((selection) => ({
              tree,
              selection,
              dateRange,
              aggPeriod: selection.aggPeriod || aggPeriod,
              aggFunc: selection.aggFunc || aggFunc,
            }))
          : []
      )
      .filter((x) => x !== undefined);

  let transformedTreeSelect: ChartBuilderTreeSelection | undefined =
    treeSelections;
  if (treeSelections && (!version || version < currentChartVersion)) {
    transformedTreeSelect = transformTreeSelectV2(
      treeSelections,
      aggPeriod,
      aggFunc
    );
    config.version = currentChartVersion;
  }

  const userChartTitle = title ?? getChartTitle(allSelections, dateRange);
  const chartTraces: Trace[] = allSelections
    ? generateChartSelection(allSelections)
    : [];
  const [dateFrom, dateTo] = getDateFromRelativeDateRange(dateRange) || [];
  const hasTodayBreak: boolean = hasTodayDate(aggPeriod, {
    from: dateFrom,
    to: dateTo,
  });
  const todayDate: string = getTodayDate(aggPeriod).format();
  const chartFilter = {
    hasTodayBreak,
    todayDate,
  };

  const showDownloadCSVButton: boolean =
    useDownloadCsvButtonFeature(allSelections);

  const chartProps = {
    chartTitle: userChartTitle,
    traces: chartTraces,
    chartFilter: chartFilter,
    downloadCsv: showDownloadCSVButton,
    configRangeOfYAxis: configRangeOfYAxis,
    adjustHeightOfChart: true,
    enableEmptyIcon: true,
    legendFilter: true,
  };

  const chart = chartCardMode ? (
    <COREAggregatesPanelChart
      {...chartProps}
      downloadPng={true}
      chartId={`aggregates - ${userChartTitle}`}
      empty={empty}
      testID={generateTestId("chartbuilder", "aggregates-chart-card")}
      bordered={containerBordered}
    />
  ) : (
    <AggregatesChartV2
      chartId={`aggregates - ${userChartTitle}`}
      plotRef={plotRef}
      downloadPngCanvas={true}
      {...chartProps}
      testID={generateTestId("chartbuilder", "aggregates-chart-v2")}
      empty={empty}
    />
  );

  if (!editMode) {
    return chart;
  }

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[spacing.md, spacing.md]}>
          <Col xs={24} sm={24} md={24} lg={10} xl={9} xxl={7}>
            <ChartBuilderSelector
              checkTreeExpend={resetTree}
              undoResetTree={undoResetTree}
              checked={transformedTreeSelect}
              onChange={(tSelections) => setTreeSelections(tSelections)}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={14} xl={15} xxl={17}>
            <Row gutter={[spacing.md, spacing.md]}>
              <Col span={24}>
                <ChartBuilderTopBar
                  aggPeriod={aggPeriod}
                  aggFunc={aggFunc}
                  dateRange={dateRange}
                  onChange={onFilterChange}
                />
              </Col>
              <Col span={24}>
                <COREAggregatesPanelChart
                  {...chartProps}
                  downloadPng={true}
                  chartId={`aggregates - ${userChartTitle}`}
                  testID={generateTestId(
                    "chartbuilder",
                    "aggregates-chart-card"
                  )}
                  empty={empty}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
