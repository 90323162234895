import React from "react";
import {
  CORESelect,
  CORESelectProps,
} from "../../../COREDesignSystem/Form/CORESelect";
import { useAwayBrokers } from "../tradeParties/awaybrokers/useAwayBrokers";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import "./TradeTracker.less";
import { COREButton } from "../../../COREDesignSystem/Action/COREButton";

const dropdownRender = (menu: React.ReactElement) => {
  return (
    <>
      {menu}
      <COREDivider space={"xs"} />
      <div className={"edit-link-section"}>
        <COREButton
          type={"link"}
          href={"/broker-admin/trade-parties/away-brokers"}
          target={"_blank"}
          size={"md"}
        >
          Manage away brokers
        </COREButton>
      </div>
    </>
  );
};
export const BrokerSelect: React.FC<CORESelectProps> = (props) => {
  const { updatable } = useAwayBrokers();
  const awayBrokers = updatable.flatMap(({ data }) => data?.name ?? []);
  return (
    <CORESelect
      widthSize={"xl"}
      showSearch
      mode={"basic"}
      dropdownRender={dropdownRender}
      size={"lg"}
      options={awayBrokers.map((awayBroker) => ({
        value: awayBroker,
        label: awayBroker,
      }))}
      {...props}
    />
  );
};
