import React, { useContext, useState } from "react";
import { Col, Form, Row } from "antd";
import {
  CORERangePicker,
  getDateFromRelativeDateRange,
  RelativeRangeValue,
} from "../../COREDesignSystem/Form/CORERangePicker";
import { DEPRECATEDCORETableAPI } from "../../shared/deprecatedTables/DEPRECATEDCORETableAPI";
import { DEPRECATEDCsvDateFormat } from "../../shared/date/DateFormatContext";
import { market_notices as Transformer } from "../../openapi-typescript/admin/models/market_notices";
import "./aemoMarketNotice/AEMOMarketNotices.less";
import { COREPageWrapper } from "../../COREDesignSystem/Layout/COREPageWrapper";
import { COREPageHeader } from "../../COREDesignSystem/Layout/COREPageHeader";
import { generateTestId } from "../../shared/testids/testids";
import { COREHeading } from "../../COREDesignSystem/Typography/COREHeading";
import { keysToSnake, spacing } from "../../shared/global";
import { COREInput } from "../../COREDesignSystem/Form/COREInput";
import { COREContainer } from "../../COREDesignSystem/Layout/COREContainer";
import { CORETooltip } from "../../COREDesignSystem/Overlay/CORETooltip";
import { COREBody } from "../../COREDesignSystem/Typography/COREBody";
import { COREIcon } from "../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { COREFormItem } from "../../COREDesignSystem/Form/COREFormItem";
import { COREForm } from "../../COREDesignSystem/Form/COREForm";
import { COREDivider } from "../../COREDesignSystem/Layout/COREDivider";
import { COREButton } from "../../COREDesignSystem/Action/COREButton";

const initDateRange: RelativeRangeValue = {
  isRelative: true,
  from: -7,
  to: 1,
};

const pageTitle = "AEMO Market Notices";

export const FeedWidget = () => {
  return (
    <>
      <COREHeading
        level={3}
        testID={generateTestId("aemomarketnotices", "page-header")}
      >
        {pageTitle}
      </COREHeading>
      <COREDivider space={"md"} type={"horizontal"} />
      <Feed />
    </>
  );
};

const AEMOMarketNoticePageWrapper = () => {
  const breadcrumbs = [
    {
      title: "Tools",
      href: "/tools",
    },
    {
      title: "AEMO Market Notices\n",
    },
  ];
  return (
    <COREPageWrapper
      breadcrumbs={breadcrumbs}
      header={
        <COREPageHeader
          breadcrumbs={breadcrumbs}
          testID={generateTestId("aemomarketnotices", "page-header")}
          title={pageTitle}
        />
      }
      testID={generateTestId("aemomarketnotices", "page-wrapper")}
    >
      <COREContainer
        header={"Market notices feed"}
        testID={generateTestId("aemomarketnotices", "market-notices-container")}
        noScroll
        className={"aemo-market-feed"}
      >
        <Feed />
      </COREContainer>
    </COREPageWrapper>
  );
};

const Feed: React.FC = () => {
  const momentFormatForParams = useContext(DEPRECATEDCsvDateFormat);
  const [form] = Form.useForm();
  const issueDateRange: RelativeRangeValue = initDateRange;
  const columnField = (search: string) => [
    `notice_id.ilike.*${search}*`,
    `notice_type_id.ilike.*${search}*`,
    `notice_type_description.ilike.*${search}*`,
    `external_reference.ilike.*${search}*`,
    `reason.ilike.*${search}*`,
  ];

  const keywordValue = (search: string, dateRange: RelativeRangeValue) => {
    const [startIssue, endIssue] =
      getDateFromRelativeDateRange(dateRange) || [];
    const startIssueDate = startIssue?.format(momentFormatForParams.format);
    const endIssueDate = endIssue?.format(momentFormatForParams.format);
    return {
      params: {
        order: "issue_date.desc",
        or: `(${columnField(search).toString()})`,
        and: `(issue_date.gte.${startIssueDate}, issue_date.lte.${endIssueDate})`,
      },
      issueDateRange: dateRange,
    };
  };

  const handleSearch = (dateRange?: RelativeRangeValue) => {
    const dateRangeParams =
      dateRange || keyword?.issueDateRange || issueDateRange;
    form.validateFields().then((v) => {
      setKeyword(keywordValue(v.search ?? "", dateRangeParams));
    });
  };

  const [keyword, setKeyword] = useState<{
    params: {
      order: string;
      or: string;
      and: string;
    };
    issueDateRange: RelativeRangeValue;
  }>(keywordValue("", issueDateRange));
  const transformer = (it: Transformer & { source?: string }) => ({
    key: it.id,
    rowKey: it.id,
    id: it.id,
    from: it.from,
    to: it.to,
    noticeId: it.notice_id, // eslint-disable-line camelcase
    noticeTypeId: it.notice_type_id, // eslint-disable-line camelcase
    noticeTypeDescription: it.notice_type_description, // eslint-disable-line camelcase
    issueDate: it.issue_date, // eslint-disable-line camelcase
    creationDate: it.creation_date, // eslint-disable-line camelcase
    externalReference: it.external_reference, // eslint-disable-line camelcase
    reason: it.reason,
    source: it.source,
  });

  const columns = [
    {
      title: "Description",
      dataIndex: "externalReference",
      key: "externalReference",
      width: "700px",
      render: (date, record) => (
        <CORETooltip
          testID={generateTestId("aemomarketnotices", "market-notice-tooltip")}
          title={"Notice summary"}
          message={record.reason}
          position={"top"}
          width={"320px"}
        >
          {date}
        </CORETooltip>
      ),
    },
    {
      title: "Notice Type",
      dataIndex: "noticeTypeId",
      key: "noticeTypeId",
      width: "220px",
    },
    {
      title: "Issued at",
      dataIndex: "creationDate",
      key: "creationDate",
      width: "192px",
      render: (date: Date) => <span>{date}</span>,
    },
  ];

  return (
    <Row gutter={[spacing.sm, spacing.md]}>
      <Col span={24}>
        <COREHeading
          testID={generateTestId(
            "aemomarketnotices",
            "market-notices-search-header"
          )}
          level={3}
        >
          <COREIcon
            icon={icon({ name: "filter", style: "regular" })}
            size={"sm"}
            className={"filter-icon-colour"}
          />{" "}
          Filter notices
        </COREHeading>
        <COREForm
          layout={"none"}
          form={form}
          testID={generateTestId("aemomarketnotices", "market-notices-form")}
        >
          <Row align={"middle"} gutter={[spacing.sm, spacing.xs]}>
            <Col>
              <COREFormItem
                testID={generateTestId(
                  "aemomarketnotices",
                  "market-notices-search-formitem"
                )}
                name={"search"}
              >
                <COREInput.Search
                  testID={generateTestId(
                    "aemomarketnotices",
                    "market-notice-search-input"
                  )}
                  size={"lg"}
                  widthSize={"lg"}
                  allowClear
                  placeholder={"Search"}
                  onChange={(e) => {
                    e.preventDefault();
                    handleSearch(undefined);
                  }}
                />
              </COREFormItem>
            </Col>
            <Col>
              <COREFormItem
                testID={generateTestId(
                  "aemomarketnotices",
                  "market-notices-range-picker-formitem"
                )}
              >
                <CORERangePicker
                  testID={generateTestId(
                    "aemomarketnotices",
                    "market-notices-range-picker"
                  )}
                  size={"lg"}
                  widthSize={"xl"}
                  defaultValue={issueDateRange}
                  onChange={(data) => {
                    handleSearch(data);
                  }}
                  allowClear={true}
                  value={keyword?.issueDateRange}
                  extended={false}
                  relativeToggle={true}
                />
              </COREFormItem>
            </Col>
          </Row>
        </COREForm>
      </Col>
      <Col span={24}>
        <DEPRECATEDCORETableAPI
          columns={columns}
          api={"market_notices"}
          apiParams={keysToSnake(keyword?.params)}
          transform={transformer}
          rowKey={(r: { id: number }) => r.id}
          theme={"w"}
          expandable={{
            expandedRowRender: (record) => (
              <Row className={"feed-description-block"}>
                <Col span={24}>
                  <COREBody type={"p3"} strong>
                    {record.noticeId}: {record.noticeTypeDescription}
                  </COREBody>
                </Col>
                <Col span={24}>
                  <COREBody type={"p3"} strong>
                    Reason
                  </COREBody>
                  <COREBody type={"p3"}>{record.reason}</COREBody>
                </Col>
              </Row>
            ),
            rowExpandable: (record: { name: string }) =>
              record.name !== "Not Expandable",
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <COREButton
                  type={"action"}
                  icon={
                    <COREIcon
                      icon={icon({ name: "caret-down", style: "regular" })}
                    />
                  }
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <COREButton
                  type={"action"}
                  icon={
                    <COREIcon
                      icon={icon({ name: "caret-right", style: "solid" })}
                    />
                  }
                  onClick={(e) => onExpand(record, e)}
                />
              ),
          }}
          onChange={() => {}}
        />
      </Col>
    </Row>
  );
};

export default AEMOMarketNoticePageWrapper; // eslint-disable-line import/no-default-export
