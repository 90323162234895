import React from "react";
import { MapStick as MapStickRaw } from "../map/MapStick";
import { FeedWidget } from "../../../../tools/AEMOMarketNoticePageWrapper";
import { AemoPriceMapOverview as AemoPriceMapOverviewRaw } from "../AemoPriceMapOverview";
import { LivePrice as LivePriceRaw } from "../LivePrice";
import { ChartBuilderWidget as ChartBuilderWidgetRaw } from "../../../../tools/chartBuilder/ChartBuilderWidget";
import { DashboardIFrame as DashboardIFrameRaw } from "../../DashboardIFrame";
import { REHUBMarketWidget } from "../../../../marketPrices/marketPricesComponent/REHUBMarketWidget";
import { LiveMarketWidget } from "../../../../marketPrices/marketPricesComponent/LiveMarketPrices";
import { TradeTickerWidget } from "../../../../tools/tradeTicker/TradeTickerWidget";
import { MarketCommentaryWidget } from "../../../widgets/dailyCurve/MarketCommentaryWidget";
import { HOCErrorBoundary } from "../../../../../shared/errors/ErrorBoundary";
import { isEmptyObject } from "../../../../../shared/globals";
import { ProjectFinderWidget } from "../../../../tools/projectFinder/ProjectFinderWidget";
import {
  carbonProducts,
  EnviroProducts,
  removeNullsFromObjectArrays,
} from "../../../../../shared/global";
import "./Widget.less";
import { OHLCPriceHistoryWidget } from "../../../widgets/marketPrices/OHLCPriceHistoryWidget";
import { DailyCurveTableWidget } from "../../../widgets/dailyCurve/DailyCurveTableWidget";
import { ArticlesAndUpdatesWidget } from "../../../widgets/insights/ArticlesAndUpdatesWidget";
import { ResearchAndAnalysisWidget } from "../../../widgets/insights/ResearchAndAnalysisWidget";
import { NewsAndAlertsWidget } from "../../../widgets/insights/NewsAndAlertsWidget";
import { DailyCurveHistoryChartWidget } from "../../../widgets/dailyCurve/DailyCurveHistoryChartWidget";

export const separator = "|";

const MapStick = HOCErrorBoundary(MapStickRaw);
const AemoMarketNotices = HOCErrorBoundary(FeedWidget);
const AemoPriceMapOverview = HOCErrorBoundary(AemoPriceMapOverviewRaw);
const LivePrice = HOCErrorBoundary(LivePriceRaw);
const ChartBuilderWidget = HOCErrorBoundary(ChartBuilderWidgetRaw);
const DashboardIFrame = HOCErrorBoundary(DashboardIFrameRaw);
const REHUBLiveMarketScreenWidget = HOCErrorBoundary(REHUBMarketWidget);
const EnviroLiveMarketScreenWidget = HOCErrorBoundary(LiveMarketWidget);
const CarbonLiveMarketWidget = HOCErrorBoundary(LiveMarketWidget);
const TradeTickerWidgetRender = HOCErrorBoundary(TradeTickerWidget);
const MarketCommentaryWidgetRender = HOCErrorBoundary(MarketCommentaryWidget);
const ProjectFinderWidgetRender = HOCErrorBoundary(ProjectFinderWidget);
const OHLCPriceHistoryWidgetRender = HOCErrorBoundary(OHLCPriceHistoryWidget);
const DailyCurveTableWidgetRender = HOCErrorBoundary(DailyCurveTableWidget);
const NewsAndAlertsWidgetRender = HOCErrorBoundary(NewsAndAlertsWidget);
const DailyCurveHistoryChartWidgetRender = HOCErrorBoundary(
  DailyCurveHistoryChartWidget
);

const EnviroLiveMarketWidgetRender = (props) => {
  return (
    <EnviroLiveMarketScreenWidget
      props={props}
      products={EnviroProducts}
      type={"environmental"}
      disableInFocus={true}
    />
  );
};

const CarbonLiveMarketWidgetRender = (props) => {
  return (
    <CarbonLiveMarketWidget
      props={props}
      products={carbonProducts}
      type={"carbon"}
      disableInFocus={true}
    />
  );
};

export const Widgets = {
  MapStick: (props) => <MapStick props={props} />,
  "map-stick": (props) => <MapStick props={props} />,
  "aemo-price-map": (props) => <AemoPriceMapOverview props={props} />,
  AemoPriceMapOverview: (props) => <AemoPriceMapOverview props={props} />,
  "aemo-market-notices": (props) => <MapStick props={props} />,
  AemoMarketNotices: (props) => (
    <div className={"widget-wrapper"}>
      <AemoMarketNotices props={props} />
    </div>
  ),
  "live-price": (props) => <LivePrice props={props} />,
  LivePrice: (props) => <LivePrice props={props} />,
  "live-price-environmental": (props) => <LivePrice props={props} />,
  LivePriceEnvironmental: (props) => (
    <LivePrice
      props={{
        codes: ["SPOTACCU", "SPOTSTC", "SPOTVEEC", "SPOTESC", "SPOTLGC"],
        title: "Environmental Market Prices Watchlist",
        disableTree: true,
        ...props,
      }}
    />
  ),
  "live-price-rehub": (props) => <LivePrice props={props} />,
  LivePriceRehub: (props) => (
    <LivePrice
      props={{
        codes: [
          "FY22QSP",
          "CY23QSP",
          "CY22NSP",
          "CY23NSP",
          "CY22VSP",
          "CY23VSP",
        ],
        title: "Electricity Shapes - Market Prices Watchlist",
        disableTree: true,
        ...props,
      }}
    />
  ),
  "dashboard-iframe": (props) => <DashboardIFrame props={props} />,
  DashboardIFrame: (props) => <DashboardIFrame props={props} />,
  "time-series": (props) => <ChartBuilderWidget props={props} />,
  ChartBuilderDashboard: (props) => <ChartBuilderWidget props={props} />,
  ChartBuilder: (props) => <ChartBuilderWidget props={props} />,
  "enviro-live-market-screen": (props) => EnviroLiveMarketWidgetRender(props),
  EnviroLiveMarketScreenWidget: (props) => EnviroLiveMarketWidgetRender(props),
  "live-market-screen": (props) => (
    <REHUBLiveMarketScreenWidget props={props} />
  ),
  REHUBLiveMarketScreenWidget: (props) => (
    <REHUBLiveMarketScreenWidget props={props} />
  ),
  "carbon-live-market-screen": (props) => CarbonLiveMarketWidgetRender(props),
  CarbonCertificatesWidget: (props) => CarbonLiveMarketWidgetRender(props),
  TradeTicker: (props) => <TradeTickerWidgetRender props={props} />,
  MarketCommentaryWidget: (props) => (
    <MarketCommentaryWidgetRender props={props} />
  ),
  ProjectFinder: (props) => <ProjectFinderWidgetRender props={props} />,
  OHLCPriceHistoryWidget: (props) => (
    <OHLCPriceHistoryWidgetRender props={props} />
  ),
  DailyCurveTableWidget: (props) => (
    <DailyCurveTableWidgetRender props={props} />
  ),
  ArticlesAndUpdatesWidget: (props) => (
    <ArticlesAndUpdatesWidget props={props} />
  ),
  ResearchAndAnalysisWidget: (props) => (
    <ResearchAndAnalysisWidget props={props} />
  ),
  NewsAndAlertsWidget: (props) => <NewsAndAlertsWidgetRender props={props} />,
  DailyCurveHistoryChartWidget: (props) => (
    <DailyCurveHistoryChartWidgetRender props={props} />
  ),
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const updateWidget = (
  action,
  val,
  { ls: defaultls, updateDashboard }
) => {
  let updateLayout;

  const ls = removeNullsFromObjectArrays(defaultls);

  if (action === "remove") {
    const removeList = {
      lg: ls.lg ? ls.lg.filter((oldLayout) => oldLayout.i !== val) : [],
      md: ls.md ? ls.md.filter((oldLayout) => oldLayout.i !== val) : [],
      sm: ls.sm ? ls.sm.filter((oldLayout) => oldLayout.i !== val) : [],
      xs: ls.xs ? ls.xs.filter((oldLayout) => oldLayout.i !== val) : [],
      xxs: ls.xxs ? ls.xxs.filter((oldLayout) => oldLayout.i !== val) : [],
    };

    updateLayout = {
      lg: removeList.lg.map((list) => {
        const [, currentWidgetName] = list.i.split(separator);
        return {
          ...list,
          i: removeList.lg.indexOf(list) + 1 + separator + currentWidgetName,
        };
      }),
      md: removeList.md.map((list) => {
        const [, currentWidgetName] = list.i.split(separator);
        return {
          ...list,
          i: removeList.md.indexOf(list) + 1 + separator + currentWidgetName,
        };
      }),
      sm: removeList.sm.map((list) => {
        const [, currentWidgetName] = list.i.split(separator);
        return {
          ...list,
          i: removeList.sm.indexOf(list) + 1 + separator + currentWidgetName,
        };
      }),
      xs: removeList.xs.map((list) => {
        const [, currentWidgetName] = list.i.split(separator);
        return {
          ...list,
          i: removeList.xs.indexOf(list) + 1 + separator + currentWidgetName,
        };
      }),
      xxs: removeList.xxs.map((list) => {
        const [, currentWidgetName] = list.i.split(separator);
        return {
          ...list,
          i: removeList.xxs.indexOf(list) + 1 + separator + currentWidgetName,
        };
      }),
    };
  } else if (action === "widgetProps") {
    const removeList = {
      lg: ls.lg ? ls.lg.filter((oldLayout) => oldLayout.i !== val.i) : [],
      md: ls.md ? ls.md.filter((oldLayout) => oldLayout.i !== val.i) : [],
      sm: ls.sm ? ls.sm.filter((oldLayout) => oldLayout.i !== val.i) : [],
      xs: ls.xs ? ls.xs.filter((oldLayout) => oldLayout.i !== val.i) : [],
      xxs: ls.xxs ? ls.xxs.filter((oldLayout) => oldLayout.i !== val.i) : [],
    };

    const newList = {
      lg: ls.lg
        ? ls.lg
            .filter((oldLayout) => oldLayout.i === val.i)
            .map((list) => ({ ...list, props: val.widget }))
        : [],
      md: ls.md
        ? ls.md
            .filter((oldLayout) => oldLayout.i === val.i)
            .map((list) => ({ ...list, props: val.widget }))
        : [],
      sm: ls.sm
        ? ls.sm
            .filter((oldLayout) => oldLayout.i === val.i)
            .map((list) => ({ ...list, props: val.widget }))
        : [],
      xs: ls.xs
        ? ls.xs
            .filter((oldLayout) => oldLayout.i === val.i)
            .map((list) => ({ ...list, props: val.widget }))
        : [],
      xxs: ls.xxs
        ? ls.xxs
            .filter((oldLayout) => oldLayout.i === val.i)
            .map((list) => ({ ...list, props: val.widget }))
        : [],
    };

    removeList.lg.push(newList.lg[0]);
    removeList.md.push(newList.md[0]);
    removeList.sm.push(newList.sm[0]);
    removeList.xs.push(newList.xs[0]);
    removeList.xxs.push(newList.xxs[0]);

    updateLayout = {
      lg: removeList.lg,
      md: removeList.md,
      sm: removeList.sm,
      xs: removeList.xs,
      xxs: removeList.xxs,
    };
  } else if (action === "addWidget") {
    updateLayout = {
      lg: val.lg,
      md: val.md,
      sm: val.sm,
      xs: val.xs,
      xxs: val.xxs,
    };
  } else if (action === "layoutChange") {
    const filteredLayout = (d) => {
      return !isEmptyObject(d)
        ? d.filter((l) => l && l.i && l.i.includes("|"))
        : [];
    };

    updateLayout = {
      lg: filteredLayout(val.lg),
      md: filteredLayout(val.md),
      sm: filteredLayout(val.sm),
      xs: filteredLayout(val.xs),
      xxs: filteredLayout(val.xxs),
    };
  }

  updateDashboard({
    layout: updateLayout,
  });
};
