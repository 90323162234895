import { useAPIQuery } from "./useAPITypes";
import { useUserIsAdmin } from "../state/user";
import { Merge } from "../TypeScriptHelpers";
import { TradableProductType } from "../select/TradableProductTree";
import { buildQuerySelect } from "../global";

const getRetData = (
  tradableProducts,
  dataWithWs,
  region,
  cap,
  shape,
  isInFocus
) => {
  return (
    tradableProducts.length !== 0
      ? tradableProducts.map((tp) =>
          dataWithWs?.find((d) => d.tradableProduct.id === tp)
        )
      : dataWithWs
  )?.filter((snapshot) => {
    if (snapshot === undefined) {
      //this is to allow skipping of tradable products (which can be done by specifying null as the tradableProduct id)
      return true;
    }

    const { name } = snapshot;
    return !(
      (region && !name?.includes(region)) ||
      (cap === true && !isInFocus && !name?.includes("cap")) ||
      (cap === false && !isInFocus && name?.includes("cap")) ||
      (shape && !cap && !name?.includes(shape)) ||
      (shape === "solar" && name?.includes("inverse")) ||
      (shape === "peak" && (name?.includes("super") || name?.includes("off")))
    );
  });
};

export type snapshotRequired =
  | { codes: string[]; tradableProducts?: undefined; type?: undefined }
  | {
      codes?: undefined;
      tradableProducts: number[];
      type?: undefined;
    }
  | {
      codes?: undefined;
      tradableProducts?: undefined;
      type: string;
    };
export type useSnapshotsType<T> = Merge<
  {
    filter?: T;
    customSelect?: string | null;
    asOf?: string | null;
    onlyFirm?: boolean;
    strip?: boolean;
    region?: string | null;
    cap?: boolean;
    order?: string;
    name?: string | null;
    option?: boolean | null;
    inFocus?: boolean | null;
    spotWithVintage?: boolean | null;
    genericProductsOnly?: boolean | null;
    showPublishedProjectOnly?: boolean | null;
    productType?: string | null;
    skip?: boolean;
  },
  snapshotRequired
>;

type snapshotParamsType = {
  select: string;
  options?: boolean;
  onlyfirm?: boolean;
  asof?: string;
  in_focus?: string; // eslint-disable-line camelcase
  codes?: string;
  type?: string;
  and?: string;
  or?: string;
  tradable_products?: string; // eslint-disable-line camelcase
  order?: string;
  only_generic_products?: boolean; // eslint-disable-line camelcase
  product_type?: string; // eslint-disable-line camelcase
  certificate_project_publication_status?: string; // eslint-disable-line camelcase
};

type NamePart = string;
type LastTen = Object;
export type BidOfferType = "firm" | "indicative";
export type SnapshotRecord = {
  name: string;
  tradableProduct: TradableProductType;
  bidType: BidOfferType;
  bidPrice: number;
  bidSize: number;
  bidNotes: string;
  offerPrice: number;
  offerSize: number;
  offerType: BidOfferType;
  offerNotes: string;
  lastPrice: number;
  lastSize: number;
  lastDate: string;
  change: number;
  lastTenIncludingStrip: LastTen[];
  nameParts: NamePart[];
  productTypeName: string;
  vintage: number;
  productType: string;
  methodType: string;
  methodTypeName: string;
  productTypeSortOrder: number;
  methodTypeSortOrder: number;
  certificateProjectPublicationStatus: string;
  certificateProjectIdentifier: string;
};
export const useSnapshots = <T extends object>({
  filter,
  customSelect = null,
  asOf = null,
  onlyFirm = false,
  type = undefined,
  strip,
  region,
  cap,
  order,
  name: shape,
  tradableProducts = [],
  option = null,
  inFocus,
  codes = undefined,
  spotWithVintage = null,
  genericProductsOnly = null,
  showPublishedProjectOnly = null,
  productType = null,
  skip = false,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
useSnapshotsType<T>) => {
  const isAdmin = useUserIsAdmin();

  let params: snapshotParamsType = {
    select: buildQuerySelect({
      id: "tradable_product",
      tradableProduct: "tradable_products",
      name: "name",
      bidType: "bid_type",
      bidPrice: "bid_price",
      bidSize: "bid_size",
      offerPrice: "offer_price",
      offerSize: "offer_size",
      offerType: "offer_type",
      lastPrice: "last_price",
      lastSize: "traded_volume",
      lastDate: "last_trade_date",
      change: "change",
      lastTenIncludingStrip: "last_ten_including_strip",
      nameParts: "name_parts",
      productType: "product_type",
      productTypeName: "product_type_name",
      productTypeLongName: "product_type_long_name",
      methodTypeName: "method_type_name",
      methodType: "method_type",
      methodTypeSortOrder: "method_type_sort_order",
      productTypeSortOrder: "product_type_sort_order",
      tenorType: "tenor_type",
      vintage: "vintage",
      rehubCode: "rehub_code",
      ...(isAdmin && {
        bidName: "bid_name",
        offerName: "offer_name",
        certificateProjectPublicationStatus:
          "certificate_project_publication_status",
        certificateProjectIdentifier: "certificate_project_identifier",
      }),
    }),
  };

  if (isAdmin) {
    params.select +=
      ", bidNotes:bid_notes, offerNotes:offer_notes, certificateProjectPublicationStatus:certificate_project_publication_status, certificateProjectIdentifier:certificate_project_identifier";
  }

  if (option !== null) params.options = option;
  params.onlyfirm = onlyFirm;
  if (asOf !== null) params.asof = asOf;
  if (inFocus !== null && inFocus !== undefined) {
    params.in_focus = `eq.${inFocus}`; // eslint-disable-line camelcase
    params.select += ", inFocus:in_focus";
  }
  if (codes) params.codes = `{${codes.join(",")}}`; // eslint-disable-line camelcase

  if (type) {
    params.type = type;
    let nameFilters: string[] = [];
    if (spotWithVintage !== null) {
      params.select += ", tenorType:tenor_type, vintage, isStrip:is_strip";
      if (spotWithVintage) {
        nameFilters.push("tenor_type.is.null,vintage.not.is.null");
        params.or = `(method_type.is.null,method_type_sort_order.not.is.null)`;
      } else {
        params.and = `(or(name.ilike.*CY*,and(tenor_type.is.null,vintage.is.null)),or(method_type.is.null,method_type_sort_order.not.is.null))`;
      }
    } else {
      if (strip) nameFilters.push("name.like.*STRIP*");
      if (!strip) nameFilters.push("name.not.like.*STRIP*");
    }
    if (nameFilters.length > 0) params.and = `(${nameFilters.join(",")})`;
  }

  if (tradableProducts.length > 0 && !codes) {
    // eslint-disable-next-line camelcase
    params.tradable_products = `{${tradableProducts
      .filter((tp) => !!tp) //this is to allow skipping of tradable products ( which can be done by specifying null as the tradableProduct id
      .join(",")}}`;
  }

  if (order) {
    params.order = order;
  }
  if (filter) {
    params = { ...params, ...filter };
  }
  if (genericProductsOnly !== null) {
    if (genericProductsOnly) {
      params.only_generic_products = true; // eslint-disable-line camelcase
    } else {
      params.only_generic_products = false; // eslint-disable-line camelcase
      if (showPublishedProjectOnly) {
        params.or = `(certificate_project_identifier.is.null, certificate_project_publication_status.eq.published)`;
      }
    }
  }

  if (productType) params.product_type = productType; // eslint-disable-line camelcase

  const { data, sync, loading, forceRefresh, lastUpdate, error, ...other } =
    useAPIQuery<SnapshotRecord[]>(
      isAdmin ? "snapshots_detail" : "snapshots",
      params,
      skip
    );

  const retData: SnapshotRecord[] =
    data && data.length !== 0
      ? getRetData(tradableProducts, data, region, cap, shape, inFocus)
      : data;

  const syncRet: boolean = skip ? false : sync && retData && retData.length > 0;

  if (customSelect) {
    params.select = customSelect;
  }

  return {
    ...other,
    lastUpdate: lastUpdate,
    sync: syncRet,
    error,
    loading,
    forceRefresh,
    data: {
      data: retData,
    },
  };
};
