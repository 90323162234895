import React from "react";
import {
  CORESelectAPI,
  CORESelectAPIProps,
  groupOptions,
} from "../../COREDesignSystem/Form/CORESelectAPI";
import { CMSContentType } from "../../openapi-typescript/common/cms_content_type";
import { FeatureFlag, useFeatureFlags } from "../customHoooks/useFeatureFlags";
import { keysToSnake } from "../global";

type Scope = Extract<
  FeatureFlag,
  | "core.content.analysis.read"
  | "core.content.case-study.read"
  | "core.content.forecast.read"
  | "core.content.guide.read"
  | "core.content.market-update.read"
  | "core.content.opinion.read"
  | "core.content.report.read"
  | "core.content.research.read"
  | "core.content.strategy.read"
  | "core.content.whitepaper.read"
>;
export const useCMSContentTypeScopes = (): CMSContentType["contentType"][] => {
  const { features } = useFeatureFlags();
  const scopes: Record<Scope, CMSContentType["contentType"]> = {
    "core.content.analysis.read": "Analysis",
    "core.content.case-study.read": "Case study",
    "core.content.forecast.read": "Forecast",
    "core.content.guide.read": "Guide",
    "core.content.market-update.read": "Market update",
    "core.content.opinion.read": "Opinion",
    "core.content.report.read": "Report",
    "core.content.research.read": "Research",
    "core.content.strategy.read": "Strategy",
    "core.content.whitepaper.read": "Whitepaper",
  };

  const contentScopes = Object.keys(scopes) as Scope[];

  return features
    .filter((feature): feature is Scope =>
      contentScopes.includes(feature as Scope)
    )
    .map((feature) => scopes[feature]);
};

export const CMSContentTypeSelect: React.FC<
  Omit<CORESelectAPIProps<CMSContentType>, "transform" | "endpoint">
> = (props) => {
  const scopes = useCMSContentTypeScopes();
  return (
    <CORESelectAPI
      {...props}
      transform={(data) => groupOptions(data, "contentType", "contentType")}
      endpoint={"cmsContentTypes"}
      params={keysToSnake({ contentType: `in.(${scopes.join(",")})` })}
    />
  );
};
