import React from "react";
import {
  CORESelect,
  CORESelectProps,
} from "../../COREDesignSystem/Form/CORESelect";
import { TestID } from "../testids/testids";
import { NewsAndAlert } from "../../openapi-typescript/common/newsAndAlert";

export const AlertTypeSelect: React.FC<
  {
    testID: TestID;
  } & CORESelectProps
> = ({ testID, ...props }) => {
  const options: NewsAndAlert["alertType"][] = ["Market Alert", "News"];

  return (
    <CORESelect
      testID={testID}
      size="lg"
      placeholder="Select alert type"
      options={options.map((p) => ({
        label: p,
        value: p,
      }))}
      {...props}
    />
  );
};
