import React from "react";
import {
  CORESelect,
  CORESelectProps,
} from "../../COREDesignSystem/Form/CORESelect";

export const articlesAndUpdatesTypeOptions = [
  {
    label: "News",
    value: "3a92be04bfceb43721f1c2606df6a759",
  },
  {
    label: "Blog",
    value: "158c1fed40a8eee9ea5b0eeb4c0afa4d",
  },
  {
    label: "Case Study",
    value: "6e0095c3b1acd08dbd1d34c009604554",
  },
  {
    label: "Whitepaper",
    value: "8d4b9bbcf632d9185570b622192dfd25",
  },
  {
    label: "Market Update",
    value: "0ad6c7a26042645767a32e22618ea786",
  },
  {
    label: "Guide",
    value: "79e45aef5addc246eb62ccef1b4264f7",
  },
  {
    label: "Forecast",
    value: "73d50680bb77d595b560019b535d43bc",
  },
  {
    label: "Report",
    value: "1c89ac266467e8d572eda6b619a42945",
  },
  {
    label: "Analysis",
    value: "83977d186645e994b71aacfba7a7a599",
  },
  {
    label: "Opinion",
    value: "e3e52acdf773dabbac1a7ceb0b62a3c8",
  },
  {
    label: "Research",
    value: "55cfd15487c416fe9f527e82d88a057e",
  },
  {
    label: "Key Interest Spotlight",
    value: "078e55b26ca9a185971449d24bcb9bf8",
  },
  {
    label: "Strategy",
    value: "9a4c5c3c4560c5f85419cb38a41c5b4c",
  },
];
export const ArticlesAndUpdatesTypeSelect: React.FC<
  Exclude<CORESelectProps, "options">
> = (porps) => (
  <CORESelect {...porps} options={articlesAndUpdatesTypeOptions} />
);
