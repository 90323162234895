import React, { Key, useState } from "react";
import { CaretDownOutlined, SearchOutlined } from "@ant-design/icons";
import { Col, Row, Tree } from "antd";
import {
  CORETreeProps,
  getTreeDepth,
  renderTreeNodes,
  treeNodeRecursiveMatches,
} from "./CORETree";
import "./CORETreeSelectV2.less";
import "./CORETreeV2.less";
import classNames from "classnames";
import { COREInput } from "./COREInput";
import { spacing } from "../../shared/global";

export const CORETreeV2 = <Value extends any>({
  treeData,
  multiple = false,
  showLine,
  selectable = true,
  checkedKeys = [],
  onCheck,
  search: searchable,
  testID,
  limit = -1,
  children,
  ...otherProps
}: CORETreeProps<Value>) => {
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>();

  const onSelectFunc = (selectKeys, node) => {
    if (!selectable || !onCheck) {
      return false;
    }
    if (!node.children) {
      setSelectedKeys(selectKeys);
      return onCheck(selectKeys);
    }
    const k: Key[] = node.expanded
      ? expandedKeys.filter((kFilter) => kFilter !== node.key)
      : expandedKeys.concat(node.key);
    setExpandedKeys(k);
  };

  const onCheckFunc = (checkedKeysValue, checked, node) => {
    if (!onCheck) return;
    if (multiple) return onCheck(checkedKeysValue);
    if (checked) return onCheck([node.key]);
    return onCheck([]);
  };

  const searchExpandedTree = (tree, searchValue): string[] => {
    let pathKey: string[] = [];

    tree.forEach((el) => {
      const matchTreeNodes = treeNodeRecursiveMatches(el, searchValue);
      if (matchTreeNodes) {
        pathKey = [...pathKey, el.key];
      }
    });
    return [...new Set(pathKey)];
  };

  const onSearchChange = ({ target: { value: searchValue } }) => {
    const matchKey: string[] = searchExpandedTree(treeData, searchValue);
    setSearchValue && setSearchValue(searchValue);
    setExpandedKeys(matchKey);
  };

  const showLineOption = showLine ? { showLeafIcon: false } : showLine;
  const treeDepth: number = getTreeDepth(treeData);
  const checkAbleNodePosition: number =
    limit < 0 ? treeDepth + limit + 1 : limit > treeDepth ? treeDepth : limit;

  return (
    <div data-testid={testID} className={"core-tree"}>
      {searchable && (
        <div className={"search-block"}>
          <COREInput.Input
            suffix={<SearchOutlined />}
            placeholder={"Search to select"}
            testID={testID}
            onChange={onSearchChange}
            allowClear={true}
            widthSize={"full"}
          />
        </div>
      )}
      <div
        className={classNames("tree-block", {
          "tree-height": true,
          "is-searchable": searchable,
        })}
      >
        <Row gutter={[0, spacing.xs]}>
          {children && (
            <Col span={24} style={{ padding: "8px" }}>
              {children}
            </Col>
          )}
          <Col>
            <Tree
              checkable
              selectable={selectable}
              multiple={multiple}
              showLine={showLineOption}
              switcherIcon={<CaretDownOutlined />}
              expandedKeys={expandedKeys}
              checkedKeys={checkedKeys}
              selectedKeys={selectedKeys}
              onExpand={(expandedKeysValue) => {
                setExpandedKeys(expandedKeysValue);
                setSelectedKeys(expandedKeysValue);
              }}
              onSelect={(selectKeys, { node }) => {
                onSelectFunc(selectKeys, node);
              }}
              onCheck={(checkedKeysValue, { checked, node }) => {
                onCheckFunc(checkedKeysValue, checked, node);
              }}
              treeData={renderTreeNodes(
                treeData,
                multiple,
                checkAbleNodePosition,
                treeDepth,
                searchValue,
                selectable
              )}
              {...otherProps}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};
