/* eslint-disable */
import React, { forwardRef, useContext, useState } from "react";
import { DEPRECATEDCORETable } from "../deprecatedTables/DEPRECATEDCORETable";
import { Badge, Checkbox, InputNumber, message, Result, Tooltip } from "antd";
import { useUserIsAdmin } from "../state/user";
import { TradableProductPopover } from "../popovers/TradableProductPopover";
import {
  priceFormatter,
  priceParser,
  volumeFormatter,
  volumeParser,
} from "../globals";
import { WarningOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import {
  SnapshotRecord,
  snapshotRequired,
  useSnapshots,
} from "../customHoooks/useSnapshots";
import sizeMe from "react-sizeme";
import { DEPRECATEDDateFormatContextDate } from "../date/DateFormatContext";
import "./PriceTable.less";
import { Merge } from "../TypeScriptHelpers";
import { TestID, TestIDWrapper, generateTestId } from "../testids/testids";
import {
  appQueryClient as queryClient,
  mutation,
} from "../state/appQueryClient";
import { useMutation } from "react-query";
import { COREIndicative } from "../../COREDesignSystem/Content/COREFlag";
import { keysToSnake } from "../global";
import { COREEmpty } from "../../COREDesignSystem/Content/COREEmpty";
import classNames from "classnames";

const Display = ({ value, message, draft, firmness }) => {
  const isAdmin = useUserIsAdmin();
  const cellClass = classNames({ [`${firmness}-cell`]: value });

  return draft && value ? (
    <div className={cellClass}>
      <Tooltip placement="topLeft" title={message} trigger={"hover"}>
        <>
          <WarningOutlined style={{ color: "orange" }} />
          {value}
        </>
      </Tooltip>
    </div>
  ) : !isAdmin &&
    firmness === "indicative" &&
    value !== "" &&
    value !== null ? (
    <div
      className={classNames({
        "indicative-cell-approved": firmness === "indicative",
      })}
    >
      <COREIndicative>{value}</COREIndicative>
    </div>
  ) : (
    <div className={cellClass}>{value}</div>
  );
};

type UpdateInFocusParams = {
  body?: string;
  fetchOptions: {};
  params?: {};
};

const updateInFocus = ({ id, value }) => {
  const checked = value.target.checked;
  let params: UpdateInFocusParams = {
    fetchOptions: { method: checked ? "POST" : "DELETE" },
  };

  if (checked) {
    params.body = JSON.stringify({
      tradable_product: id, // eslint-disable-line camelcase
    });
  } else {
    params.params = { tradable_product: `eq.${id}` }; // eslint-disable-line camelcase
  }

  return mutation({
    queryKey: [
      "inFocusTradableProducts",
      {
        action: "inFocusTradableProducts",
        enabled: true,
        ...params,
      },
    ],
  });
};

const updateDraftData = (dataSource, newValue) => {
  const { id, type, notes, price, size } = newValue;
  const updateInd = dataSource.findIndex((t) => t.tradableProduct["id"] === id);
  const isDraft = !(size && price);
  if (type === "bid") {
    dataSource[updateInd].bidSize = size;
    dataSource[updateInd].bidPrice = price;
    dataSource[updateInd].bidNotes = notes;
    dataSource[updateInd].bidType = type;
    dataSource[updateInd].draftBid = isDraft;
  } else {
    dataSource[updateInd].offerSize = size;
    dataSource[updateInd].offerPrice = price;
    dataSource[updateInd].offerNotes = notes;
    dataSource[updateInd].offerType = type;
    dataSource[updateInd].draftOffer = isDraft;
  }
  return dataSource;
};

const priceTableSaveOffer = (params) => {
  const { notes, price, size, tableType, type, id } = params;
  return queryClient.fetchQuery({
    queryKey: [
      "rehub_bid_offers",
      {
        action: "rehub_bid_offers",
        fetchOptions: { method: "POST" },
        body: JSON.stringify({
          type,
          notes: notes === "" ? null : notes,
          price: price === "" ? null : price,
          // eslint-disable-next-line camelcase
          tradable_product: id,
          volume: size === "" ? null : size,
          firmness: tableType,
        }),
        enabled: true,
      },
    ],
  });
};

const getBidData = (field, bidSize, bidPrice) => {
  const editingBid =
    field === "bidSize" || field === "bidPrice" || field === "bidNotes";
  const hasBid = bidSize && bidSize !== "" && bidPrice && bidPrice !== "";
  const clearingBid =
    (bidSize === null || bidSize === "") &&
    (bidPrice === null || bidPrice === "");

  return {
    editingBid,
    hasBid,
    clearingBid,
  };
};

const getOfferData = (field, offerSize, offerPrice) => {
  const editingOffer =
    field === "offerSize" || field === "offerPrice" || field === "offerNotes";
  const hasOffer =
    offerSize && offerSize !== "" && offerPrice && offerPrice !== "";
  const clearingOffer =
    (offerSize === null || offerSize === "") &&
    (offerPrice === null || offerPrice === "");

  return {
    editingOffer,
    hasOffer,
    clearingOffer,
  };
};

const getDisplayName = (data): string => {
  const displayNameParts = [data.short_name];
  if (data && data.name_parts.length > 6 && data.name_parts[6])
    displayNameParts.push(data.name_parts[6]);
  if (data.certificate_project && data.name_parts[5])
    displayNameParts.push(`(${data.name_parts[5]})`);
  return displayNameParts.join(" ");
};

const getEnvironmentalProductDisplayName = (data) => {
  const namePart = data.name_parts.filter((el, index) => {
    return el !== null && index !== 0;
  });
  const displayShortName: string = getDisplayName(data);

  return data.short_name ? displayShortName : namePart.join(" ");
};

const getInFocusProductDisplayName = (data) => {
  const displayShortName: string = getDisplayName(data);
  return data.certificate + " " + displayShortName;
};

const renderProductName = (data, type, isInFocusTable): string => {
  return type === "environmental" || type === "carbon"
    ? isInFocusTable
      ? getInFocusProductDisplayName(data)
      : getEnvironmentalProductDisplayName(data)
    : data.code;
};
const productColRender = (
  data,
  isAdmin,
  type,
  disableInFocus,
  isInFocusTable,
  onUpdateInFocus
) => {
  return (
    <>
      {isAdmin && !disableInFocus && (
        <Checkbox
          defaultChecked={data.in_focus}
          onChange={(value) => {
            onUpdateInFocus(data.id, value);
          }}
          style={{ marginRight: 5 }}
        />
      )}
      <Tooltip title={data.name}>
        {renderProductName(data, type, isInFocusTable)}
      </Tooltip>
    </>
  );
};

const volColRender = (
  lastTenIncludingStrip,
  ld,
  ls,
  tradableProduct,
  momentFormatTimeStamp
) => {
  const last = lastTenIncludingStrip
    ? lastTenIncludingStrip[lastTenIncludingStrip.length - 1]
    : // eslint-disable-next-line camelcase
      { time: ld, is_strip: false, volume: ls };
  const { volume: lastVolume, time: lastDate, is_strip: isStrip } = last;

  const cell = (
    <TradableProductPopover id={tradableProduct}>
      <VolumeCell
        volume={lastVolume}
        date={moment(lastDate).format(momentFormatTimeStamp.format)}
      />
    </TradableProductPopover>
  );

  return isStrip ? (
    <Badge dot={isStrip} title={"Part of a strip trade"} color={"#23e9ae"}>
      {cell}
    </Badge>
  ) : (
    cell
  );
};

const lastColRender = (
  lastTenIncludingStrip,
  tradableProduct,
  lp,
  ld,
  momentFormatTimeStamp
) => {
  const last = lastTenIncludingStrip
    ? lastTenIncludingStrip[lastTenIncludingStrip.length - 1]
    : // eslint-disable-next-line camelcase
      { price: lp, time: ld, is_strip: false };
  const { price: lastPrice, time: lastDate, is_strip: isStrip } = last;

  const cell = (
    <TradableProductPopover id={tradableProduct}>
      <PriceCell
        price={lastPrice}
        date={moment(lastDate).format(momentFormatTimeStamp.format)}
      />
    </TradableProductPopover>
  );

  return isStrip ? (
    <Badge dot={isStrip} title={"Part of a strip trade"} color={"#23e9ae"}>
      {cell}
    </Badge>
  ) : (
    cell
  );
};

const GetAllColumns = (
  columnWidth,
  isAdmin,
  type,
  disableInFocus,
  isInFocusTable
) => {
  const momentFormatTimeStamp = useContext(DEPRECATEDDateFormatContextDate);

  const inFocusMutation = useMutation(updateInFocus, {
    onSuccess: () => {
      queryClient.invalidateQueries("snapshots_detail");
    },
  });

  const onUpdateInFocus = (id, value) => {
    inFocusMutation.mutate({ id, value });
  };

  return [
    {
      title: "PRODUCT",
      key: "code",
      // eslint-disable-next-line sonarjs/no-duplicate-string
      className: classNames(
        "text-bold",
        "thead-font-size",
        "text-left",
        "cell-padding"
      ),
      dataIndex: "tradableProduct",
      render: (data) =>
        productColRender(
          data,
          isAdmin,
          type,
          disableInFocus,
          isInFocusTable,
          onUpdateInFocus
        ),
      editable: false,
    },
    {
      title: "VOL",
      key: "bidSize",
      dataIndex: "bidSize",
      render: (value, { bidType, draftBid }) => (
        <Display
          value={volumeFormatter(value)}
          firmness={bidType}
          draft={draftBid}
          message={"Could not save, the bid's price is missing."}
        />
      ),
      className: classNames(
        "text-center",
        "cell",
        "cell-environmental",
        "thead-font-size"
      ),
      width: columnWidth,
    },
    {
      title: "BID\u00a0NOTES",
      key: "bidNotes",
      dataIndex: "bidNotes",
      className: classNames("text-center", "cell", "thead-font-size"),
      width: columnWidth,
      render: (value, { bidType, draftBid }) => (
        <Display
          value={value}
          draft={draftBid}
          message={"Not yet saved, missing information"}
          firmness={bidType}
        />
      ),
    },
    {
      title: "BID",
      key: "bidPrice",
      dataIndex: "bidPrice",
      render: (value, { bidType, draftBid }) => (
        <Display
          value={priceFormatter(value, false)}
          draft={draftBid}
          message={"Could not save, the bid's volume is missing."}
          firmness={bidType}
        />
      ),
      className: classNames("text-center", "cell", "thead-font-size"),
      width: columnWidth,
    },
    {
      title: "OFFER",
      key: "offerPrice",
      dataIndex: "offerPrice",
      render: (value, { offerType, draftOffer }) => (
        <Display
          value={priceFormatter(value, false)}
          draft={draftOffer}
          message={"Could not save, the offer's volume is missing."}
          firmness={offerType}
        />
      ),
      className: classNames("text-center", "cell", "thead-font-size"),
      width: columnWidth,
    },
    {
      title: "OFFER\u00a0NOTES",
      key: "offerNotes",
      dataIndex: "offerNotes",
      className: classNames("text-center", "cell", "thead-font-size"),
      width: columnWidth,
      render: (value, { offerType, draftOffer }) => (
        <Display
          value={value}
          draft={draftOffer}
          message={"Not yet saved, missing information."}
          firmness={offerType}
        />
      ),
    },
    {
      title: "VOL",
      key: "offerSize",
      dataIndex: "offerSize",
      render: (value, { offerType, draftOffer }) => (
        <Display
          value={volumeFormatter(value)}
          message={"Could not save, the offer's price is missing."}
          draft={draftOffer}
          firmness={offerType}
        />
      ),
      className: classNames("text-center", "cell", "thead-font-size"),
      width: columnWidth,
    },
    {
      title: "LAST",
      key: "lastTenIncludingStrip",
      dataIndex: "lastTenIncludingStrip",
      className: classNames("text-center", "thead-font-size"),
      editable: false,
      render: (
        lastTenIncludingStrip,
        { tradableProduct, lastPrice: lp, lastDate: ld }
      ) =>
        lastColRender(
          lastTenIncludingStrip,
          tradableProduct,
          lp,
          ld,
          momentFormatTimeStamp
        ),
      width: columnWidth,
    },
    {
      title: "VOL",
      key: "lastSize",
      dataIndex: "lastTenIncludingStrip",
      className: classNames("text-center", "thead-font-size"),
      editable: false,
      render: (
        lastTenIncludingStrip,
        { lastDate: ld, lastSize: ls, tradableProduct }
      ) =>
        volColRender(
          lastTenIncludingStrip,
          ld,
          ls,
          tradableProduct,
          momentFormatTimeStamp
        ),
      width: columnWidth,
    },
  ];
};

const getDataSource = (
  sync,
  data,
  localChanges
): Merge<SnapshotRecord, { draftBid: boolean; draftOffer: boolean }>[] => {
  if (!sync) {
    return [];
  }
  return data.map((d) =>
    localChanges.hasOwnProperty(d.tradableProduct.id)
      ? {
          ...localChanges[d.tradableProduct.id],
          draftBid:
            localChanges[d.tradableProduct.id].bidPrice !== d.bidPrice ||
            localChanges[d.tradableProduct.id].bidSize !== d.bidSize,
          draftOffer:
            localChanges[d.tradableProduct.id].offerPrice !== d.offerPrice ||
            localChanges[d.tradableProduct.id].offerSize !== d.offerSize,
        }
      : {
          ...d,
          draftOffer: false,
          draftBid: false,
        }
  );
};
export type PriceTableProps = Merge<
  {
    testID: TestID;
    products?: {};
    name?: string;
    cap?: boolean | undefined;
    theme?: "w" | "b";
    region?: string | null;
    group?: (() => void) | null;
    rowKey?: string;
    tableType?: string;
    size?: {
      width: any;
      height: any;
    };
    showLastUpdate?: boolean;
    disableInFocus?: boolean;
    inFocus?: boolean | null;
    className?: string;
    option?: boolean | null;
    genericProductsOnly?: boolean;
    showPublishedProjectOnly?: boolean;
    filtersOr?: {};
  },
  snapshotRequired
>;

export const PriceTable = sizeMe({ noPlaceholder: true })(
  ({
    testID,
    type,
    name,
    cap,
    theme = "w",
    region = null,
    group = null,
    rowKey = "tradableProductName",
    tableType = "firm",
    tradableProducts = undefined,
    size,
    showLastUpdate = true,
    disableInFocus = true,
    inFocus = null,
    codes = undefined,
    option = null,
    genericProductsOnly,
    showPublishedProjectOnly,
    filtersOr = {},
  }: // eslint-disable-next-line sonarjs/cognitive-complexity
  PriceTableProps) => {
    const isAdmin = useUserIsAdmin();
    const [localStateData, setLocalStateData] =
      useState<
        Merge<SnapshotRecord, { draftBid: boolean; draftOffer: boolean }>[]
      >();
    const [isUpdatingRow, setUpdatingRow] = useState<boolean>(false);
    const [localChanges, setLocalChanges] = useState({});
    const [lastLocalChangeUpdate, setLastLocalChangeUpdate] =
      useState<Moment>();
    const addChange = (draftSnapshot) => {
      setLocalChanges((curLocalChanges) => ({
        ...curLocalChanges,
        [draftSnapshot.tradableProduct.id]: draftSnapshot,
      }));
      setLastLocalChangeUpdate(moment());
    };
    const removeChange = (tradableProduct) => {
      setLocalChanges((curLocalChanges) => {
        delete curLocalChanges[tradableProduct];
        return curLocalChanges;
      });
      setLastLocalChangeUpdate(moment());
    };

    const columnWidth = size?.width > 1599 && size?.width < 2200 ? 65 : 90;

    const allColumns = GetAllColumns(
      columnWidth,
      isAdmin,
      type,
      disableInFocus,
      !!inFocus
    );
    const columns = allColumns.filter((column) => {
      if (isAdmin && tableType === "firm") {
        return true;
      }
      return !column.title.includes("NOTES");
    });

    const {
      data: { data },
      loading,
      sync,
      error,
      lastUpdate,
    }: {
      data: { data: SnapshotRecord[] };
      loading?: boolean | undefined;
      sync: boolean;
      error?: Error | false;
      lastUpdate?: Moment;
    } = useSnapshots({
      region,
      cap,
      name,
      type: type,
      strip: false,
      onlyFirm: false,
      tradableProducts,
      skip:
        !(
          (tradableProducts && tradableProducts.length > 0) ||
          (codes && codes.length > 0)
        ) && !type,
      inFocus: inFocus ?? null,
      codes,
      option,
      genericProductsOnly,
      showPublishedProjectOnly,
      filter: keysToSnake({
        ...filtersOr,
      }),
    });

    const saveTableMutation = useMutation(priceTableSaveOffer, {
      onMutate: async (newValue) => {
        const updatingData = updateDraftData(dataSource, newValue);
        setLocalStateData(updatingData);
        setUpdatingRow(true);
      },
      onSuccess: (res, { id, removeChange }) => {
        removeChange(id);
        queryClient
          .invalidateQueries(isAdmin ? "snapshots_detail" : "snapshots")
          .then(() => setUpdatingRow(false));
        message.success(`Updated`);
      },
      onError: ({ response: { data: err } }) => {
        message.error(err.message);
        console.error(err);
      },
    });

    const onSaveTable = (newRow, field, addChange, removeChange, tableType) => {
      const {
        bidPrice,
        bidSize,
        bidNotes,
        tradableProduct: { id },
        offerPrice,
        offerSize,
        offerNotes,
      } = newRow;

      const getBid = getBidData(field, bidSize, bidPrice);
      const getOffer = getOfferData(field, offerSize, offerPrice);
      if (
        !(
          getBid.editingBid &&
          (getBid.hasBid || getBid.clearingBid) &&
          getOffer.editingOffer &&
          (getOffer.hasOffer || getOffer.clearingOffer)
        )
      ) {
        addChange(newRow);
      }
      if (getBid.editingBid && (getBid.hasBid || getBid.clearingBid)) {
        saveTableMutation.mutate({
          notes: bidNotes,
          price: bidPrice,
          size: bidSize,
          tableType: tableType,
          type: "bid",
          id: id,
          removeChange: removeChange,
        });
      }
      if (
        getOffer.editingOffer &&
        (getOffer.hasOffer || getOffer.clearingOffer)
      ) {
        saveTableMutation.mutate({
          notes: offerNotes,
          price: offerPrice,
          size: offerSize,
          tableType: tableType,
          type: "offer",
          id: id,
          removeChange: removeChange,
        });
      }
    };

    if (error) {
      console.error(error);
      return <Result status="error" title="Error loading table data" />;
    }

    const dataSource = getDataSource(sync, data, localChanges);
    const getTableData = () => {
      return isUpdatingRow &&
        lastLocalChangeUpdate &&
        lastLocalChangeUpdate.isAfter(lastUpdate)
        ? localStateData
        : dataSource;
    };

    return (
      <TestIDWrapper testID={testID}>
        <div className={classNames("price-table", `${tableType}-table`)}>
          <div
            className={classNames(
              { "admin-user": isAdmin },
              { "approved-user": !isAdmin }
            )}
          >
            <DEPRECATEDCORETable
              lastUpdate={lastLocalChangeUpdate ?? lastUpdate}
              rowKey={rowKey}
              loading={loading && !sync}
              dataSource={getTableData()}
              theme={theme}
              columns={columns}
              defaultExpandedRowKeys={["yearly"]}
              showLastUpdate={showLastUpdate}
              editMode={
                (type === "rehub" ||
                  type === "environmental" ||
                  type === "carbon") &&
                isAdmin
              }
              pagination={false}
              onSave={(newRow, field) => {
                onSaveTable(newRow, field, addChange, removeChange, tableType);
              }}
              group={group}
              tableType={tableType}
            />
          </div>
        </div>
      </TestIDWrapper>
    );
  }
);

type PriceCellProps = {
  price: number;
  date: string;
  onChange?: (value: string | number | null) => void;
  onCommit?: (value: string | number | undefined) => void;
  editMode?: boolean;
};

const PriceCell = forwardRef(
  (
    { price, date, onChange, onCommit, editMode = false }: PriceCellProps,
    ref
  ) => {
    if (!editMode)
      return (
        <Tooltip title={date} ref={ref}>
          {priceFormatter(price, false)}
        </Tooltip>
      );
    return (
      <Tooltip title={date} ref={ref}>
        <InputNumber
          formatter={(value) => priceFormatter(value)}
          parser={priceParser}
          value={price}
          readOnly={!editMode}
          onChange={onChange}
        />
      </Tooltip>
    );
  }
);

type VolumeCellProps = {
  volume: number;
  date: string;
  onChange?: (value: string | number | undefined) => void;
  onCommit?: (value: string | number | undefined) => void;
  editMode?: boolean;
};

const VolumeCell = forwardRef(
  (
    { volume, date, onChange, onCommit, editMode = false }: VolumeCellProps,
    ref
  ) => {
    if (!editMode)
      return (
        <Tooltip title={date} ref={ref}>
          <>{volumeFormatter(volume)}</>
        </Tooltip>
      );
    return (
      <Tooltip title={date} ref={ref}>
        <InputNumber
          precision={2}
          formatter={volumeFormatter}
          parser={volumeParser}
          value={volume}
          readOnly={!editMode}
          onChange={onChange}
        />
      </Tooltip>
    );
  }
);
