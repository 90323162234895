import React from "react";
import {
  COREFilters,
  COREFiltersProps,
  FilterItems,
} from "../../../../COREDesignSystem/Form/COREFilters";
import {
  CORERangePickerV2,
  RelativeRangeValue,
} from "../../../../COREDesignSystem/Form/CORERangePicker";
import { ArticlesAndUpdatesTypeSelect } from "../../../../shared/select/ArticlesAndUpdatesTypeSelect";
import { ArticlesAndUpdatesCategorySelect } from "../../../../shared/select/ArticlesAndUpdatesCategorySelect";
import moment from "moment";
import { ArticlesAndUpdates } from "../../../../openapi-typescript/common/articlesAndUpdates";
import { Order } from "../../../../shared/customHoooks/useUpdatable";

export type ArticlesAndUpdatesFilterValue = Partial<{
  publishedDate?: RelativeRangeValue;
  sortOrder: Order;
  type: ArticlesAndUpdates["fieldData"]["type"];
  categories: ArticlesAndUpdates["fieldData"]["categories"];
}>;

export const ArticlesAndUpdatesFilters: React.FC<
  Pick<COREFiltersProps, "onChange" | "testID"> & {
    value: ArticlesAndUpdatesFilterValue;
  }
> = ({ testID, onChange, value }) => {
  const { sortOrder, ...formatedFilterValues }: ArticlesAndUpdatesFilterValue =
    value && Object.keys(value).length > 0
      ? {
          ...value,
          publishedDate: value.publishedDate
            ? ({
                ...value.publishedDate,
                from: moment(value.publishedDate.from),
                to: moment(value.publishedDate.to),
              } as RelativeRangeValue)
            : undefined,
        }
      : {};
  const filterItems: FilterItems[] = [
    {
      label: "Type",
      key: "type",
      widthSize: "lg",
      input: (
        <ArticlesAndUpdatesTypeSelect
          size="lg"
          mode="multiple"
          placeholder="Select content type"
          testID={`${testID}-type-filter`}
        />
      ),
      dividerAfter: true,
    },
    {
      label: "Categories",
      key: "categories",
      widthSize: "lg",
      input: (
        <ArticlesAndUpdatesCategorySelect
          size="lg"
          mode="multiple"
          placeholder="Select categories"
          testID={`${testID}-categories-filter`}
        />
      ),
    },
    {
      label: "Published date range",
      key: "publishedDate",
      widthSize: "xl",
      input: (
        <CORERangePickerV2
          allowClear
          widthSize="xl"
          size="lg"
          placeholder={["Select date from", "Select date to"]}
          testID={`${testID}-published-date-filter`}
        />
      ),
    },
  ];

  return (
    <COREFilters
      onChange={onChange}
      testID={`${testID}-filter`}
      items={filterItems}
      value={formatedFilterValues as ArticlesAndUpdatesFilterValue}
      modalTitle="Insights filters"
      filterHeader="Insights filters"
      liteMode
    />
  );
};
