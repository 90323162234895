import { Counterparties } from "../../../../openapi-typescript/common/counterparties";
import { PartiesFilter } from "./CounterpartiesContainer";
import {
  APIResponse,
  FieldsOrders,
  TableOptions,
  useUpdatables,
} from "../../../../shared/customHoooks/useUpdatable";
import { mutation } from "../../../../shared/state/appQueryClient";
import { keysToSnake } from "../../../../shared/global";

type CounterPartiesFilter = keyof PartiesFilter;
type CounterPartiesOrder = FieldsOrders<CounterPartiesFilter>;

type CounterpartiesParams = Partial<
  PartiesFilter & {
    id: number;
    order: CounterPartiesOrder;
    fullTextSearch: string;
    skip?: boolean;
  }
>;

const filterBySearch = (search: CounterpartiesParams["search"]) =>
  search && { name: `ilike.*${search}*` };

const filterByCountry = (country: CounterpartiesParams["country"]) =>
  country && {
    "country.id": `eq.${country}`,
    country: `not.is.null`,
  };

const orderByString = (orderString: CounterpartiesParams["order"]) =>
  orderString && {
    order: orderString,
  };

const fullTextSearch = (search: CounterpartiesParams["fullTextSearch"]) =>
  search && { name: `plfts.${search}` };

export const buildCounterPartiesParams = (args: CounterpartiesParams) => {
  return {
    ...(args.id && { id: `eq.${args.id}` }),
    ...filterBySearch(args.search),
    ...filterByCountry(args.country),
    ...fullTextSearch(args.fullTextSearch),
    ...orderByString(args.order),
  };
};

export const useCounterparties = (
  args?: CounterpartiesParams,
  tableOption?: TableOptions
) => {
  const res = useUpdatables<
    Counterparties,
    Counterparties,
    Counterparties,
    Counterparties[]
  >(
    {
      get: [
        "getCounterParties",
        {
          order: "created_at.desc",
          ...(args && { ...buildCounterPartiesParams(args) }),
        },
        args && args.skip,
      ],
      add: (counterParties) => ({
        queryKey: [
          "addCounterParties",
          {
            action: "addCounterParties",
            body: JSON.stringify(counterParties),
            enabled: true,
          },
        ],
      }),
      update: (counterParties: Counterparties) => ({
        queryKey: [
          "editCounterParties",
          {
            params: { id: `eq.${counterParties.id}` },
            action: "editCounterParties",
            body: JSON.stringify(counterParties),
            enabled: true,
          },
        ],
      }),
      transformToRichTypes: (record) => record,
      invalidateQueries: ["getCounterParties"],
    },
    tableOption
  );

  const mergeDuplicates = async (merge: {
    toKeep: Counterparties["id"];
    toRemove: Counterparties["id"][];
  }) => {
    return await mutation<APIResponse<null>>({
      queryKey: [
        "mergeDuplicateCounterParties",
        {
          action: "mergeDuplicateCounterParties",
          body: JSON.stringify(keysToSnake(merge)),
          enabled: true,
        },
      ],
    });
  };

  if (args && args.id) {
    return {
      ...res,
      updatable: res.updatable.filter((u) => u.data?.id === args.id),
      mergeDuplicates: mergeDuplicates,
    };
  }

  return { ...res, mergeDuplicates: mergeDuplicates };
};

export const useCounterparty = (
  id: Exclude<CounterpartiesParams["id"], undefined>
) => {
  const { updatable, ...responses } = useCounterparties({
    id,
  });
  if (updatable) {
    return {
      counterparty: updatable[0],
      ...responses,
    };
  }

  return {
    counterparty: undefined,
    ...responses,
  };
};
