import React from "react";
import { Col, Row } from "antd";
import {
  CORERangePicker,
  RelativeRangeValue,
} from "../../../COREDesignSystem/Form/CORERangePicker";
import moment from "moment";
import { RpcAggFuncType, RpcAggPeriodType } from "./useChartBuilderApiV2";
import { COREBody } from "../../../COREDesignSystem/Typography/COREBody";
import { COREIcon } from "../../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { CORECard } from "../../../COREDesignSystem/Content/CORECard";
import { generateTestId } from "../../../shared/testids/testids";
import "./ChartBuilder.less";
import { spacing } from "../../../shared/global";

export const ChartBuilderTopBar = ({
  aggPeriod = "day",
  aggFunc = "avg",
  dateRange,
  onChange,
}: {
  aggPeriod?: RpcAggPeriodType;
  aggFunc?: RpcAggFuncType;
  dateRange: RelativeRangeValue;
  onChange: (param: any) => void;
}) => {
  const updateDateRange = (newDateRange: RelativeRangeValue) => {
    onChange({
      dateRange: newDateRange,
      aggPeriod,
      aggFunc,
    });
  };

  const convertDate = (range: RelativeRangeValue): RelativeRangeValue => {
    const { isRelative, from, to } = range;
    if (isRelative) {
      return range;
    } else {
      return {
        isRelative,
        from: moment(from),
        to: moment(to),
        bounds: "[)",
      };
    }
  };

  return (
    <CORECard
      testID={generateTestId("chartbuilder", "chart-builder-date-range-card")}
      hoverable={false}
      elevation={"none"}
    >
      <Row gutter={[spacing.xs, spacing.xs]}>
        <Col span={24}>
          <COREBody type="p2" strong>
            <COREIcon
              icon={icon({ name: "calendars", style: "regular" })}
              size={"xs"}
              className={"icon-colour"}
            />
            {"  "}
            Select date range
          </COREBody>
          <CORERangePicker
            size={"lg"}
            widthSize={"xl"}
            extended
            defaultValue={convertDate(dateRange)}
            onChange={updateDateRange}
            allowClear={false}
            value={dateRange}
            relativeToggle={true}
            testID={generateTestId("chartbuilder", "chart-builder-date-range")}
          />
        </Col>
      </Row>
    </CORECard>
  );
};
