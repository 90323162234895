import React from "react";
import { COREInputNumber, COREInputNumberProps } from "./COREInputNumber";
import { CurrencyName, CurrencyTooltip } from "../../shared/CurrencySymbol";

export type COREInputCurrencyProps = Omit<COREInputNumberProps, "button"> & {
  currency: CurrencyName;
};

export const COREInputCurrency: React.FC<COREInputCurrencyProps> = ({
  testID,
  size = "md",
  disabled,
  currency = "AUD",
  ...props
}) => {
  return (
    <COREInputNumber
      addonBefore={
        <CurrencyTooltip
          currencyCode={currency}
          testID={`${testID}-currency-addon`}
        />
      }
      testID={testID}
      size={size}
      disabled={disabled}
      precision={2}
      step={0.01}
      {...props}
    />
  );
};
