// TODO Please remove this file from nimported ignore list at '.unimportedrc.json' when this file already use
import { Card, CardProps, ImageProps, Space } from "antd";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import "./CORECard.less";
import classnames from "classnames";
import { CORETooltip } from "../Overlay/CORETooltip";
import React, {
  cloneElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { HOCErrorBoundary } from "../../shared/errors/ErrorBoundary";

type coverUrlProp =
  | {
      image: Exclude<ImageProps["src"], undefined>;
      imagePlaceholder?: ImageProps["src"];
      onClick?: () => void;
      height?: number;
    }
  | {
      image?: undefined | null;
      imagePlaceholder: Exclude<ImageProps["src"], undefined>;
      onClick?: () => void;
      height?: number;
    };

interface CommonProps {
  testID: TestID;
  coverUrl?: coverUrlProp;
  layout?: "vertical" | "horizontal";
  icon?: React.ReactNode;
  elevation?: "none" | "weak" | "intense";
  actions?: React.ReactElement[];
  lite?: boolean;
  badge?: ReactNode;
}

export type CORECardProps = CommonProps & CardProps;

const HorizontalCard: React.FC<
  Pick<CORECardProps, "children" | "coverUrl" | "lite" | "badge">
> = ({ children, coverUrl, lite, badge }) => (
  <div className={"core-card-horizontal"}>
    <div className={"img-box"}>
      {coverUrl && (
        <div
          {...(coverUrl.onClick && { onClick: coverUrl.onClick })}
          className={classnames("core-card-cover", {
            clickable: coverUrl.onClick,
          })}
          style={{
            backgroundImage: `url(${
              coverUrl?.image ?? coverUrl.imagePlaceholder
            })`,
            ...(coverUrl.height && { height: `${coverUrl.height}px` }),
          }}
        >
          {lite && (
            <>
              <div className={"overlay"} />
              <div className={"bage"}>{badge}</div>
            </>
          )}
        </div>
      )}
    </div>
    <div className={"content-box"}>{children}</div>
  </div>
);

const CORECardNoError: React.FC<CORECardProps> = ({
  testID,
  coverUrl,
  title,
  icon,
  className,
  hoverable = true,
  onClick,
  elevation = "weak",
  layout = "vertical",
  children,
  actions,
  lite = false,
  badge,
  ...prop
}) => {
  const textElementRef = useRef<HTMLDivElement>(null);
  const [isOverflowed, setIsOverflow] = useState<boolean>(false);

  const compareSize = () => {
    const compare =
      textElementRef.current !== null &&
      textElementRef.current!.scrollWidth > textElementRef.current!.clientWidth;
    setIsOverflow(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, [title]);

  const cover = coverUrl ? (
    <div
      {...(coverUrl.onClick && { onClick: coverUrl.onClick })}
      className={classnames(
        "core-card-cover",
        { clickable: coverUrl.onClick },
        className
      )}
      style={{
        backgroundImage: `url(${coverUrl?.image ?? coverUrl.imagePlaceholder})`,
        ...(coverUrl.height && { height: `${coverUrl.height}px` }),
      }}
    >
      {lite && (
        <>
          <div className={"overlay"} />
          <div className={"bage"}>{badge}</div>
        </>
      )}
    </div>
  ) : (
    prop.cover
  );
  if (icon) {
    title = (
      <Space size={8}>
        <div>{icon}</div>
        <div>{title}</div>
      </Space>
    );
  }

  const actionsWithStyle = actions?.map((childTree) => {
    return cloneElement(childTree, {
      ...childTree.props,
      className: classnames("core-card-actions", childTree.props.className),
    });
  });

  return (
    <TestIDWrapper testID={testID} className={"core-card"}>
      <Card
        {...prop}
        {...(layout === "vertical" && { cover: cover })}
        {...(title && {
          title: (
            <div>
              <CORETooltip
                title={""}
                message={title}
                position={"topLeft"}
                hidden={!isOverflowed}
                className={classnames("width-full", "tooltip-container")}
                testID={testID}
              >
                <div ref={textElementRef} className={"titleWithTooltip"}>
                  {title}
                </div>
              </CORETooltip>
            </div>
          ),
        })}
        className={classnames(
          "core-card-container",
          layout,
          { "disable-hover": !hoverable },
          { [elevation]: elevation !== "none" },
          { lite: lite },
          className
        )}
        hoverable={hoverable}
        actions={actionsWithStyle}
        {...(hoverable && { onClick: onClick })}
      >
        {layout === "vertical" ? (
          children
        ) : (
          <HorizontalCard coverUrl={coverUrl} lite={lite} badge={badge}>
            {children}
          </HorizontalCard>
        )}
      </Card>
    </TestIDWrapper>
  );
};

export const CORECard = HOCErrorBoundary(CORECardNoError);
