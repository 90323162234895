import React from "react";
import { TestID } from "../../../shared/testids/testids";
import { CORECard } from "../../../COREDesignSystem/Content/CORECard";
import { CORETag } from "../../../COREDesignSystem/Content/CORETag";
import DefaultImagePlaceholder from "../../../assets/img/certificate-project-default-banner.svg";
import { COREHeading } from "../../../COREDesignSystem/Typography/COREHeading";
import { COREBody } from "../../../COREDesignSystem/Typography/COREBody";
import { CORETagList } from "../../../COREDesignSystem/Content/CORETagList";
import { grey120 } from "../../../COREDesignSystem/Content/COREColour";
import { Col, Row, Space } from "antd";
import { displayRelativeDate } from "../../../shared/date/DateTime";
import moment from "moment";
import { COREButton } from "../../../COREDesignSystem/Action/COREButton";
import { COREIcon } from "../../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useHistory } from "react-router-dom";
import { CMSContent } from "../../../openapi-typescript/common/cms_content";
import { fileBaseUrl } from "../../../shared/state/rest";
import { useUser } from "../../../shared/state/user";
import {
  CMSContentExclusiveTag,
  CMSContentPremiumTag,
} from "../../../shared/tags/CMSContentPromoTag";

export const CMSContentCard: React.FC<{
  cmsContent: CMSContent;
  testID: TestID;
  isWidget: boolean;
}> = ({ cmsContent, testID, isWidget }) => {
  const history = useHistory();
  const companyID = useUser()?.companyId;
  const isBesopkeReports =
    cmsContent.companies.some(({ company }) => company === companyID) &&
    cmsContent.permissionType === "company";
  const coverUrl = {
    onClick: () =>
      history.push(
        `/insights/${
          isBesopkeReports ? "bespoke-reports" : "research-and-analysis"
        }/${cmsContent.id}`
      ),
    ...(cmsContent.headerImage && {
      image: `${fileBaseUrl()}/${cmsContent.headerImage}`,
    }),
    imagePlaceholder: DefaultImagePlaceholder,
    ...(!isWidget && { height: 220 }),
  };

  return (
    <>
      <CORECard
        layout="horizontal"
        badge={
          <Space direction="vertical" size={4}>
            {cmsContent?.contentTypes?.map((type) => (
              <CORETag
                key={type}
                label={type}
                type="colourful"
                colour="indigo100"
                tagStyle="solid"
                testID={`${testID}-badge`}
              />
            ))}
            {isBesopkeReports ? (
              <CMSContentExclusiveTag />
            ) : (
              <CMSContentPremiumTag />
            )}
          </Space>
        }
        elevation="none"
        lite
        bordered={false}
        hoverable={false}
        coverUrl={coverUrl}
        testID={`${testID}-card`}
      >
        <COREHeading level={5} testID={`${testID}-heading`}>
          {cmsContent.title}
        </COREHeading>
        <COREBody ellipsis={{ rows: 4 }} type="p3">
          {cmsContent.introduction}
        </COREBody>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <CORETagList truncateAfter={6} showTooltip autoCalculateTotal>
              {cmsContent.categories?.map((category: string) => (
                <CORETag label={category} type="basic" key={category} />
              ))}
            </CORETagList>
          </Col>
          <Col span={24}>
            <COREBody type="p5" color={grey120}>
              Published:{" "}
              {cmsContent.publishedAt
                ? displayRelativeDate(moment(cmsContent.publishedAt))
                : ""}
            </COREBody>
          </Col>
        </Row>
        <COREButton
          onClick={() =>
            history.push(
              `/insights/${
                isBesopkeReports ? "bespoke-reports" : "research-and-analysis"
              }/${cmsContent.id}`
            )
          }
          key="read-now"
          type="primary"
          size="sm"
          icon={<COREIcon icon={icon({ name: "book", style: "solid" })} />}
        >
          Read now
        </COREButton>
      </CORECard>
    </>
  );
};
