import React from "react";
import {
  CORESelectAPI,
  CORESelectAPIProps,
  groupOptions,
} from "../../COREDesignSystem/Form/CORESelectAPI";
import { CMSCategory } from "../../openapi-typescript/common/cms_category";

export const CMSCategorySelect: React.FC<
  Omit<CORESelectAPIProps<CMSCategory>, "transform" | "endpoint"> & {
    keyValue: keyof CMSCategory;
  }
> = ({ keyValue, ...props }) => (
  <CORESelectAPI
    {...props}
    transform={(data) => groupOptions(data, "name", keyValue)}
    endpoint="cmsCategories"
  />
);
