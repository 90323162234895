import { useUpdatable, useUpdatablesWithPaginate } from "./useUpdatable";
import { CMSContent } from "../../openapi-typescript/common/cms_content";
import { CMSContentFilterValue } from "../../modules/insights/cmsContents/CMSContentFilters";
import {
  convertMomentToMarketTimezone,
  dateFormatWithTimeZone,
  formatRelativeRangeFilterForServer,
} from "../date/DateTime";
import { keysToSnake } from "../global";
import { useUser } from "../state/user";
import { useCMSContentTypeScopes } from "../select/CMSContentTypeSelect";
import moment from "moment";

export type UseCMSContentsProps = {
  limit: number;
  offset: number;
  page: "researchAndAnalysis" | "bespokeReports" | "dashboard";
} & CMSContentFilterValue;

const select =
  "id,title,introduction,body,headerImage:header_image,contentTypes:content_types,permissionType:permission_type,requiredScopes:required_scopes,isDraft:is_draft,publishedAt:published_at,createdUser:created_user,createdAt:created_at,modifiedUser:modified_user,modifiedAt:modified_at,categories,companies:cms_content_companies!inner(company),files:cms_content_files(file:files(id,contentType:type,fileName:name,modifiedAt:uploaded_at,deletedAt:deleted_at))";

const defaultFilters = keysToSnake({
  isDraft: "eq.false",
  publishedAt: `lt.${convertMomentToMarketTimezone(moment()).format(
    dateFormatWithTimeZone
  )}`,
});

export const useCMSContents = ({
  limit,
  offset,
  sortOrder,
  search,
  type,
  categories,
  publishedDate,
  page,
}: UseCMSContentsProps) => {
  const companyId = useUser()?.companyId;
  const contentTypeScopes = useCMSContentTypeScopes();
  const contentType = type && type.length !== 0 ? type : contentTypeScopes;
  const permissionParam = {
    bespokeReports: {
      "companies.company": `eq.${companyId}`,
      ...keysToSnake({ permissionType: "eq.company" }),
    },
    researchAndAnalysis: {
      or: "(permission_type.eq.scopes,and(permission_type.is.null,companies->company.is.null))",
    },
    dashboard: {
      or: `(permission_type.in.(scopes,company),and(permission_type.is.null,companies->company.is.null)),companies.company.eq.${companyId})`,
    },
  }[page];

  return useUpdatablesWithPaginate<CMSContent[]>(
    {
      get: [
        "cmsContents",
        {
          ...defaultFilters,
          select: `${select}${
            categories && categories.length !== 0
              ? ",filterCategories:cms_content_categories!inner(category)"
              : ""
          }`,
          ...permissionParam,
          ...(search && { title: `ilike.*${search}*` }),
          ...(sortOrder && { order: `created_at.${sortOrder}` }),
          ...(categories &&
            categories.length !== 0 && {
              "filterCategories.category": `in.(${categories.join(",")})`,
            }),
          ...(contentType &&
            contentType.length !== 0 &&
            keysToSnake({ contentTypes: `ov.{${contentType.join(",")}}` })),
          ...(publishedDate && {
            and: formatRelativeRangeFilterForServer(
              publishedDate,
              "published_at",
              dateFormatWithTimeZone
            ),
          }),
        },
      ],
      transformToRichTypes: (record) => record,
    },
    undefined,
    limit,
    offset
  );
};

export const useCMSContent = ({
  id,
  page,
}: {
  id?: CMSContent["id"];
} & Pick<UseCMSContentsProps, "page">) => {
  const companyId = useUser()?.companyId;
  const contentTypeScopes = useCMSContentTypeScopes();

  const permissionParam = {
    bespokeReports: {
      "companies.company": `eq.${companyId}`,
      ...keysToSnake({ permissionType: "eq.company" }),
    },
    researchAndAnalysis: {
      or: "(permission_type.eq.scopes,and(permission_type.is.null,companies->company.is.null))",
    },
    dashboard: {
      or: `(permission_type.in.(scopes,company),and(permission_type.is.null,companies->company.is.null)),companies.company.eq.${companyId})`,
    },
  }[page];

  return useUpdatable<CMSContent>({
    get: [
      "cmsContents",
      {
        ...(contentTypeScopes &&
          contentTypeScopes.length !== 0 &&
          keysToSnake({ contentTypes: `ov.{${contentTypeScopes.join(",")}}` })),
        ...defaultFilters,
        ...(id && { id: `eq.${id}` }),
        ...permissionParam,
        select: select,
      },
      id === undefined,
    ],
    transformToRichTypes: (record) => record,
  });
};
