import React from "react";
import { CORESelectProps } from "../../COREDesignSystem/Form/CORESelect";
import {
  CORESelectWithPlaceHolderAPI,
  groupOptions,
} from "../../COREDesignSystem/Form/CORESelectAPI";

export const AllAccreditationSelect: React.FC<CORESelectProps> = ({
  size = "lg",
  placeholder = "Accreditation",
  ...props
}) => {
  return (
    <CORESelectWithPlaceHolderAPI
      transform={(data) => groupOptions(data, "name", "name", "name")}
      endpoint={"allGreenProjectProductTypes"}
      size={size}
      placeholder={placeholder}
      {...props}
    />
  );
};
