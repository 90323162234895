import React from "react";
import { CORETag } from "../../COREDesignSystem/Content/CORETag";

export const CMSContentPremiumTag = () => (
  <CORETag
    label="Premium"
    type="colourful"
    colour={"green100"}
    tagStyle="solid"
  />
);

export const CMSContentExclusiveTag = () => (
  <CORETag
    label="Exclusive"
    type="colourful"
    colour="purple100"
    tagStyle="solid"
  />
);
