import { Col, ColProps, Row, RowProps } from "antd";
import React, {
  Children,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { CORETag } from "./CORETag";
import { TestID } from "../../shared/testids/testids";
import { CORETooltip } from "../Overlay/CORETooltip";
import "./CORETagList.less";
import classNames from "classnames";
import { spacing } from "../../shared/global";

export type CORETagListProps = {
  children: ReactNode;
  truncateAfter?: number;
  showTooltip?: boolean;
  testID?: TestID;
  vertical?: boolean;
  autoCalculateTotal?: boolean;
} & Pick<RowProps, "wrap" | "justify"> &
  Pick<ColProps, "span">;

const TooltipContents: React.FC<{ hiddenChildren: ReactNode[] }> = ({
  hiddenChildren,
}) => {
  return (
    <Row
      gutter={[0, spacing.xxs]}
      justify={"center"}
      className={"tag-list-vertical"}
    >
      {hiddenChildren.map((child, index) => (
        <Col key={index}>{child}</Col>
      ))}
    </Row>
  );
};

const TotalTags: React.FC<
  Pick<CORETagListProps, "showTooltip" | "testID"> & {
    hiddenChildren: ReactNode[];
  }
> = ({ showTooltip, hiddenChildren, testID }) => {
  return (
    <CORETooltip
      overlay
      hidden={!showTooltip}
      message={<TooltipContents hiddenChildren={hiddenChildren} />}
      testID={`${testID}-tags-tooltip` as TestID}
    >
      <CORETag label={`+${hiddenChildren.length}`} type={"basic"} />
    </CORETooltip>
  );
};

export const CORETagList: React.FC<CORETagListProps> = ({
  children,
  truncateAfter,
  wrap = false,
  justify = "start",
  vertical = false,
  showTooltip = false,
  span,
  testID,
  autoCalculateTotal = false,
}) => {
  const [visibleChildren, setVisibleChildren] = useState<ReactNode[]>([]);
  const [hiddenChildren, setHiddenChildren] = useState<ReactNode[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const calculateChildren = useCallback(() => {
    const arrayChildren = Children.toArray(children);

    if (truncateAfter === undefined) return;
    if (autoCalculateTotal !== undefined && !autoCalculateTotal) {
      setVisibleChildren(arrayChildren.slice(0, truncateAfter));
      setHiddenChildren(arrayChildren.slice(truncateAfter));
      return;
    }

    if (containerRef.current && autoCalculateTotal) {
      const containerWidth = containerRef.current.offsetWidth;
      const extraFreeSpace = 64;

      const itemElements = containerRef.current.querySelectorAll("div.ant-col");
      const totalTagsElement =
        containerRef.current.querySelector(".total-tags");

      const totalItemWidth = Array.from(itemElements).reduce((sum, item) => {
        const { width } = item.getBoundingClientRect();
        return sum + width;
      }, 0);

      const averageItemWidth =
        itemElements.length > 0 ? totalItemWidth / itemElements.length : 100;

      const totalTagsWidth = totalTagsElement
        ? totalTagsElement.getBoundingClientRect().width
        : 0;

      const availableWidth = containerWidth - totalTagsWidth - extraFreeSpace;

      const itemsPerRow = Math.floor(availableWidth / averageItemWidth);
      const newTruncateAfter = Math.min(
        truncateAfter || arrayChildren.length,
        itemsPerRow
      );

      setVisibleChildren(arrayChildren.slice(0, newTruncateAfter));
      setHiddenChildren(arrayChildren.slice(newTruncateAfter));
    }
  }, [autoCalculateTotal, children, truncateAfter]);

  useEffect(() => {
    const handleResize = () => {
      calculateChildren();
    };

    if (containerRef.current) {
      calculateChildren();
    }

    window.addEventListener("resize", handleResize);

    const resizeObserver = new ResizeObserver(() => {
      calculateChildren();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      resizeObserver.disconnect();
    };
  }, [children, truncateAfter, calculateChildren]);

  if (truncateAfter === undefined) {
    return (
      <Row gutter={[4, 4]}>
        {Children.map(children, (child) => (
          <Col>{child}</Col>
        ))}
      </Row>
    );
  }

  return (
    <div ref={containerRef}>
      <Row
        gutter={[spacing.xxs, spacing.xxs]}
        wrap={wrap}
        justify={justify}
        className={classNames({ "tag-list-vertical": vertical })}
      >
        {visibleChildren.map((child, index) => (
          <Col key={index} span={span}>
            {child}
          </Col>
        ))}
        {hiddenChildren.length > 0 && (
          <Col key="total-tags" span={span} className="total-tags">
            <TotalTags
              testID={testID}
              hiddenChildren={hiddenChildren}
              showTooltip={showTooltip}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
