import { NewsAndAlert } from "../../../../openapi-typescript/common/newsAndAlert";
import { FeatureFlag } from "../../../../shared/customHoooks/useFeatureFlags";

export const cmsNewsAndAlertLink = () =>
  `/admin/content-management/news-and-alerts`;
export const cmsNewsAndAlertAddLink = () =>
  `/admin/content-management/news-and-alerts/add`;
export const cmsNewsAndAlertEditLink = (id: NewsAndAlert["id"]) =>
  `/admin/content-management/news-and-alerts/${id}/edit`;

export const getNewsAndAlertsPermission = (features: FeatureFlag[]) => {
  return (
    features?.includes("news-and-alerts") &&
    (features?.includes("core.market-alerts.read") ||
      features?.includes("core.news-alerts.read"))
  );
};
