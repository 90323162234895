import React from "react";
import moment from "moment";
import {
  COREDatePickerProps,
  COREDatePicker,
} from "../../COREDesignSystem/Form/COREDatePicker";
import { disabledDate } from "../../modules/dailyCurves/CarbonDailyCurvePageWrapper";
import { useUserIsAdmin } from "../state/user";
import { TestID } from "../testids/testids";

export const CurveDatePicker: React.FC<
  {
    testID: TestID;
    value?: string | null;
    publishedDateArray?: (string | undefined)[];
  } & Pick<COREDatePickerProps, "onChange" | "disabledDate">
> = ({ publishedDateArray, testID, value, ...props }) => {
  const isAdmin = useUserIsAdmin();
  return (
    <COREDatePicker
      {...(!isAdmin &&
        publishedDateArray && {
          disabledDate: (current: moment.Moment) =>
            disabledDate(current, publishedDateArray),
        })}
      testID={`${testID}-end-of-day-selector`}
      size="lg"
      widthSize="lg"
      allowClear={false}
      value={moment(value)}
      {...props}
    />
  );
};
