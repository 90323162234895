import { AwayBrokers } from "../../../../openapi-typescript/common/away_brokers";
import { AwaybrokersFilterValues } from "./AwaybrokersFilter";
import {
  FieldsOrders,
  TableOptions,
  useUpdatables,
} from "../../../../shared/customHoooks/useUpdatable";

export const useAwayBrokers = (
  args?: AwaybrokersFilterValues & {
    order?: FieldsOrders<keyof AwayBrokers>;
  },
  tableOption?: TableOptions
) => {
  return useUpdatables<AwayBrokers>(
    {
      get: [
        "getAwayBrokers",
        {
          ...(args && args.search && { name: `ilike.*${args.search}*` }),
          ...(args && args.order && { order: args.order }),
          ...(args &&
            args.fullTextSearch && { name: `plfts.${args.fullTextSearch}` }),
        },
        args?.skip,
      ],
      add: (awayBrokers) => ({
        queryKey: [
          "addAwayBrokers",
          {
            action: "addAwayBrokers",
            body: JSON.stringify(awayBrokers),
            enabled: true,
          },
        ],
      }),
      update: (awayBrokers) => ({
        queryKey: [
          "updateAwayBrokers",
          {
            params: { id: `eq.${awayBrokers.id}` },
            action: "updateAwayBrokers",
            body: JSON.stringify(awayBrokers),
            enabled: true,
          },
        ],
      }),
      transformToRichTypes: (record) => record,
    },
    tableOption
  );
};
