import moment, { Moment } from "moment";
import React from "react";
import {
  COREFilters,
  FilterItems,
} from "../../../COREDesignSystem/Form/COREFilters";
import { CurveDatePicker } from "../../../shared/date/CurveDatePicker";
import { momentFormat } from "../../../shared/date/DateFormatContext";
import { TestID } from "../../../shared/testids/testids";
import { BrokerChartFilter } from "../carbonComponent/BrokerChartWithFilter";

const serverDateFormat = momentFormat.server.format;

export const EnvironmentalForwardCurveChartFilter: React.FC<{
  testID: TestID;
  publishedDateArray?: (string | undefined)[];
  filterValue?: BrokerChartFilter;
  onFilterChange?: (filter: BrokerChartFilter) => void;
}> = ({ testID, publishedDateArray, filterValue, onFilterChange }) => {
  const items: FilterItems[] = [
    {
      label: "Curve date",
      key: "curveDate",
      widthSize: "lg",
      dividerAfter: true,
      input: (
        <CurveDatePicker
          publishedDateArray={publishedDateArray}
          testID={testID}
        />
      ),
    },
  ];

  return (
    <COREFilters
      liteMode={true}
      allowClear={false}
      testID={testID}
      items={items}
      onChange={(v: Record<string, unknown>) => {
        const dateSelected = moment(v.curveDate as Moment).isValid()
          ? moment(v.curveDate as Moment).format(serverDateFormat)
          : moment().format(serverDateFormat);
        onFilterChange &&
          onFilterChange({
            curveDate: dateSelected,
          });
      }}
      value={filterValue ?? {}}
      modalTitle="Price history filters"
      filterHeader="Price history filters"
      debounceTime={0}
    />
  );
};
