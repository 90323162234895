import React from "react";
import { AccreditationSelect } from "../../shared/select/AccreditationSelect";
import {
  CORESelect,
  CORESelectProps,
} from "../../COREDesignSystem/Form/CORESelect";
import { COREInput } from "../../COREDesignSystem/Form/COREInput";
import { generateTestId, TestID } from "../../shared/testids/testids";
import { groupOptions } from "../../COREDesignSystem/Form/CORESelectAPI";
import {
  COREFilters,
  COREFiltersProps,
  FilterItems,
} from "../../COREDesignSystem/Form/COREFilters";
import { UseLocalStorageUntilLogoutProps } from "../../shared/customHoooks/useLocalStorageUntilLogout";
import { params } from "./projectMarketplace/ProjectMarketplaceFilter";
import { CertificateProjectsFilterValues } from "../../shared/customHoooks/useGreenProject";
import { LocationSelect } from "../../shared/select/LocationSelect";
import { datum as Datum } from "../../shared/datum";
import { useAPIQueryRequests } from "../../shared/customHoooks/useAPI";
import { unitedNationsSdGoalType } from "./projectMarketplace/UNSdGoalComponent";
import { filterInDataByKey } from "./projectMarketplace/ProjectMarketplaceContainer";
import { BeZeroRatingSelector } from "./projectMarketplace/BeZeroCarbonRating/BeZeroRatingSelector";
import { COREInputNumber } from "../../COREDesignSystem/Form/COREInputNumber";
import moment from "moment/moment";
import { ProductTypes } from "../dailyCurves/carbonComponent/EodEditor";
import { useScopes } from "../../shared/state/user";
import { MethodSelect } from "../../shared/select/MethodSelect";
import { Year } from "../../shared/global";
import { BeZeroCarbonRatingType } from "./projectMarketplace/BeZeroCarbonRating/BeZeroCarbonRatingTag";
import { CreditTypeSelect } from "../../shared/select/CreditTypeSelect";

const { Search } = COREInput;

export type FilterType = CertificateProjectsFilterValues & {
  vintage?: Year[];
  bezero?: BeZeroCarbonRatingType[];
  coBenefits?: number[];
  minPrice?: number;
  maxPrice?: number;
};

export type CertificateProjectsFilters = {
  testID: TestID;
  modalTitle: string;
  filterHeaderText: string;
  defaultFilterValues?: FilterType;
  filterValues: FilterType;
  onChange:
    | ReturnType<UseLocalStorageUntilLogoutProps<FilterType>>[1]
    | ((value: FilterType) => void);
} & Pick<COREFiltersProps, "liteMode">;

export type FilterProps = Pick<
  CORESelectProps,
  | "onChange"
  | "value"
  | "widthSize"
  | "placeholder"
  | "size"
  | "disabled"
  | "mode"
  | "testID"
> & {
  productType?: CertificateProjectsFilters["filterValues"]["productType"];
};

export const selectProps: Pick<
  CORESelectProps,
  | "size"
  | "mode"
  | "maxTagCount"
  | "style"
  | "showArrow"
  | "showSearch"
  | "allowClear"
> = {
  size: "lg",
  mode: "multiple",
  maxTagCount: "responsive" as const,
  showArrow: true,
  showSearch: false,
  allowClear: true,
};

export const VintageFilter: React.FC<FilterProps> = ({
  testID,
  productType,
  value,
  ...props
}) => {
  const currentYear = moment().year();
  return (
    <AccreditationSelect
      value={value}
      size={"lg"}
      mode={"multiple"}
      testID={testID}
      placeholder={"Select vintage(s)"}
      transform={(data: ProductTypes[]) => {
        const earliestVintage = data
          .filter((d) => productType?.includes(d.code as string))
          .map((d) => d.earliestVintage) as number[];
        const year =
          earliestVintage.length !== 0 ? Math.min(...earliestVintage) : 2012;
        const forwardYears = year < currentYear ? currentYear - year + 11 : 11;
        return [...Array(forwardYears).keys()].map((n) => ({
          label: year + n,
          value: (year + n).toString(),
        }));
      }}
      order={"earliest_vintage.asc"}
      callback={(data) => {
        return filterInDataByKey(productType, data, "product_type");
      }}
      {...props}
    />
  );
};

const CoBenefitsFilter = Datum(
  ({ datum, ...props }: { datum: typeof useAPIQueryRequests }) => {
    const {
      data: { data: unSDGoals },
    } = datum[0];

    const options =
      unSDGoals?.map((item: unitedNationsSdGoalType) => {
        return {
          label: `${item.number}: ${item.name}`,
          value: item.number,
        };
      }) || [];

    return (
      <CORESelect
        testID={generateTestId("projectmarketplace", "Co-benefits-filter")}
        options={options}
        placeholder={"Select co-benefits"}
        widthSize={"lg"}
        {...props}
        {...selectProps}
      />
    );
  },
  "unitedNationsSDGoals",
  [{}],
  {
    loadingMessage: "Loading",
    loadingLayout: "horizontal",
  }
);

const StatusFilter: React.FC<FilterProps> = (props) => {
  return (
    <CORESelect
      size={"lg"}
      mode={"multiple"}
      options={[
        { label: "Published", value: "published" },
        { label: "Draft", value: "draft" },
      ]}
      placeholder={"Select status"}
      maxTagCount={"responsive"}
      {...props}
    />
  );
};

export const AccreditationFilter: React.FC<FilterProps> = (props) => {
  return (
    <AccreditationSelect
      allowClear={true}
      showSearch={true}
      optionFilterProp={"label"}
      mode={"multiple"}
      maxTagCount={"responsive"}
      placeholder={"Select accreditation"}
      transform={(data) => groupOptions(data, "name", "code", "name")}
      {...props}
    />
  );
};

const ProjectMethodFilter: React.FC<FilterProps> = (props) => {
  return (
    <MethodSelect
      params={params(props.productType as string[])}
      transform={(data) => groupOptions(data, "name", "name", "name")}
      placeholder={"Select method"}
      widthSize={"lg"}
      callback={(data) => {
        return filterInDataByKey(props.productType, data, "product_type");
      }}
      {...selectProps}
      {...props}
    />
  );
};

const ProjectCreditTypeFilter: React.FC<FilterProps> = (props) => {
  return (
    <CreditTypeSelect
      size={"lg"}
      placeholder={"Select credit type"}
      mode={"multiple"}
      {...props}
    />
  );
};

const ProjectFiltersHOC = (filterKey: string[]) => {
  const Component: React.FC<CertificateProjectsFilters> = ({
    testID,
    modalTitle,
    filterHeaderText,
    defaultFilterValues,
    filterValues,
    onChange,
    liteMode,
  }) => {
    const scopes = useScopes();
    const bezeroItem: FilterItems[] = [
      ...(scopes.includes("bezero.rating.read")
        ? [
            {
              label: `Rating`,
              key: `bezero`,
              input: (
                <BeZeroRatingSelector
                  value={filterValues.bezero}
                  size={"lg"}
                  testID={`${testID}-bezero-filter`}
                />
              ),
              widthSize: "md",
            } as FilterItems,
          ]
        : []),
    ];
    const filterItems: FilterItems[] = [
      {
        label: `Search`,
        key: `search`,
        widthSize: "lg",
        input: (
          <Search
            size={"lg"}
            widthSize={"full"}
            placeholder={"Search projects"}
            testID={`${testID}-search-form-input`}
          />
        ),
        dividerAfter: true,
      },
      {
        label: `Status`,
        key: `status`,
        widthSize: "md",
        input: (
          <StatusFilter
            testID={`${testID}-filter-by-status`}
            widthSize={"full"}
          />
        ),
      },
      {
        label: `Accreditation`,
        key: `productType`,
        widthSize: "md",
        input: (
          <AccreditationFilter
            testID={`${testID}-filter-by-accreditation`}
            widthSize={"full"}
          />
        ),
      },
      {
        label: `Vintage`,
        key: `vintage`,
        input: (
          <VintageFilter
            testID={`${testID}-vintage-filter`}
            value={filterValues.vintage}
            productType={filterValues.productType}
          />
        ),
        widthSize: "md",
      },
      {
        label: `Location`,
        key: `region`,
        widthSize: "lg",
        input: (
          <LocationSelect
            mode={"multiple"}
            size={"lg"}
            widthSize={"full"}
            testID={`${testID}-select-country-input`}
            value={filterValues.region}
            productType={(filterValues.productType ?? []) as string[]}
            transform={(data) =>
              groupOptions(data, "region_name", "region_name", "region_name")
            }
          />
        ),
      },
      {
        label: `Method`,
        key: `method`,
        widthSize: "lg",
        input: (
          <ProjectMethodFilter
            testID={`${testID}-method-filter`}
            productType={filterValues.productType}
            widthSize={"full"}
          />
        ),
      },
      {
        label: `Credit type`,
        key: `creditType`,
        widthSize: "md",
        input: (
          <ProjectCreditTypeFilter
            testID={`${testID}-credit-type-input`}
            widthSize={"full"}
          />
        ),
      },
      ...bezeroItem,
      {
        label: `Co-benefits`,
        key: `coBenefits`,
        input: <CoBenefitsFilter value={filterValues.coBenefits} />,
        widthSize: "lg",
      },
      {
        label: `Price low`,
        key: `minPrice`,
        input: (
          <COREInputNumber
            testID={`${testID}-price-from-input-number`}
            placeholder={"$ Low"}
            step={"0.10"}
            precision={2}
            min={0}
            value={filterValues.minPrice}
            size={"lg"}
          />
        ),
        widthSize: "sm",
      },
      {
        label: `Price High`,
        key: `maxPrice`,
        input: (
          <COREInputNumber
            testID={`${testID}-price-to-input-number`}
            placeholder={"$ High"}
            step={"0.10"}
            precision={2}
            min={0}
            value={filterValues.maxPrice}
            size={"lg"}
          />
        ),
        widthSize: "sm",
      },
    ];
    const matchedFilterItems: FilterItems[] = [];
    filterKey.forEach((key) => {
      const matchingFilterItem = filterItems.find(
        (filterItem) => filterItem.key === key
      );
      if (matchingFilterItem) {
        matchedFilterItems.push(matchingFilterItem);
      }
    });
    return (
      <COREFilters
        testID={testID}
        items={matchedFilterItems}
        defaultValue={defaultFilterValues}
        value={filterValues}
        modalTitle={modalTitle}
        filterHeader={filterHeaderText}
        liteMode={liteMode}
        onChange={(v) => {
          onChange(v);
        }}
      />
    );
  };
  return Component;
};

export const CertificateProjectFilters = ProjectFiltersHOC([
  "search",
  "status",
  "productType",
  "region",
  "method",
  "creditType",
]);

export const ProjectMarketplaceFilters = ProjectFiltersHOC([
  "search",
  "productType",
  "vintage",
  "region",
  "method",
  "creditType",
  "bezero",
  "coBenefits",
  "minPrice",
  "maxPrice",
]);
