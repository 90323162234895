import React, { useState } from "react";
import { generateTestId, TestID } from "../../../shared/testids/testids";
import {
  ArticlesAndUpdatesFilters,
  ArticlesAndUpdatesFilterValue,
} from "../../dashboards/widgets/insights/ArticlesAndUpdatesFilters";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { COREEmpty } from "../../../COREDesignSystem/Content/COREEmpty";
import { useArticlesAndUpdates } from "../../../shared/customHoooks/useArticlesAndUpdates";
import "../../dashboards/widgets/insights/ArticlesAndUpdates.less";
import { SortButton } from "../../../shared/buttons/SortButton";
import { Order } from "../../../shared/customHoooks/useUpdatable";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { COREInfiniteScroll } from "../../../COREDesignSystem/Content/COREInfiniteScroll";
import { ArticlesAndUpdatesList } from "../../dashboards/widgets/insights/ArticlesAndUpdatesList";
import { COREError } from "../../../COREDesignSystem/Content/COREError";
import { useHasArticlesAndUpdatesPermission } from "../../../shared/customHoooks/useHasArticlesAndUpdatesPermission";

type ArticlesAndUpdatesCardProps = {
  testID: TestID;
  filterValues?: ArticlesAndUpdatesFilterValue;
  setFilterValues: (values: ArticlesAndUpdatesFilterValue) => void;
};

export const ArticlesAndUpdatesCard: React.FC<ArticlesAndUpdatesCardProps> = ({
  testID,
  filterValues,
  setFilterValues,
}) => {
  const defaultLimit: number = 100;
  const [pagination, setPagination] = useState([
    { limit: defaultLimit, offset: 0 },
  ]);

  const { hasPermission, isLoading, error } =
    useHasArticlesAndUpdatesPermission();
  const {
    loading,
    sync,
    hasMore,
    data: articlesAndUpdatesData,
    error: articlesError,
  } = useArticlesAndUpdates(pagination, {
    ...filterValues,
  });

  if (isLoading) return <CORELoading delay={0} />;
  if (articlesError || error) return <COREError />;

  const onFilterChange = (value: ArticlesAndUpdatesFilterValue) => {
    setFilterValues && setFilterValues(value);
  };

  if (!hasPermission)
    return (
      <COREEmpty
        testID={`${testID}-empty-content`}
        description="Missing permissions"
        hint="You don’t have access to this content."
      />
    );

  return (
    <div className="articles-and-updates-container">
      <ArticlesAndUpdatesFilters
        testID={testID}
        value={filterValues as Partial<ArticlesAndUpdatesFilterValue>}
        onChange={onFilterChange}
      />
      <SortButton
        type={filterValues?.sortOrder}
        onClick={(value: Order) => {
          onFilterChange({ sortOrder: value });
        }}
      />
      <COREDivider space="sm" />
      <div className={"articles-and-updates-content"}>
        <COREInfiniteScroll
          loadMore={() => {
            setPagination((prev) => [
              ...prev,
              {
                limit: defaultLimit,
                offset: prev[prev.length - 1].offset + defaultLimit,
              },
            ]);
          }}
          loading={loading}
          sync={sync}
          hasMore={hasMore}
          contentRange={articlesAndUpdatesData.length}
          emptyRender={
            <COREEmpty
              testID={generateTestId(
                "dashboard",
                "no-articles-and-updates-found"
              )}
              description="No content found"
              hint="there are no matches based on your filter selections."
            />
          }
          useWindow={false}
          loader={<CORELoading delay={0} />}
        >
          <ArticlesAndUpdatesList
            testID={testID}
            articlesAndUpdates={articlesAndUpdatesData}
          />
        </COREInfiniteScroll>
      </div>
    </div>
  );
};
