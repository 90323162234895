import React, { useState } from "react";
import { CMSContentFilters, CMSContentFilterValue } from "./CMSContentFilters";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { COREInfiniteScroll } from "../../../COREDesignSystem/Content/COREInfiniteScroll";
import { COREEmpty } from "../../../COREDesignSystem/Content/COREEmpty";
import { TestID } from "../../../shared/testids/testids";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import "./CMSContentContainer.less";
import {
  useCMSContents,
  UseCMSContentsProps,
} from "../../../shared/customHoooks/useCMSContents";
import { CMSContent } from "../../../openapi-typescript/common/cms_content";
import { SortButton } from "../../../shared/buttons/SortButton";
import { CMSContentCard } from "./CMSContentCard";
import { hasEmptyValues } from "../../../shared/global";

type CMSContentContainerPorps = {
  onFilterChange: (newValue: CMSContentFilterValue) => void;
  filterValues: CMSContentFilterValue;
  onSortChange: (newValue: CMSContentFilterValue["sortOrder"]) => void;
  defaultLimit: number;
  testID: TestID;
  isWidget: boolean;
  page: UseCMSContentsProps["page"];
};

export const CMSContentContainer: React.FC<CMSContentContainerPorps> = ({
  onFilterChange,
  filterValues,
  onSortChange,
  defaultLimit,
  testID,
  isWidget,
  page,
}) => {
  const [paginations, setPaginations] = useState({
    limit: defaultLimit,
    offset: 0,
  });

  const { loading, sync, hasMore, updatable } = useCMSContents({
    ...paginations,
    ...filterValues,
    page,
  });
  const emptyWithFilter = !hasEmptyValues<CMSContentFilterValue>(filterValues, [
    "sortOrder",
  ]);

  const cmsContents = updatable
    ?.map((u) => u?.data)
    .filter(Boolean) as unknown as CMSContent[];

  return (
    <div className="cms-content-container">
      <CMSContentFilters
        testID={testID}
        onChange={onFilterChange}
        value={filterValues}
      />
      <SortButton type={filterValues.sortOrder} onClick={onSortChange} />
      <COREDivider space="sm" />
      <div className="cms-content">
        <COREInfiniteScroll
          loadMore={() => {
            setPaginations((prev) => ({
              limit: defaultLimit,
              offset: prev.offset + defaultLimit,
            }));
          }}
          loading={loading}
          sync={sync}
          hasMore={hasMore}
          contentRange={cmsContents.length}
          emptyRender={
            <COREEmpty
              testID={`${testID}-empty`}
              {...(emptyWithFilter
                ? {
                    description: "No content found",
                    hint: "There are no matches based on your filter selections.",
                  }
                : {
                    description: "No content available",
                  })}
            />
          }
          useWindow={!isWidget}
          loader={<CORELoading delay={0} />}
        >
          {cmsContents.map((cmsContent, index) => (
            <div key={cmsContent.id}>
              <CMSContentCard
                cmsContent={cmsContent}
                testID={testID}
                isWidget={isWidget}
              />
              {index !== cmsContents.length - 1 && (
                <COREDivider space="sm" dashed />
              )}
            </div>
          ))}
        </COREInfiniteScroll>
      </div>
    </div>
  );
};
