import React from "react";
import { daily_curves as DailyCurves } from "../../../openapi-typescript/admin";
import { BrokerChart, BrokerChartProps } from "./BrokerChart";
import { COREEmpty } from "../../../COREDesignSystem/Content/COREEmpty";
import { generateTestId, TestID } from "../../../shared/testids/testids";
import {
  CertificateCurveData,
  DailyCurvesWithSnapshot,
} from "../CarbonDailyCurvePageWrapper";
import { TabData } from "./CertificateCurve";
import { CORESelect } from "../../../COREDesignSystem/Form/CORESelect";
import moment, { Moment } from "moment";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { CORERadiobuttonGroup } from "../../../COREDesignSystem/Form/CORERadioButton";
import { NonEmptyRange } from "../../../shared/date/ranges";
import "./BrokerChartWithFilter.less";
import { ZoomLabel } from "../../tools/projectMarketplace/PriceHistoryChart";
import { ContentWithChartProps } from "../../dashboards/WidgetPanel";
import {
  COREFilters,
  FilterItems,
} from "../../../COREDesignSystem/Form/COREFilters";
import { durationRange } from "../../../shared/date/DateTime";
import { CurveDatePicker } from "../../../shared/date/CurveDatePicker";
import { momentFormat } from "../../../shared/date/DateFormatContext";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";

export type BrokerChartWithFilterProps = {
  testID: TestID;
  productType: CertificateCurveData["productType"];
  productTypeName: CertificateCurveData["productTypeName"];
  dailyCurves: DailyCurvesWithSnapshot[];
  curveDate: moment.Moment;
  localStorageKey: string | undefined;
  publishedDateArray?: (string | undefined)[];
  filterValue?: BrokerChartFilter;
  onFilterChange?: (filter: BrokerChartFilter) => void;
  isChartLoading?: boolean;
} & Pick<BrokerChartProps, "isWidget"> &
  Partial<ContentWithChartProps>;

type BrokerChartContentProps = {
  list: { label: string; value: string }[];
};

export type BrokerChartFilter = {
  curveDate?: string | null;
  method?: string;
  zoomLabel?: ZoomLabel;
};

const serverDateFormat = momentFormat.server.format;

export const EmptyContentBlock: React.FC<
  Pick<BrokerChartWithFilterProps, "productType">
> = ({ productType }) => {
  return (
    <COREEmpty
      description="No curve data available"
      hint="Try reloading the page. If the problem persists, please contact CORE Markets support."
      testID={generateTestId("carbondailycurve", `${productType}-no-data`)}
    />
  );
};

const BrokerChartFilterContainer: React.FC<
  BrokerChartContentProps &
    Pick<
      BrokerChartWithFilterProps,
      | "testID"
      | "isWidget"
      | "filterValue"
      | "onFilterChange"
      | "publishedDateArray"
    >
> = ({
  testID,
  list,
  filterValue,
  isWidget,
  onFilterChange,
  publishedDateArray,
}) => {
  const items: FilterItems[] = [];
  if (isWidget) {
    items.push({
      label: "Curve date",
      key: "curveDate",
      widthSize: "lg",
      dividerAfter: true,
      input: (
        <CurveDatePicker
          publishedDateArray={publishedDateArray}
          testID={testID}
        />
      ),
    });
  }

  items.push(
    {
      label: "Method",
      key: "method",
      widthSize: "lg",
      input: (
        <CORESelect
          testID={`${testID}-type-select`}
          size="lg"
          placeholder="select method type"
          options={list}
        />
      ),
    },
    {
      label: "Zoom",
      key: "zoomLabel",
      widthSize: "radioLg",
      input: (
        <CORERadiobuttonGroup
          testID={`${testID}-radio-select`}
          optionType="button"
          size="large"
          buttonStyle="solid"
          radioBtnValues={Object.keys(durationRange).map((key) => ({
            name: key,
            value: key,
            testID: `${testID}-radio-select-${key}`,
          }))}
        />
      ),
    }
  );

  return (
    <COREFilters
      liteMode={true}
      allowClear={false}
      testID={testID}
      items={items}
      onChange={(v: Record<string, unknown>) => {
        if (!isWidget) {
          onFilterChange && onFilterChange(v);
        } else {
          const dateSelected = moment(v.curveDate as Moment).isValid()
            ? moment(v.curveDate as Moment).format(serverDateFormat)
            : moment().format(serverDateFormat);

          onFilterChange &&
            onFilterChange({
              ...v,
              curveDate: dateSelected,
            });
        }
      }}
      value={filterValue ?? {}}
      modalTitle="Price history filters"
      filterHeader="Price history filters"
      debounceTime={0}
    />
  );
};

export const BrokerChartWithFilter: React.FC<BrokerChartWithFilterProps> = ({
  testID,
  productType,
  productTypeName,
  dailyCurves,
  curveDate,
  isWidget,
  setOnDownloadPNG,
  setOnDownloadCSV,
  setOnResetChart,
  setProgressPercent,
  localStorageKey,
  publishedDateArray,
  filterValue,
  onFilterChange,
  isChartLoading,
}) => {
  const tabData: TabData[] = dailyCurves.reduce(
    (prev: TabData[], cur: DailyCurves) => {
      const premiumCurves = cur.certificateProductPremiumCurves
        ? cur.certificateProductPremiumCurves
        : [];
      // add Generic
      if (cur.hasBaseCurve) {
        const existTabData = prev.find((p) => p.name === "Generic");
        if (existTabData === undefined) {
          prev.push({
            name: "Generic",
            tradableProduct: [
              {
                id: cur.tradableProductId,
                shortName: cur.shortName,
              },
            ],
          });
        } else {
          prev
            .find((p) => p.name === "Generic")
            ?.tradableProduct.push({
              id: cur.tradableProductId,
              shortName: cur.shortName,
            });
        }
      }
      // add method curves
      if (premiumCurves.length > 0) {
        premiumCurves.forEach((c) => {
          const existTabData = prev.find(
            (p) => p.name === c.certificate_project_type_name
          );
          if (existTabData === undefined) {
            prev.push({
              name: c.certificate_project_type_name,
              tradableProduct: [
                {
                  id: c.tradable_product.id,
                  shortName: c.tradable_product.short_name,
                },
              ],
            });
          } else {
            existTabData.tradableProduct.push({
              id: c.tradable_product.id,
              shortName: c.tradable_product.short_name,
            });
          }
        });
      }
      return prev;
    },
    []
  );

  const curveDateStr: string = moment(curveDate).format();
  const list = tabData.map((data) => ({
    label: data.name,
    value: data.name,
  }));
  const normalizeFilterValue: BrokerChartFilter = {
    ...filterValue,
    zoomLabel: filterValue?.zoomLabel ?? "H",
    method: filterValue?.method ?? list?.[0]?.value,
    curveDate: filterValue?.curveDate ?? curveDateStr,
  };
  const setDurationTime =
    durationRange[
      normalizeFilterValue.zoomLabel ? normalizeFilterValue.zoomLabel : "H"
    ];
  const dateRange: NonEmptyRange = {
    from: moment(curveDateStr).subtract(setDurationTime),
    to: moment(curveDateStr),
    bounds: "[)",
  };

  const chartData =
    tabData.find((data) => data.name === normalizeFilterValue?.method) ||
    tabData[0] ||
    {};

  return (
    <div className={"broker-chart-widgets"}>
      <BrokerChartFilterContainer
        testID={`${testID}-${productType}-filter`}
        key={localStorageKey}
        list={list}
        filterValue={normalizeFilterValue}
        isWidget={isWidget}
        onFilterChange={onFilterChange}
        publishedDateArray={publishedDateArray}
      />
      <COREDivider dashed space="md" />
      <CORELoading loading={isChartLoading}>
        {tabData.length === 0 ? (
          <EmptyContentBlock productType={productType} />
        ) : (
          <BrokerChart
            key={localStorageKey}
            date={dateRange}
            data={chartData}
            productType={productType}
            productTypeName={productTypeName}
            displayByPanel={true}
            empty={<EmptyContentBlock productType={productType} />}
            setOnDownloadPNG={setOnDownloadPNG}
            setOnDownloadCSV={setOnDownloadCSV}
            setOnResetChart={setOnResetChart}
            setProgressPercent={setProgressPercent}
            isWidget={isWidget}
          />
        )}
      </CORELoading>
    </div>
  );
};
