import { ReleaseNote } from "./shared/customHoooks/useUserReleaseNote";

export const releaseNoteData: ReleaseNote[] = [
  // // Example data
  // {
  //   version: "1.2.3",
  //   date: "11-Sep-2024",
  //   changes: [
  //     "Added feature A",
  //     "Fixed bug in feature B",
  //     "Improved performance for feature C",
  //   ],
  // },
  // {
  //   version: "1.2.2",
  //   date: "10-Sep-2024",
  //   changes: ["Fixed minor UI issue", "Updated documentation"],
  // },
];
