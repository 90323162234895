import { Space } from "antd";
import React, { Dispatch, SetStateAction, useState } from "react";
import { keysToSnake } from "../../../shared/global";
import { rehub_trade_logs as RehubTradeLogs } from "../../../openapi-typescript/admin";
import { capitaliseEachWord } from "../../../shared/stringUtil";
import { TestID } from "../../../shared/testids/testids";
import { CORECheckbox } from "../../../COREDesignSystem/Form/CORECheckbox";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { CORETag } from "../../../COREDesignSystem/Content/CORETag";
import { CORETagList } from "../../../COREDesignSystem/Content/CORETagList";
import {
  generateTree,
  useCORETree,
  UseCORETreeProps,
} from "../../../COREDesignSystem/Form/CORETree";
import { CORETreeSelectV2 } from "../../../COREDesignSystem/Form/CORETreeSelectV2";
import { CORETreeV2 } from "../../../COREDesignSystem/Form/CORETreeV2";
import { TradableProductTooltip } from "../../../shared/tooltips/TradableProductTooltip";
import {
  useAPIQuery,
  useAPIQueryPagination,
} from "../../../shared/customHoooks/useAPI";
import "./TradeTracker.less";
import { CORETypographyInput } from "../../../COREDesignSystem/Typography/CORETypographyInput";
import { COREError } from "../../../COREDesignSystem/Content/COREError";
import { CORESelectAPI } from "../../../COREDesignSystem/Form/CORESelectAPI";
import { CORESelectProps } from "../../../COREDesignSystem/Form/CORESelect";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { CORESwitch } from "../../../COREDesignSystem/Form/CORESwitch";
import { COREBody } from "../../../COREDesignSystem/Typography/COREBody";

export type ProductTreeSelectProps = {
  values: number[];
  type: "project" | "generic";
  testID: TestID;
  isStrip: boolean;
  tradeType: "firm" | "option";
  isASXTraded: boolean;
  multiple: boolean;
  search?: boolean;
  onChange?: UseCORETreeProps<
    string | number | number[] | undefined
  >["onChange"];
  isEdit?: boolean;
  tabIndex?: number;
  mode?: "popOver" | "input";
  placeholder?: string;
  isShowAllSwitch?: boolean;
};

type TradableProductType = {
  id: number;
  name: string;
  productType: string;
  rehubCode: string;
  shortName: string;
};

type ProductTagProps = Pick<ProductTreeSelectProps, "values">;

const ProductTag: React.FC<ProductTagProps> = ({ values }) => (
  <>
    {values && values.length !== 0 && (
      <CORETagList truncateAfter={3}>
        {values.map((id) => (
          <CORETag
            label={<TradableProductTooltip key={id} id={id} />}
            type="basic"
            key={`product-tag-${id}`}
          />
        ))}
      </CORETagList>
    )}
  </>
);

type ShowAllProps = Pick<ProductTreeSelectProps, "testID"> & {
  setShowAll: Dispatch<SetStateAction<boolean>>;
  showAll: boolean;
};

const ShowAll: React.FC<ShowAllProps> = ({ testID, setShowAll, showAll }) => {
  return (
    <Space>
      <CORECheckbox
        label={
          <CORETypographyInput type={"default"}>Show all</CORETypographyInput>
        }
        testID={`${testID}-show-all`}
        checked={showAll}
        onChange={({ target: { checked } }) => setShowAll(checked)}
      />
    </Space>
  );
};

const buildParams = ({
  showAll,
  type,
  isStrip,
  isASXTraded,
  tradeType,
}: { showAll: boolean } & Pick<
  ProductTreeSelectProps,
  "type" | "isStrip" | "isASXTraded" | "tradeType"
>) => ({
  order: "product_type,vintage.nullsfirst,tenor_type,tenor_dates",
  select:
    "id,product_type,isCap:is_cap,cfd_product:cfd_products!cfd_product(region_short_name,shapeType:shape_type,tenorName:tenor_name),name_parts,certificateProject:certificate_projects!certificate_project(id,projectIdentifier:project_identifier,name),rehubCode:rehub_code,name,shortName:short_name,period,deliveryYear:delivery_year,vintage,isStrip:is_strip,environmentalProduct:environmental_products!environmental_product(id,name,tenor:tenors(id,name)),projectMethodType:project_method_types(id,code,name)&order=vintage.nullsfirst,tenor_type,tenor_dates",
  ...keysToSnake({
    ...(!showAll && {
      isActive: "eq.true",
    }),
    certificateProject: type === "project" ? "not.is.null" : "is.null",
    ...(isStrip !== undefined && { isStrip: `is.${isStrip}` }),
    ...(isASXTraded !== undefined && { isAsxTraded: `eq.${isASXTraded}` }),
    ...(tradeType !== undefined && { tradeType: `eq.${tradeType}` }),
  }),
});

const Tree: React.FC<
  Pick<
    ProductTreeSelectProps,
    "values" | "multiple" | "search" | "type" | "onChange" | "isStrip"
  > & {
    testID: TestID;
    data: RehubTradeLogs[];
    setShowAll: Dispatch<SetStateAction<boolean>>;
    showAll: boolean;
  }
> = ({
  data,
  onChange,
  values,
  multiple,
  search,
  testID,
  type,
  setShowAll,
  showAll,
}) => {
  const productName = (name?: string, isCap?: boolean) => {
    if (isCap)
      return name === "flat" ? "$300 Cap" : `${capitaliseEachWord(name)} Cap`;
    return capitaliseEachWord(name);
  };

  const generateLevelOfenviroProductsTree = () => {
    if (type === "project")
      return ["name", "methodName", "certificateProject", "vintage", "tenor"];
    return ["name", "method", "methodName", "vintage", "tenor"];
  };

  const cfdProducts = data
    .filter((item) => item.cfd_product)
    .map((cfdp) => ({
      name: productName(cfdp.cfd_product?.shapeType, cfdp.isCap),
      tenor: cfdp.cfd_product?.tenorName,
      vintage: cfdp.vintage,
      region: cfdp.cfd_product?.region_short_name,
      id: cfdp.id,
    }));

  const enviroProducts = data
    .filter((item) => item.environmentalProduct)
    .map((d) => ({
      name: d.environmentalProduct?.name,
      ...(d.projectMethodType && { method: "Method-based" }),
      methodName: d.projectMethodType?.name,
      certificateProject: `${d.certificateProject?.projectIdentifier} - ${d.certificateProject?.name}`,
      tenor: d.environmentalProduct?.tenor.name,
      vintage: d.vintage,
      id: d.id,
    }));

  const levelOfCDFProductsTree = ["name", "region", "vintage", "tenor"];
  const cfdProductstreeData = generateTree(
    cfdProducts,
    levelOfCDFProductsTree,
    "id"
  );
  const enviroProductstreeData = generateTree(
    enviroProducts,
    generateLevelOfenviroProductsTree(),
    "id"
  );
  const treeData = [...enviroProductstreeData, ...cfdProductstreeData];

  const tree = useCORETree(treeData, {
    onChange: onChange,
    value: values,
  });

  return (
    <CORETreeV2
      testID={testID}
      showLine
      selectable
      {...tree}
      multiple={multiple}
      search={search}
      ref={null}
    >
      <ShowAll testID={testID} setShowAll={setShowAll} showAll={showAll} />
    </CORETreeV2>
  );
};

const DropdownRenderShowAll: React.FC<{
  setShowAll: Dispatch<SetStateAction<boolean>>;
  showAll: boolean;
  menu: React.ReactElement;
  testID: TestID;
}> = ({ menu, setShowAll, showAll, testID }) => {
  return (
    <>
      {menu}
      <COREDivider space={"xs"} />
      <div className={"edit-link-section"}>
        <Space size={8} direction={"horizontal"}>
          <CORESwitch
            size="lg"
            testID={`${testID}-show-all-switch`}
            defaultChecked={showAll}
            onChange={(value) => {
              setShowAll(value);
            }}
          />
          <COREBody color="#646768" marginBottom={false} type="p3">
            Show all products
          </COREBody>
        </Space>
      </div>
    </>
  );
};

const ProductSelect: React.FC<
  Pick<
    ProductTreeSelectProps,
    | "multiple"
    | "search"
    | "type"
    | "onChange"
    | "isStrip"
    | "isASXTraded"
    | "tradeType"
    | "placeholder"
  > & {
    testID: TestID;
    showAll: boolean;
    dropdownRender?: CORESelectProps["dropdownRender"];
    setShowAll?: Dispatch<SetStateAction<boolean>>;
  }
> = ({
  testID,
  showAll,
  type,
  isStrip,
  isASXTraded,
  tradeType,
  multiple,
  placeholder,
  onChange,
  dropdownRender,
}) => {
  const { loading } = useAPIQuery(
    "tradableProductsTradeTacker",
    buildParams({
      showAll: true,
      type,
      isStrip,
      isASXTraded,
      tradeType,
    })
  );
  return (
    <CORESelectAPI
      testID={testID}
      endpoint={"tradableProductsTradeTacker"}
      allowClear
      dropdownRender={dropdownRender}
      loading={loading}
      params={buildParams({
        showAll,
        type,
        isStrip,
        isASXTraded,
        tradeType,
      })}
      mode={multiple ? "multiple" : "basic"}
      placeholder={placeholder}
      widthSize={multiple ? "xxl" : "lg"}
      size="lg"
      transform={(lists: TradableProductType[]) => {
        return lists
          .sort((a, b) => a.rehubCode.localeCompare(b.rehubCode))
          .map((data) => ({
            label: data.rehubCode,
            value: data.id,
            key: data.name,
          }));
      }}
      filterOption={(inputValue, option) => {
        const normalizedInput = inputValue.trim().toLowerCase();
        const label = option?.label || "";
        const value = option?.key || "";
        const normalizedLabel =
          typeof label === "string" ? label.trim().toLowerCase() : "";
        const normalizedName =
          typeof value === "string" ? value.trim().toLowerCase() : "";
        return (
          normalizedLabel.includes(normalizedInput) ||
          normalizedName.includes(normalizedInput)
        );
      }}
      onChange={onChange as CORESelectProps["onChange"]}
    />
  );
};

export const ProductTreeSelect: React.FC<ProductTreeSelectProps> = ({
  type,
  values,
  onChange: parentOnChange,
  testID,
  isStrip,
  tradeType,
  isASXTraded,
  multiple,
  search,
  isEdit = false,
  tabIndex,
  mode = "popOver",
  placeholder = "Select",
  isShowAllSwitch = false,
}) => {
  const [showAll, setShowAll] = useState(false);
  const params = buildParams({
    showAll,
    type,
    isStrip,
    isASXTraded,
    tradeType,
  });

  const { data, loading, error } = useAPIQueryPagination(
    "tradableProductsTradeTacker",
    { params },
    {
      perPage: 9000,
      shouldLoadAll: true,
    }
  );

  return (
    <>
      {mode === "popOver" ? (
        <Space align={"center"} className={"inline"}>
          <CORETreeSelectV2
            testID={`${testID}-tree-select`}
            {...(isEdit && {
              valuesRenderer: (values: number[]) => (
                <ProductTag values={values} />
              ),
            })}
            showButton={isEdit}
            tabIndex={tabIndex}
          >
            {loading ? (
              <CORELoading size={"lg"} />
            ) : error ? (
              <COREError title={"Failed to load list of products."} />
            ) : (
              <Tree
                type={type}
                testID={`${testID}-tree`}
                search={search}
                multiple={multiple}
                values={values}
                data={data}
                onChange={(rootValues, values, checkedKeys) => {
                  parentOnChange &&
                    parentOnChange(rootValues, values, checkedKeys);
                }}
                isStrip={isStrip}
                setShowAll={setShowAll}
                showAll={showAll}
              />
            )}
          </CORETreeSelectV2>
          {!isEdit && <ProductTag values={values} />}
        </Space>
      ) : (
        <ProductSelect
          testID={`${testID}-select`}
          placeholder={placeholder}
          {...(isShowAllSwitch && {
            dropdownRender: (menu) => {
              return (
                <DropdownRenderShowAll
                  testID={testID}
                  menu={menu}
                  setShowAll={setShowAll}
                  showAll={showAll}
                />
              );
            },
          })}
          showAll={showAll}
          multiple={multiple}
          type={type}
          isStrip={isStrip}
          isASXTraded={isASXTraded}
          tradeType={tradeType}
          onChange={parentOnChange}
        />
      )}
    </>
  );
};
