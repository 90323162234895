import { useFeatureFlags } from "./useFeatureFlags";

export const useHasArticlesAndUpdatesPermission = () => {
  const { loading, sync, error, features } = useFeatureFlags();

  if (loading && !sync) {
    return {
      hasPermission: false,
      isLoading: true,
      error: null,
    };
  }

  if (error) {
    return {
      hasPermission: false,
      isLoading: false,
      error: error,
    };
  }

  if (!sync) {
    return {
      hasPermission: false,
      isLoading: true,
      error: null,
    };
  }

  const hasPermission = features?.includes("articles-and-updates");

  return {
    hasPermission: !!hasPermission,
    isLoading: false,
    error: null,
  };
};
