import moment from "moment";
import { useAPIQuery } from "../../../shared/customHoooks/useAPITypes";
import {
  useSnapshots,
  SnapshotRecord,
} from "../../../shared/customHoooks/useSnapshots";
import { momentFormat } from "../../../shared/date/DateFormatContext";
import { keysToSnake } from "../../../shared/global";
import {
  CurvePublicationStatus,
  ProductCurveCommentaries,
  CertificateCurveData,
  DailyCurvesWithSnapshot,
  DailyCurve,
} from "../CarbonDailyCurvePageWrapper";

const serverDateFormat = momentFormat.server.format;
const currentYear = moment().year();

export type Status = "draft" | "published";
const checkIsPublished = (status?: Status) => status === "published";

const isActiveCarbonCurve = (dc: DailyCurve) => {
  if (["accu", "nzu"].includes(dc.productType)) {
    return dc.vintage === null || dc.shortName.includes("CY");
  }
  if (dc.vintage === null || dc.vintage === undefined) return true;
  if (dc.shortName.includes("CY") || dc.shortName.includes("–")) return false;
  return dc.vintage >= 2016 && dc.vintage <= currentYear;
};

export const useCarbonCurve = ({
  curveDate,
  productType,
  type = "carbon",
}: {
  curveDate: moment.Moment;
  productType?: DailyCurvesWithSnapshot["productType"];
  type?: "carbon" | "environmental";
}): {
  publishedDateArray: CurvePublicationStatus["date"][];
  isPublished: boolean;
  generalCommentary?: ProductCurveCommentaries | undefined;
  certificateCurves: CertificateCurveData[];
  loading: boolean;
  sync: boolean;
  curvePublishLoading: boolean;
} => {
  const snapshotAsOf = curveDate.clone().add(1, "day");

  // get commentaries with curve date
  const {
    loading: commentariesLoading,
    sync: commentariesSync,
    data: commentariesData,
  } = useAPIQuery<ProductCurveCommentaries[]>(
    "productCurveCommentaries",
    {
      date: `eq.${curveDate.format(serverDateFormat)}`,
      ...keysToSnake({ productClass: "eq.carbon" }),
    },
    !!productType
  );

  const {
    data: { data: dataSnapshot },
    loading: loadingSnapshot,
    sync: syncSnapshot,
  } = useSnapshots({
    asOf: snapshotAsOf.format(serverDateFormat),
    type: type,
    strip: false,
    genericProductsOnly: true,
    filter: keysToSnake({
      or: `(tenor_type.is.null, tenor_type.neq.monthly)`,
    }),
    ...(productType && { productType: `eq.${productType}` }),
  });

  const {
    loading: loadingDailyCurvesDate,
    sync: syncDailyCurvesDate,
    data: dataDailyCurve,
  } = useAPIQuery<DailyCurve[]>(
    "getProductDailyCurve",
    keysToSnake({
      date: curveDate.format(serverDateFormat),
      type: type,
      ...(productType && { tradableProductType: `eq.${productType}` }),
    })
  );

  const {
    loading: curvePublishLoading,
    sync: curvePublishSync,
    data: curvePublishData,
  } = useAPIQuery<CurvePublicationStatus[]>(
    "getCurvePublicationStatuses",
    keysToSnake({ productClass: "eq.carbon", status: "eq.published" })
  );

  const loading =
    loadingSnapshot || loadingDailyCurvesDate || commentariesLoading;
  const sync =
    syncSnapshot && syncDailyCurvesDate && commentariesSync && curvePublishSync;
  if (
    (productType && (!sync || dataDailyCurve === undefined)) ||
    (!productType &&
      (!sync ||
        curvePublishData === undefined ||
        dataDailyCurve === undefined ||
        commentariesData === undefined))
  ) {
    return {
      generalCommentary: undefined,
      publishedDateArray: [],
      isPublished: false,
      loading,
      sync: false,
      certificateCurves: [],
      curvePublishLoading,
    };
  }
  const curvePublicationStatus = curvePublishData?.find(
    (d: CurvePublicationStatus) => d.date === curveDate.format(serverDateFormat)
  );

  const isPublished = checkIsPublished(curvePublicationStatus?.status);
  const generalCommentary = commentariesData?.find(
    (c) => c.product_type === null
  );

  const publishedDateArray = curvePublishData
    ?.filter((d) => d.status === "published")
    .map((d) => d.date)
    .sort();

  const dataDailyCurveValidVintage =
    dataDailyCurve?.filter(isActiveCarbonCurve);
  const dailyCurves: DailyCurvesWithSnapshot[] =
    dataDailyCurveValidVintage?.map((dailyCurve: DailyCurve) => {
      const snapshot = dataSnapshot.find(
        (snapshot: SnapshotRecord) =>
          dailyCurve &&
          dailyCurve.tradableProductId === snapshot.tradableProduct.id
      );
      return {
        ...dailyCurve,
        ...(snapshot && {
          lastPrice: snapshot.lastPrice,
          lastDate: snapshot.lastDate,
          lastSize: snapshot.lastSize,
        }),
      };
    }) ?? [];

  const productTypes = dailyCurves
    .map((item: DailyCurve) => item.productType)
    .filter((value, index, self) => self.indexOf(value) === index);

  const certificateCurves: CertificateCurveData[] = productTypes.map(
    (productType) => {
      const firstDailyCurve = dailyCurves.find(
        (d: DailyCurve) => d.productType === productType
      );
      const currency: CertificateCurveData["currency"] =
        firstDailyCurve?.currency as CertificateCurveData["currency"];

      return {
        productType: productType,
        productTypeName:
          firstDailyCurve?.productTypeName as CertificateCurveData["productTypeName"],
        dailyCurves: dailyCurves.filter(
          (d: DailyCurve) => d.productType === productType
        ),
        productCommentary: commentariesData?.find(
          (d) => d.product_type === productType
        ),
        currency,
      };
    }
  );

  return {
    generalCommentary,
    publishedDateArray: publishedDateArray ?? [],
    isPublished,
    loading,
    sync: true,
    certificateCurves,
    curvePublishLoading,
  };
};
