import React, { useState } from "react";
import { ArticlesAndUpdatesFilterValue } from "./ArticlesAndUpdatesFilters";
import { currentWidgetTestID, WidgetPanel } from "../../WidgetPanel";
import "./ArticlesAndUpdates.less";
import { ArticlesAndUpdatesCard } from "../../../insights/articlesAndUpdates/ArticlesAndUpdatesCard";

type ArticlesWidgetProps = {
  props: {
    name: string;
    props: ArticlesAndUpdatesFilterValue | string;
    editMode: boolean;
    setProps: (value: unknown) => void;
  };
};

export const ArticlesAndUpdatesWidget: React.FC<ArticlesWidgetProps> = ({
  props: articlesUpdatesProps,
}) => {
  const testID = currentWidgetTestID(articlesUpdatesProps);
  const [articlesUpdatesFilter, setArticlesUpdatesFilter] =
    useState<ArticlesAndUpdatesFilterValue>(
      articlesUpdatesProps.props &&
        typeof articlesUpdatesProps.props !== "string"
        ? articlesUpdatesProps.props
        : {}
    );

  const onChangeFilter = (widget: ArticlesAndUpdatesFilterValue) => {
    setArticlesUpdatesFilter(widget);
    articlesUpdatesProps.setProps({ widget, i: articlesUpdatesProps.name });
  };

  return (
    <WidgetPanel
      testID={`${testID}-widget-panel`}
      panelTitle="Articles and updates"
    >
      <ArticlesAndUpdatesCard
        testID={`${testID}-card-widget`}
        filterValues={articlesUpdatesFilter}
        setFilterValues={onChangeFilter}
      />
    </WidgetPanel>
  );
};
