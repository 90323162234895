import React, { useState } from "react";
import {
  ContentWidgetProps,
  currentWidgetFilters,
  onSelectFilters,
} from "../../WidgetPanel";
import {
  BrokerChartWithFilterProps,
  BrokerChartFilter,
  BrokerChartWithFilter,
} from "../../../dailyCurves/carbonComponent/BrokerChartWithFilter";
import {
  CurvePublicationStatus,
  useEnsureOnDateUserCanView,
} from "../../../dailyCurves/CarbonDailyCurvePageWrapper";
import moment from "moment";
import { DailyCurveProductType } from "../../../../shared/customHoooks/useProductTypes";
import { CORELoading } from "../../../../COREDesignSystem/Feedback/CORELoading";
import { useAPIQuery } from "../../../../shared/customHoooks/useAPITypes";
import { keysToSnake } from "../../../../shared/global";
import { useUserIsAdmin } from "../../../../shared/state/user";
import { useCarbonCurve } from "../../../dailyCurves/carbonComponent/useCarbonCurve";
import { getTodayDate } from "../../../tools/chartBuilder/AggregatesChartV2";
import { COREError } from "../../../../COREDesignSystem/Content/COREError";
import "./CarbonCurveHistoryChartWidget.less";

type DailyCurveHistoryChartProps = ContentWidgetProps<
  DailyCurveProductType,
  BrokerChartFilter
> &
  Pick<
    BrokerChartWithFilterProps,
    "publishedDateArray" | "filterValue" | "onFilterChange"
  > & { dateNow: moment.Moment };

const CarbonCurveHistoryChart: React.FC<DailyCurveHistoryChartProps> = ({
  dateNow,
  widgetType,
  widgetProps,
  ...props
}) => {
  const { certificateCurves, loading, sync } = useCarbonCurve({
    curveDate: dateNow,
    productType: widgetType,
    type: "carbon",
  });
  const curvesData = certificateCurves.flatMap((v) => v.dailyCurves);

  const defaultProps: BrokerChartWithFilterProps = {
    localStorageKey: `${widgetProps.name}-${widgetType}`,
    curveDate: dateNow,
    productType: widgetType,
    productTypeName: widgetType.toUpperCase(),
    dailyCurves: curvesData,
    isWidget: true,
    ...props,
  };
  return (
    <BrokerChartWithFilter
      {...defaultProps}
      key={`${widgetProps.name}-${widgetType}`}
      isChartLoading={loading && !sync}
    />
  );
};

const DailyCurveHistoryChartForApprovedUser: React.FC<
  Omit<DailyCurveHistoryChartProps, "dateNow">
> = ({ filterValue, ...props }) => {
  const {
    loading,
    sync,
    data: curvePublishData,
  } = useAPIQuery<CurvePublicationStatus[]>(
    "getCurvePublicationStatuses",
    keysToSnake({ productClass: `eq.carbon`, status: "eq.published" })
  );

  const curvePublicationStatus = curvePublishData?.find(
    (d: CurvePublicationStatus) => d.date === filterValue?.curveDate
  );
  const isPublished = curvePublicationStatus?.status === "published";
  const publishedDateArray = curvePublishData
    ?.filter((d) => d.status === "published")
    .map((d) => d.date)
    .sort();

  const redirectToDate = useEnsureOnDateUserCanView(
    isPublished,
    sync,
    publishedDateArray ?? []
  );

  if (loading && !sync)
    return (
      <div className={"display-loading-center"}>
        <CORELoading delay={0} layout="horizontal" />
      </div>
    );

  const newCurveDate = isPublished ? filterValue?.curveDate : redirectToDate;

  return (
    <CarbonCurveHistoryChart
      publishedDateArray={publishedDateArray}
      dateNow={moment(newCurveDate)}
      filterValue={filterValue}
      {...props}
    />
  );
};

export const CarbonCurveHistoryChartWidget: React.FC<
  Omit<DailyCurveHistoryChartProps, "dateNow">
> = ({ widgetType: defaultWidgetType, widgetProps, ...props }) => {
  const defaultFilter = currentWidgetFilters(widgetProps)?.filters;
  const [widgetFilter, setWidgetFilter] = useState<{
    widgetType: DailyCurveProductType;
    filters: BrokerChartFilter | undefined;
  }>({ widgetType: defaultWidgetType, filters: defaultFilter });
  const isAdmin = useUserIsAdmin();

  const handleWidgetFilter = (newFilterValues: BrokerChartFilter) => {
    setWidgetFilter((prev) => {
      return {
        ...prev,
        filters: newFilterValues,
      };
    });
    onSelectFilters(widgetProps, newFilterValues);
  };

  if (widgetFilter?.widgetType !== defaultWidgetType) {
    setWidgetFilter({ widgetType: defaultWidgetType, filters: defaultFilter });
  }

  if (!defaultWidgetType) return <COREError />;
  const dateNow =
    moment(widgetFilter.filters?.curveDate) ?? getTodayDate("none");

  if (isAdmin) {
    return (
      <CarbonCurveHistoryChart
        widgetProps={widgetProps}
        widgetType={widgetFilter?.widgetType}
        dateNow={dateNow}
        filterValue={widgetFilter.filters}
        onFilterChange={handleWidgetFilter}
        {...props}
      />
    );
  } else {
    return (
      <DailyCurveHistoryChartForApprovedUser
        widgetProps={widgetProps}
        widgetType={widgetFilter?.widgetType}
        filterValue={widgetFilter.filters}
        onFilterChange={handleWidgetFilter}
        {...props}
      />
    );
  }
};
