import React from "react";
import {
  COREFilters,
  COREFiltersProps,
  FilterItems,
} from "../../../COREDesignSystem/Form/COREFilters";
import { generateTestId, TestID } from "../../../shared/testids/testids";
import {
  CORERangePickerV2,
  RelativeRangeValue,
} from "../../../COREDesignSystem/Form/CORERangePicker";
import moment from "moment";
import { Order } from "../../../shared/customHoooks/useUpdatable";
import { CMSCategorySelect } from "../../../shared/select/CMSCategorySelect";
import { COREInput } from "../../../COREDesignSystem/Form/COREInput";
import { CMSContentTypeSelect } from "../../../shared/select/CMSContentTypeSelect";
import { CMSCategory } from "../../../openapi-typescript/common/cms_category";
import { CMSContentType } from "../../../openapi-typescript/common/cms_content_type";

export type CMSContentFilterValue = Partial<{
  search: string;
  publishedDate?: RelativeRangeValue;
  sortOrder: Order;
  type: CMSContentType[];
  categories: CMSCategory[];
}>;

export const CMSContentFilters: React.FC<
  Pick<COREFiltersProps, "onChange"> & {
    value: CMSContentFilterValue;
    testID: TestID;
  }
> = ({ onChange, value, testID }) => {
  const { sortOrder, ...formatedFilterValues }: CMSContentFilterValue =
    value && Object.keys(value).length > 0
      ? {
          ...value,
          publishedDate: value.publishedDate
            ? ({
                ...value.publishedDate,
                from: moment(value.publishedDate.from),
                to: moment(value.publishedDate.to),
              } as RelativeRangeValue)
            : undefined,
        }
      : {};
  const filterItems: FilterItems[] = [
    {
      label: "Search",
      key: "search",
      widthSize: "lg",
      input: (
        <COREInput.Search
          size="lg"
          placeholder="Search"
          testID={generateTestId("insights", "search")}
        />
      ),
      dividerAfter: true,
    },
    {
      label: "Type",
      key: "type",
      widthSize: "lg",
      input: (
        <CMSContentTypeSelect
          size="lg"
          mode="multiple"
          placeholder="Select content type"
          testID={generateTestId("insights", "type-filter")}
        />
      ),
    },
    {
      label: "Categories",
      key: "categories",
      widthSize: "lg",
      input: (
        <CMSCategorySelect
          size="lg"
          mode="multiple"
          placeholder="Select categories"
          testID={generateTestId("insights", "categories-filter")}
          keyValue="id"
        />
      ),
    },
    {
      label: "Published date range",
      key: "publishedDate",
      widthSize: "lg",
      input: (
        <CORERangePickerV2
          allowClear
          widthSize="lg"
          size="lg"
          placeholder={["Select date from", "Select date to"]}
          testID={generateTestId("insights", "published-date-filter")}
        />
      ),
    },
  ];

  return (
    <COREFilters
      debounceTime={1000}
      liteMode
      onChange={onChange}
      testID={`${testID}-filter`}
      items={filterItems}
      value={formatedFilterValues as CMSContentFilterValue}
      modalTitle={"Filter contents"}
      filterHeader={"Filter contents"}
    />
  );
};
