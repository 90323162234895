import React from "react";
import { useCounterparties } from "../../brokerAdmin/tradeParties/counterParties/useCounterparties";
import {
  CORESelect,
  CORESelectProps,
} from "../../../COREDesignSystem/Form/CORESelect";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { COREButton } from "../../../COREDesignSystem/Action/COREButton";
import "./TradeTracker.less";

type DropdownRenderProps = {
  menu: React.ReactElement;
};
const DropdownRender: React.FC<DropdownRenderProps> = ({ menu }) => {
  return (
    <>
      {menu}
      <COREDivider space={"xs"} />
      <div className={"edit-link-section"}>
        <COREButton
          type={"link"}
          href={"/broker-admin/trade-parties/counterparties"}
          target={"_blank"}
          size={"md"}
        >
          Manage counterparties
        </COREButton>
      </div>
    </>
  );
};
export const TradePartiesSelect: React.FC<CORESelectProps> = (props) => {
  const { updatable } = useCounterparties();
  const counterParties = updatable.flatMap(({ data }) => data?.name ?? []);
  return (
    <CORESelect
      widthSize={"xl"}
      showSearch
      mode={"basic"}
      dropdownRender={(menu) => <DropdownRender menu={menu} />}
      size={"lg"}
      options={counterParties.map((counterParty) => ({
        value: counterParty,
        label: counterParty,
      }))}
      {...props}
    />
  );
};
