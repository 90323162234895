import React from "react";
import { Col, Row } from "antd";
import { COREContainer } from "../../../COREDesignSystem/Layout/COREContainer";
import { generateTestId } from "../../../shared/testids/testids";
import { EodEditor } from "./EodEditor";
import moment from "moment";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { CarbonTableWithFilter } from "./CarbonTableWithFilter";
import "./CertificateCurve.less";
import { COREEmpty } from "../../../COREDesignSystem/Content/COREEmpty";
import {
  CertificateCurveData,
  DailyCurvesWithSnapshot,
} from "../CarbonDailyCurvePageWrapper";
import { daily_curve_methods as MethodTypeAdmin } from "../../../openapi-typescript/admin";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { DailyCurveTableFilterType } from "../widget/SetupDailyCurveWidget";
import { CarbonTableProps } from "./CarbonTable";
import {
  useLocalStorageUntilLogout,
  useLocalStorageUntilLogoutPerKey,
} from "../../../shared/customHoooks/useLocalStorageUntilLogout";
import {
  BrokerChartWithFilterProps,
  BrokerChartFilter,
} from "./BrokerChartWithFilter";
import { spacing } from "../../../shared/global";
import { BrokerChartContainer } from "./BrokerChartContainer";

export type CertificateCurveProps = {
  isPublished: boolean;
  curveDate: moment.Moment;
  certificateCurve: CertificateCurveData;
  isLoading?: boolean;
};

type TradableProductData = {
  id?: number;
  shortName: string;
};

export type TabData = {
  name: string;
  tradableProduct: TradableProductData[];
};

export type MethodType = MethodTypeAdmin;

const CarbonTableWithStoreFilter: React.FC<CarbonTableProps> = ({
  productType,
  methodTypes,
  testId,
  ...props
}) => {
  const [filterValues, setFilterValues] = useLocalStorageUntilLogout<{
    [productType: string]: DailyCurveTableFilterType | null | undefined;
  } | null>({
    key: "dailycurvesCarbonTable",
    initialValue: {},
  });

  const onChange = (filter?: DailyCurveTableFilterType | null | undefined) => {
    setFilterValues((prev) => {
      return {
        ...prev,
        [`${productType}`]: filter,
      };
    });
  };

  return (
    <CarbonTableWithFilter
      productType={productType}
      methodTypes={methodTypes}
      initFilters={
        filterValues && productType ? filterValues[productType] : null
      }
      onFilterChange={onChange}
      testID={testId}
      {...props}
    />
  );
};

const CarbonChartWithStoreFilter: React.FC<
  Pick<
    BrokerChartWithFilterProps,
    "curveDate" | "productType" | "productTypeName" | "dailyCurves"
  >
> = ({ curveDate, productType, productTypeName, dailyCurves }) => {
  const [brokerFilter, setBrokerFilter] =
    useLocalStorageUntilLogoutPerKey<BrokerChartFilter>(
      "dailyHistoryChartFilter",
      {
        key: `${productType}-certificate-curve`,
        initialValue: {
          zoomLabel: "H",
        },
      }
    );

  return (
    <BrokerChartContainer
      testID={generateTestId("carbondailycurve", "broker-chart")}
      curveDate={curveDate}
      productType={productType}
      productTypeName={productTypeName}
      dailyCurves={dailyCurves}
      isWidget={false}
      localStorageKey={`${productType}-certificate-curve`}
      filterValue={brokerFilter}
      onFilterChange={setBrokerFilter}
    />
  );
};

export const CertificateCurve: React.FC<CertificateCurveProps> = ({
  curveDate,
  isPublished,
  certificateCurve: {
    currency,
    dailyCurves,
    productType,
    productTypeName,
    productCommentary,
  },
  isLoading = false,
}) => {
  const methodTypes: DailyCurvesWithSnapshot["certificateProductPremiumCurves"] =
    dailyCurves.find(
      (d) =>
        d.certificateProductPremiumCurves &&
        d.certificateProductPremiumCurves.length !== 0
    )?.certificateProductPremiumCurves;

  const hasMethods: boolean = Array.isArray(methodTypes);
  const shouldBeSingleColumn =
    methodTypes !== undefined && methodTypes?.length > 2;
  const dailyCurve = dailyCurves?.find((d) => d) || { hasBaseCurve: false };
  const hasBaseCurve: boolean = dailyCurve?.hasBaseCurve;
  return (
    <Row gutter={[spacing.sm, spacing.sm]}>
      <Col
        xxl={shouldBeSingleColumn ? 24 : 14}
        xl={shouldBeSingleColumn ? 24 : 13}
        lg={24}
        md={24}
        sm={24}
        xs={24}
      >
        <CORELoading loading={isLoading} size={"lg"}>
          <COREContainer
            testID={generateTestId("carbondailycurve", `${productType}`)}
            header={`${productTypeName} - Daily update`}
            noScroll={true}
          >
            {!hasBaseCurve && !hasMethods ? (
              <COREEmpty
                description={"No Data"}
                testID={generateTestId(
                  "carbondailycurve",
                  `${productType}-no-data`
                )}
              />
            ) : (
              <>
                <EodEditor
                  key={`carbonDailyCurve${curveDate}`}
                  certificate={productType}
                  date={curveDate}
                  commentaryData={{
                    certificate: productType,
                    commentary: productCommentary?.commentary ?? "",
                  }}
                  isPublished={isPublished}
                  testId={generateTestId(
                    "carbondailycurve",
                    `${productTypeName}-commentary-text-content`
                  )}
                />
                <COREDivider space="lg" />
                <CarbonTableWithStoreFilter
                  testId={generateTestId(
                    "carbondailycurve",
                    `${productTypeName}-carbon`
                  )}
                  isPublished={isPublished}
                  dailyCurves={dailyCurves}
                  currency={currency}
                  methodTypes={methodTypes}
                  hasMethods={hasMethods}
                  hasBaseCurve={hasBaseCurve}
                  date={curveDate}
                  productType={productType}
                />
              </>
            )}
          </COREContainer>
        </CORELoading>
      </Col>
      <Col
        xxl={shouldBeSingleColumn ? 24 : 10}
        xl={shouldBeSingleColumn ? 24 : 11}
        lg={24}
        md={24}
        sm={24}
        xs={24}
      >
        <CORELoading loading={isLoading} size={"lg"}>
          <CarbonChartWithStoreFilter
            curveDate={curveDate}
            productType={productType}
            productTypeName={productTypeName}
            dailyCurves={dailyCurves}
          />
        </CORELoading>
      </Col>
    </Row>
  );
};
