import React, { useState } from "react";
import { DailyCurveProductType } from "../../../../shared/customHoooks/useProductTypes";
import {
  ContentWidgetProps,
  currentWidgetFilters,
  onSelectFilters,
} from "../../WidgetPanel";
import { EnvironmentalForwardCurveChartProps } from "../../../dailyCurves/environComponent/EnvironmentalChart";
import { useAPIQuery } from "../../../../shared/customHoooks/useAPITypes";
import {
  CurvePublicationStatus,
  useEnsureOnDateUserCanView,
} from "../../../dailyCurves/CarbonDailyCurvePageWrapper";
import { keysToSnake } from "../../../../shared/global";
import { CORELoading } from "../../../../COREDesignSystem/Feedback/CORELoading";
import { useUserIsAdmin } from "../../../../shared/state/user";
import {
  BrokerChartFilter,
  EmptyContentBlock,
} from "../../../dailyCurves/carbonComponent/BrokerChartWithFilter";
import moment from "moment";
import { momentFormat } from "../../../../shared/date/DateFormatContext";
import {
  Certificate,
  EnvironmentalForwardCurve,
  EnvironmentalForwardCurveProps,
} from "../../../dailyCurves/environComponent/EnvironmentalDailyCurve";
import { COREError } from "../../../../COREDesignSystem/Content/COREError";
import { getTodayDate } from "../../../tools/chartBuilder/AggregatesChartV2";

type DailyCurveForwardCurveChartProps = ContentWidgetProps<
  DailyCurveProductType,
  BrokerChartFilter
>;

const serverDateFormat = momentFormat.server.format;

const EnvironmentalForwardCurveChartWidgetApprovedUser: React.FC<
  EnvironmentalForwardCurveProps
> = ({ certificate, date: curveDate, filterValue, ...props }) => {
  const {
    loading,
    sync,
    data: curvePublishData,
  } = useAPIQuery<CurvePublicationStatus[]>(
    "getCurvePublicationStatuses",
    keysToSnake({ productClass: `eq.environmental`, status: "eq.published" })
  );

  const curvePublicationStatus = curvePublishData?.find(
    (d: CurvePublicationStatus) => d.date === curveDate.format(serverDateFormat)
  );
  const isPublished = curvePublicationStatus?.status === "published";
  const publishedDateArray = curvePublishData
    ?.filter((d) => d.status === "published")
    .map((d) => d.date)
    .sort();

  const redirectToDate = useEnsureOnDateUserCanView(
    isPublished,
    sync,
    publishedDateArray ?? []
  );

  if (loading && !sync)
    return (
      <div className={"display-loading-center"}>
        <CORELoading delay={0} layout="horizontal" />
      </div>
    );

  const newCurveDate = isPublished ? curveDate : moment(redirectToDate);

  return (
    <EnvironmentalForwardCurve
      certificate={certificate as Certificate}
      date={newCurveDate}
      filterValue={{
        curveDate: isPublished
          ? curveDate.format(serverDateFormat)
          : redirectToDate,
      }}
      publishedDateArray={publishedDateArray}
      {...props}
    />
  );
};

export const EnvironmentalForwardCurveChartWidget: React.FC<
  DailyCurveForwardCurveChartProps &
    Pick<EnvironmentalForwardCurveChartProps, "certificate">
> = ({ widgetProps, widgetType: defaultWidgetType, certificate, ...props }) => {
  const defaultFilter = currentWidgetFilters(widgetProps)?.filters;
  const [widgetFilter, setWidgetFilter] = useState<{
    widgetType: DailyCurveProductType;
    filters: BrokerChartFilter | undefined;
  }>({ widgetType: defaultWidgetType, filters: defaultFilter });
  const isAdmin = useUserIsAdmin();

  const handleWidgetFilter = (newFilterValues: BrokerChartFilter) => {
    setWidgetFilter((prev) => {
      return {
        ...prev,
        filters: newFilterValues,
      };
    });
    onSelectFilters(widgetProps, newFilterValues);
  };

  if (widgetFilter?.widgetType !== defaultWidgetType) {
    setWidgetFilter({ widgetType: defaultWidgetType, filters: defaultFilter });
  }

  if (!defaultWidgetType) return <COREError />;
  const dateNow =
    moment(widgetFilter.filters?.curveDate) ?? getTodayDate("none");

  if (isAdmin) {
    return (
      <EnvironmentalForwardCurve
        certificate={certificate as Certificate}
        date={dateNow}
        isWidget
        empty={<EmptyContentBlock productType={defaultWidgetType} />}
        filterValue={widgetFilter.filters}
        onFilterChange={handleWidgetFilter}
        {...props}
      />
    );
  } else {
    return (
      <EnvironmentalForwardCurveChartWidgetApprovedUser
        certificate={certificate as Certificate}
        date={dateNow}
        isWidget
        empty={<EmptyContentBlock productType={defaultWidgetType} />}
        filterValue={widgetFilter.filters}
        onFilterChange={handleWidgetFilter}
        {...props}
      />
    );
  }
};
