import React from "react";
import { InfiniteScroll } from "../../dependencies/react-infinite-scroller";
import { CORELoading } from "../Feedback/CORELoading";
import { COREBody } from "../Typography/COREBody";
import "./COREInfiniteScroll.less";

type COREInfiniteScrollProps = {
  pageStart?: number;
  initialLoad?: boolean;
  hasMore: boolean;
  loadMore: () => void;
  useWindow?: boolean;
  loading: boolean;
  sync: boolean;
  contentRange: number;
  className?: string;
  loader?: React.ReactNode;
  noMoreRender?: React.ReactNode;
  emptyRender?: React.ReactNode;
};

const NoMoreRender = () => (
  <InfiniteScrollProgressBlock>
    <COREBody type="p3" strong={true} marginBottom={false}>
      Showing all
    </COREBody>
  </InfiniteScrollProgressBlock>
);

export const InfiniteScrollProgressBlock: React.FC = ({ children }) => {
  return (
    <div className={"core-infinite-scroll-progress-row-block"}>{children}</div>
  );
};

export const COREInfiniteScroll: React.FC<COREInfiniteScrollProps> = ({
  pageStart = 1,
  initialLoad = true,
  useWindow = false,
  loader = <CORELoading delay={0} />,
  loading,
  sync,
  contentRange,
  noMoreRender = <NoMoreRender />,
  emptyRender,
  children,
  hasMore,
  ...props
}) => {
  const isLoading = loading;
  const isEmpty = !loading && sync && contentRange === 0;
  const isNoMoreToLoad = !loading && sync && !hasMore;
  return (
    <InfiniteScroll
      pageStart={pageStart}
      initialLoad={initialLoad}
      useWindow={useWindow}
      hasMore={!loading && sync && hasMore}
      {...props}
    >
      {children}
      {isLoading && loader}
      {isEmpty && emptyRender}
      {isNoMoreToLoad && !isEmpty && noMoreRender}
    </InfiniteScroll>
  );
};
