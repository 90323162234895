import React from "react";
import "./ArticlesAndUpdates.less";
import { ArticlesCard } from "./ArticlesCard";
import { TestID } from "../../../../shared/testids/testids";
import { ArticlesAndUpdates } from "../../../../openapi-typescript/common/articlesAndUpdates";

export const ArticlesAndUpdatesList: React.FC<{
  testID: TestID;
  articlesAndUpdates?: ArticlesAndUpdates[];
}> = ({ testID, articlesAndUpdates }) => (
  <div className={"articles-and-updates-list-container"}>
    {articlesAndUpdates?.map((item) => (
      <React.Fragment key={item.id}>
        <ArticlesCard articles={item} testID={`${testID}-list`} />
      </React.Fragment>
    ))}
  </div>
);
