import React from "react";
import {
  COREFilters,
  FilterItems,
} from "../../../../COREDesignSystem/Form/COREFilters";
import { TestID } from "../../../../shared/testids/testids";
import { NewsAndAlertFilters } from "./NewsAndAlertsTableWithFilters";
import { AlertTypeSelect } from "../../../../shared/select/AlertTypeSelect";
import {
  CORERangePickerV2,
  RelativeRangeValue,
} from "../../../../COREDesignSystem/Form/CORERangePicker";
import moment from "moment";
import { CMSCategorySelect } from "../../../../shared/select/CMSCategorySelect";

export const NewsAndAlertsFilters: React.FC<{
  testID: TestID;
  filterValues?: NewsAndAlertFilters;
  setFilterValues: (value: NewsAndAlertFilters) => void;
}> = ({ testID, filterValues, setFilterValues }) => {
  const filterItems: FilterItems[] = [
    {
      label: `Alert types`,
      key: `alertTypes`,
      widthSize: "md",
      input: (
        <AlertTypeSelect
          mode="multiple"
          testID={`${testID}-alert-type-selector`}
        />
      ),
      dividerAfter: true,
    },
    {
      label: `Categories`,
      key: `categories`,
      widthSize: "xl",
      input: (
        <CMSCategorySelect
          keyValue="id"
          testID={`${testID}-category-selector`}
          placeholder="Select categories"
          size="lg"
          widthSize="xl"
          mode="multiple"
        />
      ),
    },
    {
      label: `Publish at`,
      key: `alertTimestamp`,
      input: (
        <CORERangePickerV2
          allowClear={true}
          size={"lg"}
          placeholder={["Select date from", "Select date to"]}
          testID={`${testID}-alert-timestamp-date-input`}
        />
      ),
      widthSize: "xl",
    },
  ];

  const formatedFilterValues: NewsAndAlertFilters =
    filterValues && Object.keys(filterValues).length > 0
      ? {
          ...filterValues,
          alertTimestamp: filterValues["alertTimestamp"]
            ? ({
                ...filterValues["alertTimestamp"],
                from: moment(filterValues["alertTimestamp"]?.from),
                to: moment(filterValues["alertTimestamp"]?.to),
              } as RelativeRangeValue)
            : undefined,
        }
      : {};

  return (
    <COREFilters
      testID={testID}
      items={filterItems}
      value={formatedFilterValues ?? {}}
      modalTitle={"News and alerts filters"}
      filterHeader={"News and alerts filters"}
      liteMode={true}
      onChange={(v) => {
        setFilterValues(v);
      }}
      allowClear={true}
    />
  );
};
