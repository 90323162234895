import React from "react";
import { COREContainer } from "../../../COREDesignSystem/Layout/COREContainer";
import { generateTestId } from "../../../shared/testids/testids";
import {
  BrokerChartWithFilter,
  BrokerChartWithFilterProps,
} from "./BrokerChartWithFilter";

export const BrokerChartContainer: React.FC<BrokerChartWithFilterProps> = ({
  productType,
  productTypeName,
  ...props
}) => {
  return (
    <COREContainer
      testID={generateTestId("carbondailycurve", `${productType}-end-of-day`)}
      header={`${productTypeName} - End of day`}
      noScroll={true}
    >
      <BrokerChartWithFilter
        productType={productType}
        productTypeName={productTypeName}
        {...props}
      />
    </COREContainer>
  );
};
