import React, { ReactElement, useEffect, useState } from "react";
import { Badge, Input, Popover, Tag } from "antd";
import { COREButton } from "../Action/COREButton";
import "./CORETreeSelectV2.less";
import { FilterOutlined } from "@ant-design/icons";
import { TestID } from "../../shared/testids/testids";

const defaultValuesRenderer = (values): Function =>
  values.map((value, i) => (
    <Tag key={`tag:${value}`}>
      <span>{value}</span>
    </Tag>
  ));

export const CORETreeSelectV2 = ({
  children: ChildTree,
  onChange: parentOnChange,
  valuesRenderer = defaultValuesRenderer,
  forceVisiblePopOver = false,
  testID,
  showButton = true,
  tabIndex,
}: {
  children: JSX.Element;
  onChange?: Function;
  valuesRenderer?: Function;
  forceVisiblePopOver?: boolean;
  testID: TestID;
  showButton?: boolean;
  tabIndex?: number;
}) => {
  const {
    values,
    initialValue,
  }: {
    values?: number[];
    initialValue?: number[];
  } = ChildTree.props;

  const [rootValues, setRootValues] = useState<number[] | undefined>(
    ChildTree.props.initialValue
  );

  useEffect(() => {
    setRootValues(values ?? initialValue);
  }, [values, initialValue]);

  const onChange = (...args) => {
    const rootValues: number[] = args[0];
    setRootValues(rootValues);
    if (parentOnChange && parentOnChange instanceof Function) {
      parentOnChange(...args);
    }
  };

  const Tree: JSX.Element = React.cloneElement(
    ChildTree as React.ReactElement<ReactElement>,
    {
      onChange: onChange,
      ...ChildTree.props,
    }
  );

  const contentTitle = <div className={"custom-pop-over-title"}>{Tree}</div>;

  const contentFooter = (
    <div className={"custom-pop-over-footer"}>
      <div className={"cancel-btn"}>
        <COREButton size={"sm"} type={"default"}>
          CANCEL
        </COREButton>
      </div>
      <div className={"ok-btn"}>
        <COREButton size={"sm"} type={"primary"}>
          OK
        </COREButton>
      </div>
    </div>
  );

  const forceVisiblePopOverConfig: { visible?: boolean } = forceVisiblePopOver
    ? { visible: true }
    : {};

  return (
    <Popover
      placement={"bottomLeft"}
      title={contentTitle}
      content={contentFooter}
      overlayClassName={"custom-pop-over"}
      data-testid={testID}
      {...forceVisiblePopOverConfig}
    >
      <Input.Group className="core-tree-input-group">
        {rootValues &&
        showButton &&
        rootValues.length !== 0 &&
        !ChildTree.props.filterMode ? (
          <div>{valuesRenderer(rootValues)}</div>
        ) : ChildTree.props.filterMode ? (
          <Badge
            count={rootValues && rootValues.length ? rootValues.length : 0}
          >
            <COREButton type={"primary"}>
              <FilterOutlined />
            </COREButton>
          </Badge>
        ) : (
          <COREButton type={"primary"} tabIndex={tabIndex}>
            {ChildTree.props.placeholder || "Select"}
          </COREButton>
        )}
      </Input.Group>
    </Popover>
  );
};
