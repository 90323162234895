import React from "react";
import moment, { Duration } from "moment/moment";
import { ProductType } from "../ProjectMarketplacePageWrapper";
import { certificate_projects as certificateProjectsAdmin } from "../../../openapi-typescript/admin";
import { certificate_projects as certificateProjectsApproved } from "../../../openapi-typescript/approved";
import { useAPIQuery } from "../../../shared/customHoooks/useAPI";
import { keysToSnake, spacing } from "../../../shared/global";
import { useLocalStorageUntilLogoutPerKey } from "../../../shared/customHoooks/useLocalStorageUntilLogout";
import {
  PriceHistoryChartPanel,
  PriceHistoryProps,
} from "./PriceHistoryChartPanel";
import { Col, Row } from "antd";
import { PriceHistoryChartFilter } from "./PriceHistoryChartFilter";
import { MethodType } from "../../dailyCurves/carbonComponent/CertificateCurve";
import { ProductSelectedType } from "./MarketSnapshot";
import { ContentWithChartProps } from "../../dashboards/WidgetPanel";
import { COREFiltersProps } from "../../../COREDesignSystem/Form/COREFilters";

type productId = number;

export type Product = {
  shortName: string;
  nameParts: string[];
  id: productId;
};

export type ZoomLevel = Duration;
export type ZoomLabel = string;

export const zoomButtons: Record<ZoomLabel, ZoomLevel> = {
  M: moment.duration(1, "months"),
  Q: moment.duration(3, "months"),
  H: moment.duration(6, "months"),
  "1Y": moment.duration(1, "years"),
  "3Y": moment.duration(3, "years"),
} as const;

export const isCandleStickChart = (type: ProductType): boolean =>
  type === "accu" || type === "lgc";

export type SnapshotType = Exclude<
  certificateProjectsAdmin | certificateProjectsApproved,
  undefined
>;

export const useTradableProductsForType = (
  type: ProductType,
  projectMethodType?: ProductSelectedType["projectMethodType"]
): {
  tradableProducts: Product[];
  loading: boolean;
  error: boolean;
  sync: boolean;
} => {
  let projectMethodTypeParams = {};
  if (projectMethodType) {
    projectMethodTypeParams = keysToSnake({
      tenorType: `is.null`,
      projectMethodType: `eq.${projectMethodType}`,
    });
  } else {
    projectMethodTypeParams = keysToSnake({
      projectMethodType: `is.null`,
    });
  }
  const {
    sync,
    loading,
    error,
    data: { data: tradableProductsForProductType },
  } = useAPIQuery(
    "tradable_products",
    keysToSnake({
      productType: `eq.${type}`,
      inGreenMarketplace: `eq.true`,
      isActive: `eq.true`,
      isStrip: `eq.false`,
      certificateProject: `is.null`,
      tradeType: `eq.firm`,
      order: `tenor_type.nullsfirst,delivery_year.nullsfirst,vintage.nullsfirst`,
      or: `(tenor_type.is.null,tenor_type.eq.yearly)`,
      ...projectMethodTypeParams,
    })
  );
  return {
    sync: sync as boolean,
    loading: loading as boolean,
    error,
    tradableProducts: tradableProductsForProductType,
  };
};

export const getLabelFromProduct = (tp: Product): string => tp.shortName;
const defaultZoom: ZoomLabel = "1Y";

export type PriceHistoryChartFilterValues = Pick<
  PriceHistoryProps,
  "productType"
> & { zoomLevel: ZoomLabel } & Partial<{
    method: MethodType["certificate_project_type_name"];
    vintages?: number | number[];
  }>;

export const PriceHistoryChart: React.FC<
  {
    empty: React.ReactElement;
    isWidget: boolean;
    localStorageKey?: string;
    productType?: PriceHistoryProps["productType"];
    widgetFilters?: PriceHistoryChartFilterValues;
    onWidgetFilter?: COREFiltersProps["onChange"];
  } & Partial<ContentWithChartProps>
> = ({
  localStorageKey,
  empty,
  productType = "accu",
  isWidget,
  setOnResetChart,
  setOnDownloadPNG,
  setProgressPercent,
  widgetFilters,
  onWidgetFilter,
}) => {
  const initialValue = {
    productType: productType,
    zoomLevel: defaultZoom,
    method: "generic",
  };

  const [localStorageChart, setLocalStorageChart] =
    useLocalStorageUntilLogoutPerKey<PriceHistoryChartFilterValues>(
      "greenMarketSnapshotChart",
      {
        key: localStorageKey ?? "greenMarketSnapshotChart",
        initialValue,
      }
    );

  const isCandleStick = isCandleStickChart(localStorageChart.productType);

  const { tradableProducts } = useTradableProductsForType(
    widgetFilters?.productType ?? localStorageChart.productType,
    widgetFilters && widgetFilters.method !== "generic"
      ? widgetFilters?.method
      : localStorageChart.method !== "generic"
      ? localStorageChart.method
      : null
  );

  const defaultVintages =
    widgetFilters?.vintages ||
    localStorageChart.vintages ||
    (isCandleStick
      ? tradableProducts?.[0]?.id
      : tradableProducts?.map((p) => p.id));

  let products: Product[] = [];

  if (tradableProducts?.length) {
    const filterProducts = (
      vintages: PriceHistoryChartFilterValues["vintages"]
    ) =>
      tradableProducts.filter((p) =>
        Array.isArray(vintages) ? vintages.includes(p.id) : p.id === vintages
      );

    products = localStorageChart.vintages
      ? filterProducts(localStorageChart.vintages)
      : filterProducts(defaultVintages);
  }

  return (
    <Row gutter={[spacing.lg, spacing.lg]}>
      <Col span={24}>
        <PriceHistoryChartFilter
          key={localStorageKey}
          isWidget={isWidget}
          value={{
            ...(widgetFilters ? widgetFilters : localStorageChart),
            vintages: defaultVintages,
          }}
          setValue={setLocalStorageChart}
          onWidgetFilter={onWidgetFilter}
        />
      </Col>
      <Col span={24}>
        <PriceHistoryChartPanel
          key={localStorageKey}
          isWidget={isWidget}
          productType={localStorageChart.productType}
          zoomLevel={
            zoomButtons[widgetFilters?.zoomLevel ?? localStorageChart.zoomLevel]
          }
          products={products}
          empty={empty}
          setOnDownloadPNG={setOnDownloadPNG}
          setOnResetChart={setOnResetChart}
          setProgressPercent={setProgressPercent}
        />
      </Col>
    </Row>
  );
};
