import React, { useState } from "react";
import { generateTestId } from "../../../../shared/testids/testids";
import { WidgetPanel } from "../../WidgetPanel";
import { CMSContentContainer } from "../../../insights/cmsContents/CMSContentContainer";
import { CMSContentFilterValue } from "../../../insights/cmsContents/CMSContentFilters";

type ResearchAndAnalysisWidgetProps = {
  props: {
    name: string;
    props: { filters: CMSContentFilterValue };
    editMode: boolean;
    setProps: (value: {
      widget: { filters: CMSContentFilterValue };
      i: string;
    }) => void;
  };
};

export const ResearchAndAnalysisWidget: React.FC<
  ResearchAndAnalysisWidgetProps
> = ({
  props: {
    name,
    props: { filters },
    setProps,
  },
}) => {
  const defaultFilters = filters ?? { sortOrder: "desc" };
  const [widgetFilter, setWidgetFilter] =
    useState<CMSContentFilterValue>(defaultFilters);
  const onFilters = (values: CMSContentFilterValue) => {
    setProps({
      widget: {
        filters: values,
      },
      i: name,
    });
  };

  return (
    <WidgetPanel
      testID={generateTestId(
        "dashboard",
        "research-and-analysis-widget-widget"
      )}
      panelTitle="Research and analysis"
    >
      <CMSContentContainer
        page={"dashboard"}
        isWidget
        testID={generateTestId("dashboard", "research-and-analysis-widget")}
        defaultLimit={3}
        onFilterChange={(values) => {
          const filterValues = { sortOrder: widgetFilter.sortOrder, ...values };
          setWidgetFilter(filterValues);
          onFilters(filterValues);
        }}
        filterValues={widgetFilter}
        onSortChange={(sortOrder) => {
          const filterValues = { ...widgetFilter, sortOrder };
          setWidgetFilter(filterValues);
          onFilters(filterValues);
        }}
      />
    </WidgetPanel>
  );
};
