import { Col, Row, Space } from "antd";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { COREContainer } from "../../../COREDesignSystem/Layout/COREContainer";
import { useAPIQueryWithPlaceHolder } from "../../../shared/customHoooks/useAPI";
import { ProjectMarketplaceFilter } from "./ProjectMarketplaceFilter";
import { ViewProjectsAsCards } from "./ViewProjectsAsCards";
import { ViewProjectsAsMap } from "./ViewProjectsAsMap";
import { ViewProjectsAsList } from "./ViewProjectsAsList";
import { green_offer_details as greenOfferDetailsAdminType } from "../../../openapi-typescript/admin";
import { green_offer_details as greenOfferDetailsApprovedType } from "../../../openapi-typescript/approved";
import { CORETypographyInput } from "../../../COREDesignSystem/Typography/CORETypographyInput";
import {
  keysToSnake,
  spacing,
  uniqueByKey,
  uniqueByMultipleKeys,
} from "../../../shared/global";
import React, { Dispatch, SetStateAction, useState } from "react";
import { COREMenuItem } from "../../../COREDesignSystem/Navigation/COREMenuItem";
import { generateTestId } from "../../../shared/testids/testids";
import { CORESwitch } from "../../../COREDesignSystem/Form/CORESwitch";
import { COREBody } from "../../../COREDesignSystem/Typography/COREBody";
import { COREError } from "../../../COREDesignSystem/Content/COREError";
import {
  useLocalStorageUntilLogout,
  useLocalStorageUntilLogoutPerKey,
} from "../../../shared/customHoooks/useLocalStorageUntilLogout";
import { useScopes } from "../../../shared/state/user";
import { PortfolioPeriodScope } from "../../emissions/usePortfolio";
import { FilterType } from "../ProjectFiltersHOC";

export type GreenOfferDetailsType =
  | greenOfferDetailsAdminType
  | greenOfferDetailsApprovedType;

export type ViewAsType = "list" | "cards";

type ProjectMarketplaceContainerProps = {
  defaultFilters?: FilterType;
  projectMarketplaceFilterKey?: `${PortfolioPeriodScope["id"]}-scope${PortfolioPeriodScope["scope"]}`;
};

type FilterKey =
  | Exclude<
      ProjectMarketplaceContainerProps["projectMarketplaceFilterKey"],
      undefined
    >
  | "none";

export type ProjectViewPagination = {
  currentPage: number;
  defaultPageSize: number;
  setDefaultPageSize: Dispatch<SetStateAction<number>>;
};

export type OfferSummaryType = {
  countOffers: number;
  minOffersPrice: number;
  totalOffersVolume: number;
};

export const getOfferSummary = (
  projectOffers: GreenOfferDetailsType[]
): OfferSummaryType => {
  return projectOffers.reduce(
    (result: OfferSummaryType, d: GreenOfferDetailsType) => {
      result.countOffers += 1;
      if (d.offer_price) {
        result.minOffersPrice =
          result.minOffersPrice > 0
            ? Math.min(d.offer_price, result.minOffersPrice)
            : d.offer_price;
      }
      if (d.offer_size) result.totalOffersVolume += d.offer_size;
      return result;
    },
    {
      countOffers: 0,
      minOffersPrice: 0,
      totalOffersVolume: 0,
    }
  );
};

const offerPriceParamsCondition = (filter: FilterType) => {
  if (filter.minPrice && filter.maxPrice) {
    return {
      and: `(offer_price.gte.${filter.minPrice},offer_price.lte.${filter.maxPrice})`, // eslint-disable-line camelcase
    };
  } else if (filter.minPrice) {
    return {
      offer_price: `gte.${filter.minPrice}`, // eslint-disable-line camelcase
    };
  } else if (filter.maxPrice) {
    return {
      offer_price: `lte.${filter.maxPrice}`, // eslint-disable-line camelcase
    };
  } else {
    return {};
  }
};

// eslint-disable-next-line react/prop-types
const LoadingViewProjectsAsMap = ({ greenOfferDetails, carbonProjects }) => {
  return (
    <ViewProjectsAsMap
      allProjects={greenOfferDetails}
      carbonProjects={carbonProjects}
      mapHeight={"470px"}
      mapWidth={"100%"}
    />
  );
};

export const filterInDataByKey = (filter, data, key) => {
  if (filter && filter.length > 0) {
    return data.filter((val) =>
      filter
        .map((v) => v.toString().toLowerCase())
        .includes(val[key]?.toString().toLowerCase())
    );
  } else {
    return data;
  }
};

const comparedUnSDGoal = (unSDGoals, filter) => {
  if (unSDGoals === null) {
    return false;
  }
  return unSDGoals.some((e) => filter.includes(e));
};

const dataWithFilter = (filter, greenOfferDetails) => {
  greenOfferDetails = filterInDataByKey(
    filter.productType,
    greenOfferDetails,
    "product_type"
  );
  greenOfferDetails = filterInDataByKey(
    filter.method,
    greenOfferDetails,
    "certificate_project_type_name"
  );
  greenOfferDetails = filterInDataByKey(
    filter.vintage,
    greenOfferDetails,
    "vintage"
  );
  greenOfferDetails = filterInDataByKey(
    filter.region,
    greenOfferDetails,
    "region_name"
  );

  if (filter.coBenefits && filter.coBenefits.length > 0)
    greenOfferDetails = greenOfferDetails.filter((val) =>
      comparedUnSDGoal(val.un_sd_goals, filter.coBenefits)
    );

  if (filter.minPrice)
    greenOfferDetails = greenOfferDetails.filter(
      (val) =>
        val.offer_price !== null &&
        filter.minPrice &&
        val.offer_price >= filter.minPrice
    );

  if (filter.maxPrice) {
    greenOfferDetails = greenOfferDetails.filter(
      (val) =>
        val.offer_price !== null &&
        filter.maxPrice &&
        val.offer_price <= filter.maxPrice
    );
  }

  if (filter.search) {
    greenOfferDetails = greenOfferDetails.filter(
      (val) =>
        val.project_identifier?.indexOf(filter.search) > -1 ||
        val.name?.toLowerCase().indexOf(filter.search) > -1
    );
  }

  return greenOfferDetails;
};

const greenOfferDetailsAPIOptions = (filter: FilterType) => {
  return keysToSnake({
    ...(filter.productType &&
      Array.isArray(filter.productType) &&
      filter.productType.length > 0 && {
        productType: `in.(${filter.productType.join()})`,
      }),
    ...(filter.method &&
      Array.isArray(filter.method) &&
      filter.method.length > 0 && {
        certificateProjectTypeName: `in.(${filter.method.join()})`,
      }),
    ...(filter.creditType &&
      Array.isArray(filter.creditType) &&
      filter.creditType.length > 0 && {
        projectCreditType: `in.(${filter.creditType.join()})`,
      }),
    ...(filter.vintage &&
      Array.isArray(filter.vintage) &&
      filter.vintage.length > 0 && {
        vintage: `in.(${filter.vintage.join()})`,
      }),
    ...(filter.region &&
      Array.isArray(filter.region) &&
      filter.region.length > 0 && {
        regionName: `in.(${filter.region.join()})`,
      }),
    ...(filter.coBenefits &&
      Array.isArray(filter.coBenefits) &&
      filter.coBenefits.length > 0 && {
        unSdGoals: `cs.{${filter.coBenefits.join()}}`,
      }),
    ...offerPriceParamsCondition(filter),
    ...(filter.search && {
      or: `(project_identifier.ilike.%${encodeURIComponent(
        filter.search
      )}%,name.ilike.*${encodeURIComponent(filter.search)}*)`,
    }),
  });
};

export function useGreenOfferDetail(
  filter: FilterType,
  viewAs: "list" | "cards"
) {
  const scopes = useScopes();
  const selectColumn = scopes.includes("bezero.rating.read")
    ? ["*", "bezero_ratings"]
    : ["*"];
  const {
    data: { data },
    sync,
    error,
    loading,
  } = useAPIQueryWithPlaceHolder(
    "greenOfferDetails",
    {
      select: selectColumn.join(),
      order: "name.asc,tenor_type.asc.nullsfirst,vintage.asc.nullsfirst",
      ...(viewAs === "cards" && {
        certificate_project: "not.is.null", // eslint-disable-line camelcase
      }),
      ...greenOfferDetailsAPIOptions(filter),
      ...(scopes.includes("bezero.rating.read") &&
        filter.bezero &&
        Array.isArray(filter.bezero) &&
        filter.bezero.length > 0 &&
        keysToSnake({
          bezeroRatingCode: `in.(${filter.bezero.join()})`,
        })),
    },
    false,
    undefined,
    (data) => {
      return dataWithFilter(filter, data);
    }
  );

  return { data, sync, error, loading };
}

const localStorageFilter = "greenMarketProjectFilter";
const localStorageCardPagination = "greenMarketProjectCardPagination";
const localStorageListPagination = "greenMarketProjectListPagination";
const localStorageShowMap = "greenMarketProjectShowMap";

export const ProjectMarketplaceContainer: React.FC<
  ProjectMarketplaceContainerProps
> = ({ defaultFilters, projectMarketplaceFilterKey }) => {
  const key: FilterKey = projectMarketplaceFilterKey ?? "none";
  const [localProjectViewAs, setLocalProjectViewAs] =
    useLocalStorageUntilLogoutPerKey<ViewAsType>("greenMarketProjectViewAs", {
      key: key,
      initialValue: "list",
    });

  const [localProjectFilter, setLocalProjectFilter] =
    useLocalStorageUntilLogoutPerKey<FilterType | undefined>(
      localStorageFilter,
      {
        key: key,
        initialValue: undefined,
      }
    );
  const [localProjectCardPagination, setLocalProjectCardPagination] =
    useLocalStorageUntilLogoutPerKey<number>(localStorageCardPagination, {
      key: key,
      initialValue: 1,
    });
  const [localProjectListPagination, setLocalProjectListPagination] =
    useLocalStorageUntilLogoutPerKey<number>(localStorageListPagination, {
      key: key,
      initialValue: 1,
    });

  const onProjectCardPaginationChange = (val: number) => {
    setLocalProjectCardPagination(val);
  };
  const onProjectListPaginationChange = (val: number) => {
    setLocalProjectListPagination(val);
  };

  const filterChanges =
    localProjectFilter !== undefined
      ? localProjectFilter
      : defaultFilters ?? {};

  const [localProjectShowMap, setLocalProjectShowMap] =
    useLocalStorageUntilLogout<boolean>({
      key: localStorageShowMap,
      initialValue: true,
    });

  const [defaultPageSize, setDefaultPageSize] = useState<number>(20);
  const [showMapSwitch, setShowMapSwitch] = useState<boolean>(true);
  const { data, sync, error, loading } = useGreenOfferDetail(
    filterChanges,
    localProjectViewAs ?? "list"
  );
  const hasNoData = data === undefined || data.length === 0;
  let greenOfferDetails = hasNoData ? [] : data;

  if (error) return <COREError />;

  const onFilterChange = (value: FilterType) => {
    setLocalProjectFilter(value);
    setLocalProjectCardPagination(1);
    setLocalProjectListPagination(1);
  };

  const carbonProjects: GreenOfferDetailsType[] = uniqueByKey(
    greenOfferDetails.filter((p) => p.certificate_project !== null),
    "certificate_project"
  );
  const wholesaleProducts: GreenOfferDetailsType[] = uniqueByMultipleKeys(
    greenOfferDetails.filter((p) => p.certificate_project === null),
    ["product_type", "project_method_type"]
  );

  return (
    <COREContainer
      testID={"projectmarketplace-project-marketplace"}
      header={"Project marketplace"}
      noScroll={true}
      interactionsRight={[
        <CORETypographyInput type={"label"} key={"show-map-label"}>
          Show map
        </CORETypographyInput>,
        <CORESwitch
          size={"lg"}
          testID={generateTestId("projectmarketplace", "switch-show-map")}
          onChange={(v) => setLocalProjectShowMap(v)}
          checked={localProjectShowMap}
          key={"show-map-switch"}
        />,
      ]}
      interactionsMenu={[
        {
          component: (
            <COREMenuItem
              testID={generateTestId("projectmarketplace", "switchShowMap")}
              key={"switchShowMap"}
            >
              <Space size={8} direction={"horizontal"}>
                <CORESwitch
                  id={"switchShowMap"}
                  size="sm"
                  defaultChecked={true}
                  checked={localProjectShowMap}
                  onChange={(value) => setLocalProjectShowMap(value)}
                  testID={generateTestId(
                    "projectmarketplace",
                    "switchShowMap-CORESwitch"
                  )}
                />
                <COREBody marginBottom={false} type="p3">
                  Show map
                </COREBody>
              </Space>
            </COREMenuItem>
          ),
          closeWhenClick: false,
        },
        {
          component: (
            <COREMenuItem
              testID={generateTestId(
                "projectmarketplace",
                "switchMenuCardView"
              )}
              key={"switchMenuCardView"}
            >
              <Space size={8} direction={"horizontal"}>
                <CORESwitch
                  id={"switchCardView"}
                  size="sm"
                  defaultChecked={false}
                  checked={localProjectViewAs === "cards"}
                  onChange={(value) =>
                    setLocalProjectViewAs(value ? "cards" : "list")
                  }
                  testID={generateTestId(
                    "projectmarketplace",
                    "switchCardView-CORESwitch"
                  )}
                />
                <COREBody marginBottom={false} type="p3">
                  Card view
                </COREBody>
              </Space>
            </COREMenuItem>
          ),
          closeWhenClick: false,
        },
      ]}
      menuType={"differentMenu"}
      onShowMenu={(isShowMenu: boolean) => {
        setShowMapSwitch(!isShowMenu);
      }}
    >
      <CORELoading delay={0} size={"lg"} loading={loading && !sync}>
        <Row gutter={[spacing.lg, spacing.lg]}>
          <Col span={24}>
            {localProjectShowMap && (
              <LoadingViewProjectsAsMap
                greenOfferDetails={greenOfferDetails}
                carbonProjects={carbonProjects}
              />
            )}
          </Col>
          <Col>
            <Row gutter={[spacing.md, spacing.md]}>
              <Col span={24}>
                <ProjectMarketplaceFilter
                  defaultFilterValues={defaultFilters}
                  filterValues={filterChanges}
                  onChange={onFilterChange}
                  viewAs={localProjectViewAs ?? "list"}
                  setViewAs={setLocalProjectViewAs}
                  showMapSwitch={showMapSwitch}
                />
              </Col>
              <Col span={24}>
                {localProjectViewAs === "cards" ? (
                  <ViewProjectsAsCards
                    allProjects={greenOfferDetails}
                    carbonProjects={carbonProjects}
                    onProjectPaginationChange={onProjectCardPaginationChange}
                    pagination={{
                      currentPage: localProjectCardPagination ?? 1,
                      defaultPageSize,
                      setDefaultPageSize,
                    }}
                  />
                ) : (
                  <ViewProjectsAsList
                    allProjects={greenOfferDetails}
                    carbonProjects={carbonProjects}
                    wholesaleProducts={wholesaleProducts}
                    onProjectPaginationChange={onProjectListPaginationChange}
                    pagination={{
                      currentPage: localProjectListPagination ?? 1,
                      defaultPageSize,
                      setDefaultPageSize,
                    }}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </CORELoading>
    </COREContainer>
  );
};
