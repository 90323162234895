/* eslint-disable react/prop-types */
import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { COREHeading } from "../Typography/COREHeading";
import {
  canvasLegendBlock,
  canvasTitleBlock,
  ChartDataType,
  COREChart,
  COREChartProps,
  downloadFileName,
  drawLegendBlock,
  drawPlotlyBlock,
  drawTitleChartBlock,
  setPlotlyImage,
} from "./COREChart";
import { CORELegend } from "./CORELegend";
import "./COREChart.less";
import { AggregatesChartProps } from "../../modules/tools/chartBuilder/AggregatesChartV2";
import { CORETagProps } from "../Content/CORETag";
import { generateTestId, TestID } from "../../shared/testids/testids";
import ImgCOREMarketsLogoSVG from "../../assets/img/COREMarketsLogoForDownload.svg";
import { Col, Row } from "antd";
import { COREDivider } from "../Layout/COREDivider";
import { PlotRefType } from "./ChartHOC";
import equal from "fast-deep-equal";
import { spacing } from "../../shared/global";

export type Legend = Pick<
  CORETagProps,
  "label" | "color" | "checked" | "tagStyle"
> & {
  key: string;
};

type COREChartLegendProps = Pick<
  AggregatesChartProps,
  | "chartId"
  | "chartTitle"
  | "downloadCsv"
  | "downloadPng"
  | "downloadPngCanvas"
  | "enableEmptyIcon"
  | "testID"
  | "showlegend"
  | "legendFilter"
  | "plotRef"
  | "chartPanel"
  | "ref"
  | "empty"
> &
  Pick<
    COREChartProps,
    "data" | "layout" | "configRangeOfYAxis" | "hoverColor"
  > & {
    legends: Legend[];
    screenShotSize?: {
      width: number;
      height: number;
    };
  };

export const drawImageIntoCanvas = async (
  plotRef: COREChartProps["plotRef"],
  titleRef: COREChartProps["titleRef"],
  legendRef: COREChartProps["legendRef"],
  chartTitle: COREChartProps["chartTitle"],
  screenShotSize?: COREChartLegendProps["screenShotSize"]
) => {
  const canvasTotalWidth = screenShotSize ? screenShotSize.width : 1280;
  const canvasTotalHeight = screenShotSize ? screenShotSize.height : 780;
  const basePadding = 24;
  const spaceBetween = 16;

  if (!plotRef || !titleRef || !legendRef) {
    console.error("One or more refs are null or undefined.");
    return;
  }

  const plotlyBlock = await setPlotlyImage(plotRef);
  const titleBlock = await canvasTitleBlock({
    titleDOM: titleRef,
  });
  const legendBlock = await canvasLegendBlock({
    legendDOM: legendRef,
  });

  const COREMarkets = new Image();
  COREMarkets.src = ImgCOREMarketsLogoSVG;
  COREMarkets.alt = "COREMarkets-logo";

  const titleBlockHeight = titleBlock?.height
    ? titleBlock?.height + spaceBetween
    : 0;
  const legendBlockHeight = legendBlock?.height
    ? legendBlock?.height + spaceBetween
    : 0;

  const finalCanvas = document.createElement("canvas");
  finalCanvas.setAttribute("width", `${canvasTotalWidth}`);
  finalCanvas.setAttribute("height", `${canvasTotalHeight}`);

  const ctx = finalCanvas.getContext("2d");
  if (ctx === null) throw Error("error");
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, canvasTotalWidth, canvasTotalHeight);

  COREMarkets.onload = () => {
    drawTitleChartBlock({
      titleBlock: titleBlock,
      ctx: ctx,
      canvasTotalWidth: canvasTotalWidth,
      titleMarginTop: basePadding,
    });

    drawLegendBlock({
      legendBlock: legendBlock,
      ctx: ctx,
      titleBlockHeight: titleBlockHeight,
      marginLegendTop: spaceBetween,
      canvasTotalWidth: canvasTotalWidth,
    });

    drawPlotlyBlock({
      plotlyBlock: plotlyBlock,
      ctx: ctx,
      canvasTotalWidth: canvasTotalWidth,
      titleBlockHeight: titleBlockHeight,
      legendBlockHeight: legendBlockHeight,
      canvasTotalHeight: canvasTotalHeight,
    });

    ctx.drawImage(
      COREMarkets,
      0,
      0,
      COREMarkets.width,
      COREMarkets.height,
      canvasTotalWidth - COREMarkets.width - basePadding,
      canvasTotalHeight - (COREMarkets.height + 16),
      COREMarkets.width,
      COREMarkets.height
    );

    const image = finalCanvas.toDataURL("image/png", 1.0);
    const tagA = document.createElement("a");
    tagA.setAttribute(
      "download",
      downloadFileName(`${chartTitle ?? "Newplot"}`)
    );
    tagA.setAttribute("href", image);
    tagA.click();
  };
};

const checkDataExist = (d?: Partial<ChartDataType>) => {
  if (!!d && d.type === "bar") {
    const y = d.mode !== "markers" ? (d.y as number[]) ?? [] : [];
    return y.length > 0 && y.filter((value) => value).length > 0;
  }
  return true;
};

const ChartLegend: React.FC<COREChartLegendProps> = forwardRef(
  ({ data, legends, ...props }, ref) => {
    const filteredLegends = legends.filter((l) =>
      checkDataExist(data.find((d) => l.label === d.name))
    );
    return (
      <ChartLegendBody
        ref={ref}
        data={data}
        legends={filteredLegends}
        {...props}
      />
    );
  }
);

const emptyDataCheck = (data: Partial<ChartDataType>[]) => {
  if (data.length === 0) return true;
  return data.every((datalist) => {
    return (
      (datalist.x?.length === 0 && datalist.y?.length === 0) ||
      (!datalist.y?.some((v) => v !== null) &&
        !datalist.r?.some((v) => v !== null))
    );
  });
};

const ChartLegendBodyPNG: React.FC<
  Pick<COREChartLegendProps, "layout" | "testID" | "legendFilter"> & {
    isTitleHidden: boolean;
    selectLegend: Legend[];
    setSelectLegend: Dispatch<SetStateAction<Legend[]>>;
    titleRef: React.RefObject<HTMLDivElement>;
    legendRef: React.RefObject<HTMLDivElement>;
  }
> = ({
  layout,
  testID,
  isTitleHidden,
  legendFilter,
  selectLegend,
  setSelectLegend,
  titleRef,
  legendRef,
}) => {
  const ScreenSizeWidth = 1200;

  return (
    <Row
      className={"chart-title-by-file-name-block"}
      hidden={isTitleHidden}
      align={"middle"}
      justify={"center"}
      gutter={[spacing.xxs, spacing.xxs]}
    >
      <Col ref={titleRef} className={"chart-title-block"}>
        <COREHeading
          level={3}
          marginBottom={true}
          testID={generateTestId(
            "chartbuilder",
            "show-title-of-chart-by-file-name"
          )}
        >
          {layout.filename}
        </COREHeading>
      </Col>
      <Col span={24}>
        <COREDivider space="none"></COREDivider>
      </Col>
      <div style={{ width: `${ScreenSizeWidth}px` }} ref={legendRef}>
        <CORELegend
          selectLegend={selectLegend}
          setSelectLegend={setSelectLegend}
          legendFilter={legendFilter}
          testID={`${testID}-legend` as TestID}
          maxWidth={172}
          isShowModal={false}
        />
      </div>
    </Row>
  );
};

const ChartLegendBody: React.FC<COREChartLegendProps> = forwardRef(
  (
    {
      chartId,
      chartTitle,
      downloadCsv,
      downloadPng,
      downloadPngCanvas,
      data,
      layout,
      configRangeOfYAxis,
      hoverColor,
      enableEmptyIcon,
      testID,
      legends,
      showlegend,
      legendFilter,
      plotRef,
      chartPanel,
      empty,
      screenShotSize = {
        width: 1280,
        height: 780,
      },
    },
    ref
  ) => {
    const [selectLegend, setSelectLegend] = useState(legends);
    const legendRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLDivElement>(null);
    const defaultPlotRef = useRef<PlotRefType>();
    const [isTitleHidden, setIsTitleHidden] = useState<boolean>(true);

    useEffect(() => {
      setSelectLegend(legends);
    }, [legends]);

    useImperativeHandle(ref, () => ({
      callDrawImage() {
        setIsTitleHidden(false);
        drawImageIntoCanvas(
          plotRef ?? defaultPlotRef,
          titleRef,
          legendRef,
          chartTitle,
          screenShotSize
        ).then(() => {
          setIsTitleHidden(true);
        });
      },
    }));

    const chartData: Partial<ChartDataType>[] = data.filter(
      (d) =>
        legends.map((l) => l.label).includes(d.name) &&
        legends.find((l) => l.label === d.name)?.checked
    );

    return (
      <Row align={"middle"} gutter={[0, spacing.md]}>
        {!chartPanel && (
          <Col span={24} ref={titleRef}>
            <COREHeading
              level={3}
              marginBottom={false}
              testID={`${testID}-title`}
            >
              {chartTitle}
            </COREHeading>
          </Col>
        )}
        {!chartPanel && (
          <Col span={24}>
            <COREDivider space="none"></COREDivider>
          </Col>
        )}
        {showlegend && legends.length !== 0 && (
          <Col span={24}>
            <CORELegend
              selectLegend={selectLegend}
              setSelectLegend={setSelectLegend}
              legendFilter={legendFilter}
              testID={`${testID}-main-legend` as TestID}
              maxWidth={172}
            />
          </Col>
        )}
        <Col span={24}>
          {!emptyDataCheck(chartData) ? (
            <COREChart
              chartId={chartId}
              chartTitle={chartTitle}
              testID={testID}
              plotRef={plotRef ?? defaultPlotRef}
              legendRef={legendRef}
              titleRef={titleRef}
              downloadCsv={downloadCsv}
              downloadPng={downloadPng}
              downloadPngCanvas={downloadPngCanvas}
              data={chartData}
              layout={layout}
              useResizeHandler={true}
              configRangeOfYAxis={configRangeOfYAxis}
              config={{
                displaylogo: false,
                displayModeBar: false,
              }}
              showTimeButtons={false}
              hoverColor={hoverColor}
              enableEmptyIcon={enableEmptyIcon}
            />
          ) : (
            empty
          )}
        </Col>
        {chartPanel && layout.filename && (
          <ChartLegendBodyPNG
            testID={testID}
            layout={layout}
            isTitleHidden={isTitleHidden}
            selectLegend={selectLegend}
            setSelectLegend={setSelectLegend}
            titleRef={titleRef}
            legendRef={legendRef}
          />
        )}
      </Row>
    );
  }
);

export const COREChartLegend = React.memo<
  React.ComponentType<COREChartLegendProps & { ref?: React.ForwardedRef<any> }>
>(
  ChartLegend,
  (prevProps, nextProps) =>
    prevProps &&
    equal(JSON.stringify(prevProps.data), JSON.stringify(nextProps.data)) &&
    equal(
      JSON.stringify(prevProps.layout.width),
      JSON.stringify(nextProps.layout.width)
    )
);
