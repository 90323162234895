import React, { useMemo, useRef } from "react";
import JoditEditor, { IJoditEditorProps } from "jodit-react";

type CORETextEditorProps = IJoditEditorProps & {
  placeholder?: string;
};

export const CORETextEditor: React.FC<CORETextEditorProps> = ({
  placeholder = "",
  ...props
}) => {
  const editorRef = useRef(null);
  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder,
      height: "350px",
      uploader: {
        insertImageAsBase64URI: true,
      },
      disablePlugins: ["image-properties"],
      removeButtons: [
        "font",
        "print",
        "about",
        "speechRecognize",
        "classSpan",
        "ai-commands",
        "ai-assistant",
      ],
    }),
    [placeholder]
  );

  return <JoditEditor ref={editorRef} config={config} {...props} />;
};
