import React from "react";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { COREButton } from "../../../COREDesignSystem/Action/COREButton";
import { COREIcon } from "../../../COREDesignSystem/Content/COREIcon";
import { COREBody } from "../../../COREDesignSystem/Typography/COREBody";
import { COREHeading } from "../../../COREDesignSystem/Typography/COREHeading";
import {
  onSelectSetupMenu,
  SetupWidgetProps,
} from "../../dashboards/WidgetPanel";
import "./MarketCommentaryWidget.less";
import { Col, Row, Space } from "antd";
import { capitalizeFirstLetter } from "../../../shared/global";
import { COREMenuItem } from "../../../COREDesignSystem/Navigation/COREMenuItem";
import { generateTestId } from "../../../shared/testids/testids";
import { DropdownMenuItem } from "../../../COREDesignSystem/Form/COREDropdownMenu";
import { BrokerChartFilter } from "../carbonComponent/BrokerChartWithFilter";
import {
  DailyCurveProductType,
  useAllProductTypes,
} from "../../../shared/customHoooks/useProductTypes";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { COREMenuItemGroup } from "../../../COREDesignSystem/Navigation/COREMenuItemGroup";

type SetupDailyCurveChartWidgetProps = SetupWidgetProps<
  DailyCurveProductType,
  BrokerChartFilter
>;

export const useMenuGroupOfProducts = (
  widgetProps: SetupDailyCurveChartWidgetProps["widgetProps"]
): DropdownMenuItem[] => {
  const productTypes = useAllProductTypes();
  return productTypes.map((p) => {
    return {
      closeWhenClick: true,
      component: (
        <COREMenuItemGroup
          key={`${p}-group-item`}
          testID={generateTestId("company", "company-menu-group-item")}
          title={`${capitalizeFirstLetter(p.productClass)} market`}
        >
          <COREDivider space="none" />
          {p.productTypes.map((t) => (
            <COREMenuItem
              testID={generateTestId("company", "add-company-menu-item")}
              key={`${t}-item`}
              onClick={() => {
                onSelectSetupMenu(widgetProps, t);
              }}
            >
              {t.toUpperCase()}
            </COREMenuItem>
          ))}
        </COREMenuItemGroup>
      ),
    };
  });
};

export const SetupDailyCurveChartWidget: React.FC<
  SetupDailyCurveChartWidgetProps
> = ({ widgetProps, testID }) => {
  const productClass = useAllProductTypes();

  return (
    <div className={"setup-market-block-custom"}>
      <COREHeading
        level={3}
        testID={`${testID}-select-market`}
        marginBottom={false}
      >
        Select product type
      </COREHeading>
      <COREBody>
        Use the buttons below or the widget menu (
        {<COREIcon icon={icon({ name: "bars", style: "regular" })} size="xs" />}
        ) to configure your content preferences. You can have multiple widgets
        of this type and each can be configured separately.
      </COREBody>
      <Row gutter={[0, 24]}>
        {productClass.map((p) => {
          return (
            <Col span={24} key={`${p.productClass}-block`}>
              <COREHeading testID={`${testID}-carbon-product-group`} level={5}>
                {capitalizeFirstLetter(p.productClass)} market products
              </COREHeading>
              <Space
                size={8}
                align="center"
                style={{ justifyContent: "center" }}
              >
                {p.productTypes.map((product) => (
                  <COREButton
                    key={`${product}-button`}
                    size="lg"
                    onClick={() => {
                      onSelectSetupMenu(widgetProps, product);
                    }}
                    testID={`${testID}-setup-menu-${product}-menu-item`}
                  >
                    {product.toUpperCase()}
                  </COREButton>
                ))}
              </Space>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
