import { Space } from "antd";
import { COREBody, COREBodyProps } from "../Typography/COREBody";
import "./COREFlag.less";
import React from "react";
import { COREIcon } from "./COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { AllColorHEX, grey100, grey140, lime100, red100 } from "./COREColour";
import { CORETooltip } from "../Overlay/CORETooltip";
import { generateTestId } from "../../shared/testids/testids";

export type COREFlagType = {
  type: "indicative" | "firm" | "strip";
};
export type COREFlagNode = {
  gap?: number;
  children?: React.ReactNode;
};

export type COREPriceDirectionIndicatorProps = COREFlagNode & {
  type: "up" | "down" | "noChanges" | "none";
  changeValue?: number;
};

export const COREPriceDirectionIndicator: React.FC<
  COREPriceDirectionIndicatorProps
> = ({ children, gap, type, changeValue }) => {
  const directionIndicatorIcon = {
    up: <COREIcon icon={icon({ name: "caret-up" })} color={lime100} />,
    down: <COREIcon icon={icon({ name: "caret-down" })} color={red100} />,
    noChanges: <COREIcon icon={icon({ name: "hyphen" })} color={grey100} />,
    none: null,
  }[type];

  const messageColor = {
    up: lime100,
    down: red100,
    noChanges: grey140,
    none: grey140,
  }[type];

  return (
    <Space direction="horizontal" size={gap}>
      <CORETooltip
        title=""
        message={
          <COREBody
            color={messageColor as AllColorHEX}
            strong={!!changeValue}
            marginBottom={false}
          >
            {changeValue ? (
              <>
                {directionIndicatorIcon}
                {Math.abs(changeValue)}
              </>
            ) : (
              "No change"
            )}
          </COREBody>
        }
        position="top"
        testID={generateTestId(
          "marketprices",
          "price-direction-indicator-tooltips"
        )}
        hidden={type === "none"}
      >
        {directionIndicatorIcon}
      </CORETooltip>
      <span>{children}</span>
    </Space>
  );
};

export const COREIndicative: React.FC<
  COREFlagNode & { type?: COREBodyProps["type"] }
> = ({ children, gap = 0, type = "p2" }) => {
  return (
    <Space direction="horizontal" size={gap}>
      <COREFlag type="indicative" />
      <COREBody type={type} marginBottom={false} italic={true} color={grey140}>
        {children}
      </COREBody>
    </Space>
  );
};

export const COREStrip: React.FC<
  COREFlagNode & { type?: COREBodyProps["type"] }
> = ({ children, gap = 0, type = "p2" }) => {
  return (
    <Space direction="horizontal" size={gap}>
      <COREFlag type="strip" />
      <COREBody type={type} marginBottom={false}>
        {children}
      </COREBody>
    </Space>
  );
};

export const COREFlag: React.FC<COREFlagType> = ({ type }) => {
  if (!type || type === "firm") {
    return null;
  }

  return <span className={`core-flag-indicator-${type}-flag`} />;
};
