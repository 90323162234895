import React from "react";
import {
  CORESelect,
  CORESelectProps,
} from "../../COREDesignSystem/Form/CORESelect";

export const articlesAndUpdatesOptions = [
  {
    label: "$300 Cap",
    value: "668b24ea37794fd042f297d4",
  },
  {
    label: "ACCU",
    value: "668b240f5943d95ab66e2ca1",
  },
  {
    label: "ACR",
    value: "668b2439e18e604c509d6345",
  },
  {
    label: "APAC",
    value: "668b23dd2fd2fee2f2c49006",
  },
  {
    label: "Biodiversity",
    value: "668b250a1f29d0dfd5803ab4",
  },
  {
    label: "CAR",
    value: "668b2444b08d1a7a8fbeb7d1",
  },
  {
    label: "Carbon",
    value: "64f51b1170fc318bbf20522f",
  },
  {
    label: "Climate Change",
    value: "64f51b1170fc318bbf20527f",
  },
  {
    label: "Electricity",
    value: "668b23ce134dc172d67386d7",
  },
  {
    label: "EMEA",
    value: "668b2545358ce10f3577b457",
  },
  {
    label: "Emissions Reduction",
    value: "64f51b1170fc318bbf2052eb",
  },
  {
    label: "Environmental",
    value: "668b23b1c39b281a24273dff",
  },
  {
    label: "ESC",
    value: "668b248d2fd2fee2f2c4ef00",
  },
  {
    label: "Europe",
    value: "668b2515510bdea9ea40f1c6",
  },
  {
    label: "Flat",
    value: "668b24c46736455943e98d4c",
  },
  {
    label: "Global",
    value: "668b23ec510bdea9ea403dde",
  },
  {
    label: "Government Policy",
    value: "64f51b1170fc318bbf2051e5",
  },
  {
    label: "GS",
    value: "668b2429cd57736f5c9c9a9f",
  },
  {
    label: "Inverse Solar",
    value: "668b24b8c3a8abb2efdf5125",
  },
  {
    label: "LGC",
    value: "668b2455f80ed0dcd2ba1147",
  },
  {
    label: "Markets",
    value: "668b23f7d23e3de91a4b2076",
  },
  {
    label: "Nature",
    value: "65c5771995bf6b7decc671b2",
  },
  {
    label: "North America",
    value: "668b251f55cfbdd7d033559f",
  },
  {
    label: "Off Peak",
    value: "668b24de2209cc713b017136",
  },
  {
    label: "Peak",
    value: "668b24d2c4c6b5ad30092435",
  },
  {
    label: "PRC",
    value: "668b248037148c8522895df7",
  },
  {
    label: "Renewable Energy",
    value: "64f51b1170fc318bbf205270",
  },
  {
    label: "Solar",
    value: "668b249af3f81bbf3bbb5d32",
  },
  {
    label: "South America",
    value: "668b252b1f29d0dfd5804c01",
  },
  {
    label: "STC",
    value: "668b2462330799edca9a6010",
  },
  {
    label: "Super Peak",
    value: "668b24aa9041faaea1642cd0",
  },
  {
    label: "Sustainability",
    value: "64f51b1170fc318bbf2052ea",
  },
  {
    label: "VCU",
    value: "668b241b35a37bf83ff72e0b",
  },
  {
    label: "VEEC",
    value: "668b2470510bdea9ea40a418",
  },
  {
    label: "Voluntary Carbon Markets",
    value: "668b24f9b08d1a7a8fbf176a",
  },
  {
    label: "Guide",
    value: "64f51b1170fc318bbf2052ec",
  },
];

export const ArticlesAndUpdatesCategorySelect: React.FC<
  Exclude<CORESelectProps, "options">
> = (porps) => <CORESelect {...porps} options={articlesAndUpdatesOptions} />;
