import React from "react";
import {
  currentWidgetFilters,
  currentWidgetTestID,
  WidgetPanel,
  WidgetProps,
} from "../../WidgetPanel";
import {
  SetupDailyCurveChartWidget,
  useMenuGroupOfProducts,
} from "../../../dailyCurves/widget/SetupDailyCurveChartWidget";
import { BrokerChartFilter } from "../../../dailyCurves/carbonComponent/BrokerChartWithFilter";
import { useFeatureFlags } from "../../../../shared/customHoooks/useFeatureFlags";
import { TestID } from "../../../../shared/testids/testids";
import { CORELoading } from "../../../../COREDesignSystem/Feedback/CORELoading";
import { COREIcon } from "../../../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { CORESubMenu } from "../../../../COREDesignSystem/Navigation/CORESubMenu";
import { COREEmpty } from "../../../../COREDesignSystem/Content/COREEmpty";
import { CarbonCurveHistoryChartWidget } from "./CarbonCurveHistoryChartWidget";
import {
  DailyCurveProductType,
  getProductTypePermission,
  useDailyCurveFeaturePermissions,
} from "../../../../shared/customHoooks/useProductTypes";
import { EnvironmentalForwardCurveChartWidget } from "./EnvironmentalForwardCurveChartWidget";

type DailyCurveHistoryChartWidgetType<T, WidgetFilterType extends object> = {
  props: WidgetProps<T, WidgetFilterType>;
};

const PermissionBlockContent: React.FC<{ testID: TestID }> = ({ testID }) => (
  <COREEmpty
    testID={`${testID}-empty-content`}
    description="Missing permissions"
    hint={`You don't have access to this content, this could be as a result of changes in your subscription’s included features.`}
  />
);

export const DailyCurveHistoryChartWidget = ({
  props,
}: DailyCurveHistoryChartWidgetType<
  DailyCurveProductType,
  BrokerChartFilter
>) => {
  const testID = currentWidgetTestID(props);
  const currentProps = currentWidgetFilters(props);
  const currentWidgetType = currentProps?.widgetType as DailyCurveProductType;
  const typeOfProductWidget = ["veec", "lgc", "stc", "prc", "esc"].includes(
    currentWidgetType
  );
  const widgetTypeUpperCase = currentWidgetType
    ? currentWidgetType.toUpperCase()
    : currentWidgetType;
  const { sync, loading, features } = useFeatureFlags();
  const subInteractionMenu = useMenuGroupOfProducts(props);
  const featurePermission = useDailyCurveFeaturePermissions(currentWidgetType);
  const productPermission = getProductTypePermission(
    features,
    currentWidgetType
  );
  const subMenu = subInteractionMenu.map((menu) => menu.component);
  const hasPermission = !featurePermission || !productPermission;

  const interactionsMenu =
    subMenu.length !== 0
      ? [
          {
            component: (
              <CORESubMenu
                testID={`${testID}-setup-sub-menu`}
                title="Widget setup"
                icon={
                  <COREIcon
                    icon={icon({
                      name: "pen-to-square",
                      style: "regular",
                    })}
                  />
                }
              >
                {subMenu}
              </CORESubMenu>
            ),
            closeWhenClick: true,
          },
        ]
      : [];

  const renderWidgetContent = () => {
    switch (true) {
      case hasPermission:
        return <PermissionBlockContent testID={testID} />;

      case !!currentWidgetType && !typeOfProductWidget:
        return (
          <CarbonCurveHistoryChartWidget
            testID={testID}
            widgetProps={props}
            widgetType={currentWidgetType}
          />
        );

      case !!currentWidgetType && typeOfProductWidget:
        return (
          <EnvironmentalForwardCurveChartWidget
            certificate={widgetTypeUpperCase}
            testID={testID}
            widgetProps={props}
            widgetType={currentWidgetType}
          />
        );

      default:
        return (
          <SetupDailyCurveChartWidget
            testID={`${testID}-setup-chart`}
            widgetProps={props}
          />
        );
    }
  };

  return (
    <CORELoading loading={loading && !sync}>
      <WidgetPanel
        interactionsMenu={interactionsMenu}
        testID={`${testID}-widgets`}
        panelTitle={
          currentWidgetType
            ? `Daily curve - ${currentWidgetType.toUpperCase()}`
            : "Daily curve - widget setup"
        }
      >
        {renderWidgetContent()}
      </WidgetPanel>
    </CORELoading>
  );
};
