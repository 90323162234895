import React from "react";
import { COREBody } from "../../COREDesignSystem/Typography/COREBody";
import { grey120, grey140 } from "../../COREDesignSystem/Content/COREColour";
import { COREIcon } from "../../COREDesignSystem/Content/COREIcon";
import { Space } from "antd";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Order } from "../customHoooks/useUpdatable";

export const SortButton: React.FC<{
  type?: Order;
  onClick: (value: Order) => void;
}> = ({ type, onClick }) => {
  if (type === "asc")
    return (
      <Space align={"center"}>
        <COREBody
          marginBottom={false}
          color={grey140}
          onClick={() => onClick("desc")}
        >
          <COREIcon
            onClick={() => onClick("desc")}
            icon={icon({ name: "arrow-up-wide-short" })}
            color={grey120}
          />{" "}
          Oldest first
        </COREBody>
      </Space>
    );
  return (
    <Space align={"center"}>
      <COREBody
        marginBottom={false}
        color={grey140}
        onClick={() => onClick("asc")}
      >
        <COREIcon
          onClick={() => onClick("asc")}
          icon={icon({ name: "arrow-down-wide-short" })}
          color={grey120}
        />{" "}
        Newest first
      </COREBody>
    </Space>
  );
};
