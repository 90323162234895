import { Col, Row } from "antd";
import { CORECard } from "../../../COREDesignSystem/Content/CORECard";
import { COREDisplayComment } from "../../../COREDesignSystem/Content/COREDisplayComment";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { useNewsAndAlertsWithDatePaginate } from "../../../shared/customHoooks/useNewsAndAlerts";
import { TestID, TestIDWrapper } from "../../../shared/testids/testids";
import React, { useState } from "react";
import moment from "moment";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import {
  convertMomentToMarketTimezone,
  formatDate,
  isTodayDate,
  isYesterdayDate,
} from "../../../shared/date/DateTime";
import { SortButton } from "../../../shared/buttons/SortButton";
import { Order } from "../../../shared/customHoooks/useUpdatable";
import { NewsAndAlertsFilters } from "../../admin/contentManagement/newsAndAlerts/NewsAndAlertsFilters";
import { NewsAndAlertFilters } from "../../admin/contentManagement/newsAndAlerts/NewsAndAlertsTableWithFilters";
import {
  COREInfiniteScroll,
  InfiniteScrollProgressBlock,
} from "../../../COREDesignSystem/Content/COREInfiniteScroll";
import { useDeepCompareEffect } from "react-use";
import { createRange, NonEmptyRange } from "../../../shared/date/ranges";
import "./NewsAndAlertsCard.less";
import { COREIcon } from "../../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { orange100 } from "../../../COREDesignSystem/Content/COREColour";
import { COREBody } from "../../../COREDesignSystem/Typography/COREBody";
import { WidgetPanel } from "../../dashboards/WidgetPanel";
import { useFeatureFlags } from "../../../shared/customHoooks/useFeatureFlags";
import { COREEmpty } from "../../../COREDesignSystem/Content/COREEmpty";
import { getNewsAndAlertsPermission } from "../../admin/contentManagement/newsAndAlerts/newsAndAlertsHelper";
import { COREButton } from "../../../COREDesignSystem/Action/COREButton";

const NewsAndAlertList: React.FC<{
  testID: TestID;
  filterValues?: NewsAndAlertFilters;
  isWidget?: boolean;
}> = ({ testID, filterValues, isWidget }) => {
  const [loadedRange, setLoadedRange] = useState<NonEmptyRange>();

  useDeepCompareEffect(() => {
    setLoadedRange(undefined);
  }, [filterValues]);

  const { updatable, loading, sync, hasMore } =
    useNewsAndAlertsWithDatePaginate(
      filterValues,
      undefined,
      loadedRange,
      "week"
    );

  let currentDate: moment.Moment | null = null;

  return (
    <COREInfiniteScroll
      loadMore={() => {
        if (!hasMore) return;
        if (!loadedRange) {
          const latestRecord = updatable[updatable.length - 1];
          const latestRecordDate = convertMomentToMarketTimezone(
            moment(latestRecord.data?.alertTimestamp)
          );
          setLoadedRange(createRange(latestRecordDate, latestRecordDate));
        } else {
          if (filterValues?.orderBy === "asc") {
            setLoadedRange(
              createRange(
                loadedRange.from,
                loadedRange.to.add(1, "week"),
                loadedRange.bounds
              )
            );
          } else {
            setLoadedRange(
              createRange(
                loadedRange.from.subtract(1, "week"),
                loadedRange.to,
                loadedRange.bounds
              )
            );
          }
        }
      }}
      loading={loading}
      sync={sync}
      hasMore={hasMore}
      contentRange={updatable.length}
      noMoreRender={
        <InfiniteScrollProgressBlock>
          <COREBody
            testID={`${testID}-no-more-content`}
            type={"p3"}
            strong={true}
            marginBottom={false}
          >
            Showing all
          </COREBody>
        </InfiniteScrollProgressBlock>
      }
      emptyRender={
        <COREEmpty
          testID={`${testID}-empty-content`}
          description={"No news and alert found."}
          hint={
            "There are no results for the applied filters. Try expanding your selections to see more results."
          }
        />
      }
      useWindow={!isWidget}
      loader={
        <InfiniteScrollProgressBlock>
          <CORELoading
            testID={`${testID}-loading`}
            delay={0}
            layout={"horizontal"}
            size={"sm"}
          />
        </InfiniteScrollProgressBlock>
      }
    >
      <Row align="top" gutter={[0, 8]}>
        {updatable.map((item, index) => {
          if (!item.data) return null;
          const alertItem = item.data;
          const dateOfCurrentItem = moment(alertItem.alertTimestamp);
          const isItFirstItemForEachDay =
            currentDate === null ||
            !currentDate.isSame(dateOfCurrentItem, "date");

          if (isItFirstItemForEachDay) {
            currentDate = dateOfCurrentItem;
          }

          return (
            <React.Fragment key={`item-${alertItem.id}-${index}`}>
              {isItFirstItemForEachDay && (
                <Col span={24}>
                  <div className={"full-block"}>
                    <COREDivider
                      orientation="left"
                      testID={`${testID}-divider-date` as TestID}
                      space="sm"
                      noOrientationMargin={isItFirstItemForEachDay}
                    >
                      {isTodayDate(dateOfCurrentItem)
                        ? "Today"
                        : isYesterdayDate(dateOfCurrentItem)
                        ? "Yesterday"
                        : formatDate(dateOfCurrentItem.clone().toDate(), {
                            dateStyle: "medium",
                          })}
                    </COREDivider>
                  </div>
                </Col>
              )}
              <Col span={24}>
                <COREDisplayComment
                  testID={`${testID}-alert-item-${alertItem.id}`}
                  title={alertItem.title ?? ""}
                  comment={alertItem.description ?? ""}
                  modifiedAt={moment(alertItem.alertTimestamp)}
                  createdAt={moment(alertItem.alertTimestamp)}
                  isAuthor={false}
                  key={alertItem.id}
                  truncateAfter={isWidget ? 5 : 0}
                  {...(alertItem.sourceUrl && {
                    showButton: (
                      <COREButton
                        type={"text"}
                        target="_blank"
                        href={alertItem.sourceUrl}
                        size={"sm"}
                      >
                        {alertItem.sourceName}
                      </COREButton>
                    ),
                  })}
                  {...(alertItem.alertType === "Market Alert" && {
                    prefixIcon: (
                      <COREIcon
                        color={orange100}
                        icon={icon({
                          name: "circle-exclamation",
                          style: "solid",
                        })}
                      />
                    ),
                  })}
                />
              </Col>
            </React.Fragment>
          );
        })}
      </Row>
    </COREInfiniteScroll>
  );
};

export const NewsAndAlertsCard: React.FC<{
  testID: TestID;
  filterValues?: NewsAndAlertFilters;
  setFilterValues?: (value: NewsAndAlertFilters) => void;
  isWidget?: boolean;
}> = ({ testID, filterValues, setFilterValues, isWidget }) => {
  const { sync, loading, features } = useFeatureFlags();

  if (loading && !sync) return <CORELoading delay={0} />;
  if (!sync) return null;

  const onFilterChange = (value: NewsAndAlertFilters) => {
    setFilterValues && setFilterValues(value);
  };

  const hasPermission = getNewsAndAlertsPermission(features);
  const Content = !hasPermission ? (
    <COREEmpty
      testID={`${testID}-empty-content`}
      description="Missing permissions"
      hint={`You don’t have access to this content.`}
    />
  ) : (
    <TestIDWrapper className="news-and-alert-block" testID={`${testID}-outer`}>
      {setFilterValues && (
        <>
          <NewsAndAlertsFilters
            testID={`${testID}-filters`}
            filterValues={filterValues}
            setFilterValues={onFilterChange}
          />
          <SortButton
            type={filterValues?.orderBy ?? "desc"}
            onClick={(value: Order) => {
              onFilterChange({ orderBy: value });
            }}
          />
        </>
      )}
      <div className="news-and-alert-content-list">
        <NewsAndAlertList
          filterValues={filterValues}
          testID={testID}
          isWidget={isWidget}
        />
      </div>
    </TestIDWrapper>
  );

  return isWidget ? (
    <WidgetPanel
      interactionsMenu={[]}
      testID={`${testID}-widgets`}
      panelTitle={"News and alerts"}
    >
      {Content}
    </WidgetPanel>
  ) : (
    <CORECard testID={`${testID}-card`} hoverable={false}>
      {Content}
    </CORECard>
  );
};
