import React, { useContext, useState } from "react";
import { Col, message, Popconfirm, Result, Row } from "antd";
import "../../../modules/marketPrices/marketPricesComponent/LiveMarketScreen.less";
import { EnviroTable } from "./EnviroTable";
import { useSnapshots } from "../../../shared/customHoooks/useSnapshots";
import { useAPIQuery } from "../../../shared/customHoooks/useAPI";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import moment, { Moment } from "moment";
import { useUserIsAdmin } from "../../../shared/state/user";
import { priceFormatter, volumeFormatter } from "../../../shared/globals";
import { appQueryClient as queryClient } from "../../../shared/state/appQueryClient";
import { useMutation } from "react-query";
import { useCopyToClipboard } from "../../../shared/customHoooks/useCopyToClipboard";
import { CORETextEditor } from "../../../COREDesignSystem/Form/CORETextEditor";
import "./EnvironmentalDailyCurve.less";
import { DEPRECATEDDateFormatContextStandard } from "../../../shared/date/DateFormatContext";
import { generateTestId } from "../../../shared/testids/testids";
import { keysToSnake, spacing } from "../../../shared/global";
import { COREButton } from "../../../COREDesignSystem/Action/COREButton";
import { COREEmpty } from "../../../COREDesignSystem/Content/COREEmpty";
import {
  APIResponse,
  displayAPIError,
  useUpdatable,
} from "../../../shared/customHoooks/useUpdatable";
import { COREError } from "../../../COREDesignSystem/Content/COREError";
import { coreMessage } from "../../../COREDesignSystem/Feedback/COREMessage";
import { COREContainer } from "../../../COREDesignSystem/Layout/COREContainer";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { COREIcon } from "../../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import classNames from "classnames";
import {
  useEnvironProductCurvePivots,
  useEnvironSnapshot,
} from "./useEnvironCurve";
import { NonEmptyRange } from "../../../shared/date/ranges";
import { BasicTrace } from "../../../COREDesignSystem/Chart/COREBasicChart";
import {
  EnvironmentalForwardCurveChart,
  EnvironmentalForwardCurveChartProps,
  EnvironmentalSpotChart,
} from "./EnvironmentalChart";
import { durationRange } from "../../../shared/date/DateTime";
import { ContentWithChartProps } from "../../dashboards/WidgetPanel";
import "./EnviroTable.less";

type status = "published" | "draft";
export type Certificate = "STC" | "VEEC" | "ESC" | "LGC" | "ACCU" | "PRC";

export type commentary = Certificate | "general" | null;
type commentaryData = { certificate: commentary; commentary: string | null };
const dateDBFormat = "YYYY-MM-DD";
export type tableRow = {
  tradableProductId: number;
  product: string;
  nameParts: string[];
  vol: number | null;
  last: number | null;
  curve: number | null;
  change: number | null;
  tradableProductName: string;
  tradeDate: Moment | null;
  isStrip: boolean;
  offerPrice: number | null;
  bidPrice: number | null;
};

export type ForwardCurveDataProps = Pick<tableRow, "tradableProductId"> & {
  name: string;
  price: number;
  shape: commentary;
  term: string;
  tenor: string;
  vintage: number | null;
};

export type EnvironmentalForwardCurveProps = {
  certificate: Certificate;
  date: Moment;
  empty: React.ReactElement;
  isWidget?: boolean;
} & ContentWithChartProps &
  Pick<
    EnvironmentalForwardCurveChartProps,
    "publishedDateArray" | "filterValue" | "onFilterChange"
  >;

export const dateFormat = "DD/MM/YY";

const header = `<tr>
    <th style="color: darkblue;font-weight: 400; text-align: left;">Tenor</th>
    <th style="color: red">Curve</th>
    <th>Last</th>
    <th>Vol</th>
    <th>Trade Date</th>
</tr>`;
const renderRow = (
  { tradableProductName, curve, last, vol, tradeDate }: tableRow,
  i: number
) => {
  const addHeader = i === 0;
  const row = `<tr>
    <td style="color: darkblue;font-weight: bold; text-align: left;">${tradableProductName}</td>
    <td style="color: red;">${priceFormatter(curve, false) ?? ""}</td>
    <td style="font-weight: bold;">${priceFormatter(last, false) ?? ""}</td>
    <td>${volumeFormatter(vol)}</td>
    <td>${tradeDate?.format(dateFormat) ?? ""}</td>
</tr>`;
  return addHeader ? header + row : row;
};

const renderTable = (
  name: Certificate,
  ds: tableRow[],
  commentary: commentaryData["commentary"]
): string =>
  `<h1>INDICATIVE FORWARD ${name} CURVE</h1>${commentary ?? ""}<table>${ds
    .map(renderRow)
    .join("")}</table>`;

const renderTables = (
  general: string | null | undefined,
  tables: {
    certificate: Certificate;
    dataSource: tableRow[];
    commentary: commentaryData["commentary"];
  }[]
): string =>
  `<style>
    h1 {
        font-size: 15pt;
        color: #33335b;
    }

    table {
        font-family: Calibri, serif;
        font-size: 11pt;
        text-align: center;
        border-collapse: collapse
    }

    table th {
        font-weight: bold;
    }

    table td, table th {
        border: black 1px solid;
        font-family: Calibri, serif;
        padding:0 25px;
    }

    table tr:nth-child(odd) {
        background: #a4b4b4;
    }
</style>${general ?? ""} ${tables
    .map(({ certificate, dataSource, commentary }) =>
      renderTable(certificate, dataSource, commentary)
    )
    .join("")}`;

const saveEodData = ({
  html,
  certificate,
  date,
}: {
  html: string;
  certificate: commentary;
  date: Moment;
}): Promise<string> => {
  return queryClient
    .fetchQuery({
      queryKey: [
        "setProductCurveCommentaries",
        {
          action: "setProductCurveCommentaries",
          body: JSON.stringify({
            date: date.format(dateDBFormat),
            commentary: html,
            // eslint-disable-next-line camelcase
            product_class: certificate === "ACCU" ? "carbon" : "environmental",
            // eslint-disable-next-line camelcase
            product_type:
              certificate === "general" ? null : certificate?.toLowerCase(),
          }),
          enabled: true,
        },
      ],
    })
    .then(() => {
      return "Updated success";
    });
};

const EodEditor = ({
  certificate,
  commentaryData,
  date,
  isPublished,
}: {
  certificate: commentary;
  commentaryData: any;
  date: Moment;
  isPublished: boolean;
}) => {
  const isAdmin = useUserIsAdmin();

  const [curVal, setCurVal] = useState(commentaryData);

  const saveMutation = useMutation(saveEodData, {
    onSuccess: (successMessage: string) => {
      queryClient
        .invalidateQueries("productCurveCommentaries")
        .then(() => message.success(successMessage));
    },
    onError: ({ response: { data: err } }: any) => {
      message.error(`Error: ${err.message}`);
      console.error(err.message);
    },
  });

  const handleEditorChange = (e: string, certificate: commentary | null) => {
    if (e === curVal) return null;

    // save mutation
    saveMutation.mutate({ html: e, certificate, date });

    setCurVal(e);
  };

  if ((!isAdmin && curVal) || isPublished)
    return (
      <div
        className={"eod-editor"}
        dangerouslySetInnerHTML={{ __html: curVal }}
      />
    );

  if (isAdmin) {
    return (
      <div className={"eod-editor"}>
        <CORETextEditor
          value={curVal}
          onBlur={(e) => handleEditorChange(e, certificate)}
        />
      </div>
    );
  }

  return null;
};

export const EnvironmentalForwardCurve = ({
  certificate,
  date,
  empty,
  isWidget,
  ...props
}: EnvironmentalForwardCurveProps) => {
  const dateNow: string = moment(date).format();
  const setDurationTime = durationRange["H"];
  const dateRange: NonEmptyRange = {
    from: moment(dateNow).subtract(setDurationTime),
    to: moment(dateNow),
    bounds: "[)",
  };
  const {
    loading,
    error,
    sync,
    data: { data },
  }: {
    loading?: boolean;
    sync?: boolean;
    error: string | null;
    data: {
      data: ForwardCurveDataProps[];
    };
  } = useAPIQuery("getProductCurvePivots", {
    date: date.format(dateDBFormat),
    type: "environmental",
    ...keysToSnake({ isForward: true }),
  });

  if (loading && !sync)
    return (
      <div className={"display-loading-center"}>
        <CORELoading delay={0} layout="horizontal" />
      </div>
    );
  if (!sync) return null;
  if (error) {
    console.error(error);
    return (
      <COREEmpty
        description="Failed to load EOD curves"
        testID="dailycurve-page-load-error-empty"
      />
    );
  }
  const d = data.filter(
    (row) => row.shape === certificate && row.price != null
  );

  const chartData: BasicTrace[] = [
    {
      name: `${certificate} Forward Curve`,
      x: d.map((d) => `${d.tenor} ${d.vintage ?? ""}`.trim()),
      y: d.map((d) => d.price),
      type: "scatter",
    },
  ];

  return (
    <EnvironmentalForwardCurveChart
      chartId={`Forward Curve - ${certificate}`}
      chartName={`${certificate} Forward curve - ${date.format(dateFormat)}`}
      certificate={certificate}
      date={dateRange}
      data={chartData}
      empty={empty}
      isWidget={isWidget}
      {...props}
    />
  );
};

const SpotChart = ({
  certificate,
  date,
}: {
  certificate: Certificate;
  date: Moment;
}) => {
  const dateNow: string = moment(date).format();
  const setDurationTime = durationRange["Q"];
  const dateRange: NonEmptyRange = {
    from: moment(dateNow).subtract(setDurationTime),
    to: moment(dateNow),
    bounds: "[)",
  };
  const {
    loading,
    sync,
    data: spotData,
  }: {
    loading?: boolean;
    sync?: boolean;
    data: {
      data: {
        id: number;
        shortName: string;
        environmentalProduct: null | {
          id: number;
          tenor: null | { name: string };
          name: string;
        };
      }[];
    };
  } = useAPIQuery(
    "tradable_products",
    keysToSnake({
      environmentalProduct: `not.is.null`,
      isStrip: `eq.false`,
      isActive: `eq.true`,
      name: `eq.${certificate} Spot`,
    })
  );

  if (loading && !sync) return <CORELoading size="lg" />;
  if (!sync) return null;

  const spotObjData = spotData?.data
    ?.filter(
      (d) =>
        d.hasOwnProperty("environmentalProduct") &&
        d.environmentalProduct &&
        d.environmentalProduct.tenor?.name === "Spot" &&
        d.environmentalProduct.name === certificate
    )
    .map((item) => {
      return { id: item.id, shortName: item.shortName };
    });

  return (
    <EnvironmentalSpotChart
      chartId={`${certificate} - Spot Price`}
      chartName={`${certificate} Spot price - Quarterly rolling`}
      certificate={certificate}
      date={dateRange}
      data={spotObjData}
      displayPanel={false}
      empty={
        <COREEmpty
          description="No curve data available"
          hint="Try reloading the page. If the problem persists, please contact CORE Markets support."
          testID={generateTestId("dailycurve", "trades-chart-no-data")}
        />
      }
    />
  );
};

const EODCharts = ({
  showForwardPriceCurve,
  certificate,
  date,
}: {
  showForwardPriceCurve: boolean;
  certificate: Certificate;
  date: Moment;
}) => {
  return (
    <Row gutter={[spacing.sm, spacing.sm]} align={"top"}>
      <Col span={24}>
        <SpotChart certificate={certificate} date={date} />
      </Col>
      <Col span={24}>
        {showForwardPriceCurve && (
          <EnvironmentalForwardCurve
            certificate={certificate}
            date={date}
            empty={
              <COREEmpty
                description={"No curve data available"}
                hint={
                  "Try reloading the page. If the problem persists, please contact CORE Markets support."
                }
                testID={generateTestId("dailycurve", "trades-chart-no-data")}
              />
            }
          />
        )}
      </Col>
    </Row>
  );
};

const EODCurveRow = ({
  showForwardPriceCurve,
  commentaryData,
  dataSource,
  certificate,
  date,
  futureDate,
  loadingDate,
  loadingSnapshot,
  isPublished,
}: {
  showForwardPriceCurve: boolean;
  date: Moment;
  certificate: Certificate;
  commentaryData: { certificate: commentary; commentary: string | null };
  dataSource: tableRow[];
  loadingSnapshot: boolean;
  loadingDate: boolean;
  futureDate: Moment;
  isPublished: boolean;
}) => (
  <Row>
    <Col span={24}>
      <COREDivider space="sm" />
    </Col>
    <Col span={24}>
      <Row gutter={[spacing.sm, spacing.sm]} align="top">
        <Col xs={24} xxl={12}>
          <Row gutter={[spacing.sm, spacing.sm]}>
            <Col span={24}>
              <COREContainer
                header={`${certificate} - Daily update`}
                testID="dailycurve-product-commentary-pricing"
                noScroll
              >
                <div className={"environment-daily-curve-table-editable"}>
                  <EodEditor
                    certificate={certificate}
                    date={date}
                    commentaryData={commentaryData}
                    isPublished={isPublished}
                  />
                  <EnviroTable
                    certificate={certificate}
                    dataSource={dataSource}
                    date={date}
                    loading={loadingSnapshot || loadingDate}
                    futureDate={futureDate}
                    isPublished={isPublished}
                  />
                </div>
              </COREContainer>
            </Col>
          </Row>
        </Col>
        <Col xs={24} xxl={12}>
          <EODCharts
            showForwardPriceCurve={showForwardPriceCurve}
            certificate={certificate}
            date={date}
          />
        </Col>
      </Row>
    </Col>
  </Row>
);
type Environmental = {
  productClass: string;
  date: Moment;
  status: string;
};

type EnvironmentalType = {
  status: string;
};

const filterByCurveId = (curvePublishTodayId: number) =>
  curvePublishTodayId && { id: `eq.${curvePublishTodayId}` };

const useEnvironmental = (isAdmin: boolean, curvePublishTodayId: number) =>
  useUpdatable<EnvironmentalType, EnvironmentalType, Environmental>({
    get: [
      "getCurvePublicationStatuses",
      keysToSnake({
        productClass: "eq.environmental",
        ...filterByCurveId,
      }),
      !isAdmin,
    ],
    add: (newItem: Environmental) => ({
      queryKey: [
        "postCurvePublicationStatuses",
        {
          action: "postCurvePublicationStatuses",
          body: JSON.stringify(newItem),
          enabled: true,
        },
      ],
    }),
    update: (newItem: EnvironmentalType) => ({
      queryKey: [
        "patchCurvePublicationStatuses",
        {
          action: "patchCurvePublicationStatuses",
          params: { id: curvePublishTodayId },
          body: JSON.stringify({
            ...newItem,
          }),
          enabled: true,
        },
      ],
    }),
    transformToRichTypes: (record) => record,
    invalidateQueries: ["getCurvePublicationStatuses"],
  });

export const dataSnapshotMap = (snapshots: any, dailyCurves: any) => {
  return snapshots.map((snapshot) => {
    const curve = dailyCurves.find((dailyCurve) => {
      return dailyCurve.tradableProductId === snapshot.tradableProduct.id;
    });

    const last = snapshot?.lastTenIncludingStrip
      ? snapshot.lastTenIncludingStrip[
          snapshot.lastTenIncludingStrip.length - 1
        ]
      : {
          price: snapshot?.lastPrice,
          volume: snapshot?.lastSize,
          time: snapshot?.lastDate,
          // eslint-disable-next-line camelcase
          is_strip: false,
        };
    const {
      price: lastPrice,
      volume: lastSize,
      time: lastDate,
      is_strip: isStrip,
    } = last;
    return {
      tradableProductId: snapshot.tradableProduct.id,
      product: snapshot.name.substr(0, 4).trim(),
      tradableProductName: snapshot.name.substr(4).trim(),
      nameParts: snapshot.nameParts,
      curve: curve?.price,
      last: lastPrice,
      isStrip: isStrip,
      vol: lastSize,
      tradeDate: lastDate === null ? null : moment(lastDate),
      bidPrice: snapshot.bidPrice,
      offerPrice: snapshot.offerPrice,
      change: curve?.change,
    };
  });
};

const getTableData = (ds, commentariesData, certificate: Certificate) => {
  const c = commentariesData.data.find(
    (x) => x.product_type === certificate.toLowerCase()
  );
  return {
    dataSource: ds.filter((row) => row.product.includes(certificate)),
    commentary: c?.commentary,
    certificate,
  };
};

const getAllTable = (ds: any, commentariesData: any) => {
  return [
    {
      showForwardPriceCurve: true,
      ...getTableData(ds, commentariesData, "STC"),
    },
    {
      showForwardPriceCurve: true,
      ...getTableData(ds, commentariesData, "LGC"),
    },
    {
      showForwardPriceCurve: true,
      ...getTableData(ds, commentariesData, "VEEC"),
    },
    {
      showForwardPriceCurve: true,
      ...getTableData(ds, commentariesData, "ESC"),
    },
    {
      showForwardPriceCurve: true,
      ...getTableData(ds, commentariesData, "PRC"),
    },
  ];
};

const getTableForAccu = (ds: any, commentariesData: any) => {
  return [
    {
      showForwardPriceCurve: false,
      ...getTableData(ds, commentariesData, "ACCU"),
    },
  ];
};

const generateTableRows = (
  tables: any,
  date: Moment,
  futureDate: Moment,
  loadingDate: boolean | undefined,
  loadingSnapshot: boolean | undefined,
  isPublished: boolean
) => {
  return tables.map((t) => (
    <EODCurveRow
      key={t.certificate}
      showForwardPriceCurve={t.showForwardPriceCurve}
      certificate={t.certificate}
      date={date}
      commentaryData={t.commentary}
      dataSource={t.dataSource}
      futureDate={futureDate}
      loadingDate={loadingDate === undefined ? true : loadingDate}
      loadingSnapshot={loadingSnapshot === undefined ? true : loadingSnapshot}
      isPublished={isPublished}
    />
  ));
};

const checkIsLoadingForAccu = (
  loadingSnapshotAccu,
  syncSnapshotAccu,
  curvePublishAccuLoading,
  curvePublishAccuSync,
  loadingDailyCurvesDateCarbon,
  syncDailyCurvesDateCarbon,
  commentariesLoadingAccu,
  commentariesSyncAccu
) => {
  return (
    (loadingSnapshotAccu && !syncSnapshotAccu) ||
    (curvePublishAccuLoading && !curvePublishAccuSync) ||
    (loadingDailyCurvesDateCarbon && !syncDailyCurvesDateCarbon) ||
    (commentariesLoadingAccu && !commentariesSyncAccu) ||
    !syncSnapshotAccu ||
    !curvePublishAccuSync ||
    !syncDailyCurvesDateCarbon ||
    !commentariesSyncAccu
  );
};

const checkIsLoading = (
  loadingDailyCurvesDate,
  syncDailyCurvesDate,
  loadingSnapshot,
  syncSnapshot,
  commentariesLoading,
  commentariesSync
) => {
  return (
    (loadingDailyCurvesDate && !syncDailyCurvesDate) ||
    (loadingSnapshot && !syncSnapshot) ||
    (commentariesLoading && !commentariesSync) ||
    !syncDailyCurvesDate ||
    !syncSnapshot ||
    !commentariesSync
  );
};

const CurvesPublishedButton: React.FC<{
  curvePublishTodayId?: number;
  isPublished: boolean;
  curvePublishBtn: string;
  dailyCurveDate: Moment;
  loading: boolean;
  add: ReturnType<typeof useEnvironmental>["add"];
  updatableCurvePublish: ReturnType<typeof useEnvironmental>["updatable"];
}> = ({
  curvePublishTodayId,
  isPublished,
  curvePublishBtn,
  dailyCurveDate,
  loading,
  add,
  updatableCurvePublish,
}) => {
  const status: string = isPublished ? "draft" : "published";
  const insertOrUpdate = curvePublishTodayId
    ? updatableCurvePublish?.update
    : add;
  if (insertOrUpdate === undefined) throw new Error("Unable to save");
  const handleInsertOrUpdate = async () => {
    const res: APIResponse<EnvironmentalType> = await insertOrUpdate({
      ...keysToSnake(
        isPublished
          ? { status: status }
          : {
              productClass: "environmental",
              date: dailyCurveDate.format(),
              status: status,
            }
      ),
    });

    if (res.hasError) {
      coreMessage({
        type: "error",
        content: `Error publish. ${displayAPIError(res.error)}`,
      });
    } else {
      coreMessage({
        type: "success",
        content: `successfully ${isPublished ? "created" : "updated"}`,
      });
    }
  };

  return (
    <Popconfirm
      title={`Are you sure you want to ${curvePublishBtn}?`}
      onConfirm={() => handleInsertOrUpdate()}
      okText="Yes"
      cancelText="No"
    >
      <COREButton type="primary" loading={loading} disabled={loading}>
        {curvePublishBtn}
      </COREButton>
    </Popconfirm>
  );
};

export const EnvironmentalDailyCurve: React.FC<{
  date: Moment;
  status: status;
  curvePublishTodayId: number;
}> = ({ date: dailyCurveDate, status, curvePublishTodayId }) => {
  const isAdmin = useUserIsAdmin();
  // @ts-ignore
  const [, copyToClipboard] = useCopyToClipboard();

  const date = moment(dailyCurveDate);
  const futureDate = date.clone().add(1, "day");
  const {
    data: { data: dataSnapshot },
    loading: loadingSnapshot,
    sync: syncSnapshot,
    error: errorSnapshot,
  } = useEnvironSnapshot({
    futureDate,
  });

  // get curve date
  const {
    loading: loadingDailyCurvesDate,
    error: errorDailyCurvesDate,
    sync: syncDailyCurvesDate,
    data: { data: dailyCurvesData },
  } = useEnvironProductCurvePivots({
    date,
  });

  // get commentaries with curve date
  const {
    loading: commentariesLoading,
    sync: commentariesSync,
    data: commentariesData,
  }: {
    loading?: boolean;
    sync?: boolean;
    data: { data };
  } = useAPIQuery("productCurveCommentaries", {
    date: `eq.${dailyCurveDate}`,
    // eslint-disable-next-line camelcase
    product_class: `eq.environmental`,
  });

  // ----- Load Accu data when IsAdmin -----
  const momentFormatStandard = useContext(DEPRECATEDDateFormatContextStandard);

  const {
    data: { data: dataSnapshotAccu },
    loading: loadingSnapshotAccu,
    sync: syncSnapshotAccu,
    error: errorSnapshotAccu,
  } = useSnapshots({
    asOf: futureDate.format(),
    type: "carbon",
    strip: false,
    genericProductsOnly: true,
    productType: "eq.accu",
    skip: !isAdmin,
    filter: keysToSnake({
      and: `(method_type.is.null,product_type.eq.accu, or(tenor_type.is.null, tenor_type.neq.monthly), or(tenor_type.not.is.null, vintage.is.null))`,
    }),
  });

  // Daily curve date
  const {
    loading: loadingDailyCurvesDateCarbon,
    error: errorDailyCurvesDateCarbon,
    sync: syncDailyCurvesDateCarbon,
    data: { data: dataDailyCurveCarbon },
  }: {
    loading?: boolean;
    sync?: boolean;
    error: string | null;
    data: {
      data: {
        id: number;
        price: number;
        // eslint-disable-next-line camelcase
        environmental_product: null | { id: number };
      }[];
    };
  } = useAPIQuery(
    "getProductCurvePivots",
    {
      date: date.format(momentFormatStandard.format),
      type: "carbon",
      tradable_product_type: "eq.accu", // eslint-disable-line camelcase
      or: "(tradable_product_tenor_type.is.null, tradable_product_tenor_type.neq.monthly)",
    },
    !isAdmin
  );

  // get commentaries with curve ate
  const {
    loading: commentariesLoadingAccu,
    sync: commentariesSyncAccu,
    data: commentariesDataAccu,
  }: {
    loading?: boolean;
    sync?: boolean;
    data: { data };
  } = useAPIQuery(
    "productCurveCommentaries",
    {
      date: `eq.${date.format(momentFormatStandard.format)}`,
      product_class: "eq.carbon", // eslint-disable-line camelcase
      product_type: "eq.accu", // eslint-disable-line camelcase
    },
    !isAdmin
  );
  // ----- Load Accu data when IsAdmin -----

  const {
    loading: curvePublishAccuLoading,
    sync: curvePublishAccuSync,
    updatable: curvePublishAccuData,
    add,
    error,
  } = useEnvironmental(isAdmin, curvePublishTodayId);
  if (error) return <COREError title={error.message} />;

  // check error date and snapshot
  if (
    errorDailyCurvesDate ||
    errorSnapshot ||
    errorSnapshotAccu ||
    errorDailyCurvesDateCarbon
  ) {
    return (
      <COREEmpty
        description={`Failed to load curves for date: ${date}`}
        testID="dailycurve-load-failure-empty"
      />
    );
  }

  const isLoading = checkIsLoading(
    loadingDailyCurvesDate,
    syncDailyCurvesDate,
    loadingSnapshot,
    syncSnapshot,
    commentariesLoading,
    commentariesSync
  );

  const isLoadingAccu = checkIsLoadingForAccu(
    loadingSnapshotAccu,
    syncSnapshotAccu,
    curvePublishAccuLoading,
    curvePublishAccuSync,
    loadingDailyCurvesDateCarbon,
    syncDailyCurvesDateCarbon,
    commentariesLoadingAccu,
    commentariesSyncAccu
  );

  if (isLoading || (isLoadingAccu && isAdmin)) {
    return <CORELoading size="lg" />;
  }

  // check error only snapshot
  if (errorSnapshot) {
    console.error(errorSnapshot);
    return <Result status="error" title="Error loading table data" />;
  }

  // data set from data snapshot
  const ds: tableRow[] = dataSnapshotMap(dataSnapshot, dailyCurvesData);
  const dsAccu: tableRow[] = isAdmin
    ? dataSnapshotMap(dataSnapshotAccu, dataDailyCurveCarbon)
    : [];

  // prepare general commentary
  const generalCommentary = commentariesData.data.find(
    (c) => c.product_type === null
  )?.commentary;

  // prepare table
  const tables = getAllTable(ds, commentariesData);
  const tableAccu = isAdmin
    ? getTableForAccu(dsAccu, commentariesDataAccu)
    : [];

  const isPublished: boolean = status === "published";
  const isPublishedAccu: boolean = curvePublishAccuData
    ? curvePublishAccuData[0]?.status === "published"
    : false;

  const curvePublishBtn: string = isPublished ? "Un-publish" : "Publish";
  // copy table component
  const copyTables = () => {
    const combineTables = [...tables, ...tableAccu];
    const tablesHTML = renderTables(generalCommentary, combineTables);
    // @ts-ignore
    copyToClipboard(tablesHTML, {
      format: "text/html",
    });
    message.success(`Copied to clipboard`);
  };

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[0, spacing.sm]}>
          <Col span={24}>
            {/*Copy button only for Admin*/}
            {isAdmin && (
              <Row gutter={[8, 4]} align="middle">
                <Col flex="auto">
                  <COREButton
                    testID={generateTestId("dailycurve", "copyforemail")}
                    onClick={copyTables}
                    icon={
                      <COREIcon
                        icon={icon({ name: "copy", style: "regular" })}
                      />
                    }
                  >
                    Copy for email
                  </COREButton>
                </Col>
                <Col>
                  <span
                    className={classNames("status-label", {
                      "published-label": isPublished,
                    })}
                    data-testid="eod-status-label"
                  >
                    {isPublished ? "Published" : "Draft"}
                  </span>
                  <CurvesPublishedButton
                    curvePublishTodayId={curvePublishTodayId}
                    isPublished={isPublished}
                    curvePublishBtn={curvePublishBtn}
                    dailyCurveDate={date}
                    loading={curvePublishAccuLoading}
                    add={add}
                    updatableCurvePublish={curvePublishAccuData}
                  />
                </Col>
              </Row>
            )}
          </Col>
          {/*General commentary*/}
          <Col span={24}>
            <COREContainer
              noScroll
              header="General commentary"
              testID="dailycurve-commentary-container"
            >
              <EodEditor
                certificate="general"
                date={date}
                commentaryData={generalCommentary}
                isPublished={isPublished}
              />
            </COREContainer>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        {generateTableRows(
          tables,
          date,
          futureDate,
          loadingDailyCurvesDate,
          loadingSnapshot,
          isPublished
        )}

        {isAdmin &&
          generateTableRows(
            tableAccu,
            date,
            futureDate,
            loadingDailyCurvesDateCarbon,
            loadingSnapshotAccu,
            isPublishedAccu
          )}
      </Col>
    </Row>
  );
};
