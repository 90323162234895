import React, { useState } from "react";
import { CORELoading } from "../../COREDesignSystem/Feedback/CORELoading";
import { CORETreeSelect } from "../../COREDesignSystem/Form/CORETreeSelect";
import { useAPIQueryPagination } from "../customHoooks/useAPI";
import { Alert, Empty, Space, Tag } from "antd";
import {
  generateTree,
  CORETree,
  useCORETree,
  CORETreeData,
} from "../../COREDesignSystem/Form/CORETree";
import { capitaliseEachWord } from "../stringUtil";
import { TradableProductTooltip } from "../tooltips/TradableProductTooltip";
import "./TradableProductTree.less";
import { generateTestId, TestID } from "../testids/testids";
import { Merge } from "../TypeScriptHelpers";
import { certificate_project_types as certificateProjectTypesAdmin } from "../../openapi-typescript/admin";
import { certificate_project_types as certificateProjectTypesApproved } from "../../openapi-typescript/approved";
import { RangeString } from "../date/ranges";
import { CORESwitch } from "../../COREDesignSystem/Form/CORESwitch";
import { CORETypographyInput } from "../../COREDesignSystem/Typography/CORETypographyInput";
import { sortArrayData } from "../global";
import { Snapshot } from "../../openapi-typescript/common/snapshot";

export type CertificateProject =
  | certificateProjectTypesAdmin
  | certificateProjectTypesApproved;

export interface TradableProductType {
  id: number;
  shortName: string;
  vintage: number;
  vintages: RangeString;
  isStrip: boolean;
  isCap: number;
  code: string;
  deliveryYear?: number;
  // eslint-disable-next-line camelcase
  name_parts: string[];
  environmentalProduct: {
    id: number;
    name: string;
    tenor: {
      name: string;
    };
  };
  // eslint-disable-next-line camelcase
  project_method_types: {
    id: number;
    productType: string;
    code: string;
    name: string;
  };
  cfdProduct: {
    id: number;
    shape: {
      region: {
        short_name: string; // eslint-disable-line camelcase
      };
      type: string;
    };
    tenor: {
      name: string;
    };
  };
  certificate_project?: CertificateProject; // eslint-disable-line camelcase
  productClass?: string;
  rehubCode: string;
  snapshots?: Snapshot;
}

type TradableTreeData = {
  name: string;
  tenor: string;
  vintage: string;
  strip: string;
  id: number;
};

type TradableProductTreeNormal = {
  testID: TestID;
  multiple: boolean;
  initialValue?: number[];
  value?: number[];
  isStrip?: boolean;
  selectable?: boolean;
  region?: string;
  hideNonMethodspecificTradableProduct?: boolean;
  search?: boolean;
};

type TradableProductTreeType = Merge<
  TradableProductTreeNormal,
  {
    onChange: Function;
    onlyCurrent: boolean;
    setOnlyCurrent?: (value: boolean) => void;
    showOnlyCurrentToggle: boolean;
    isASXTraded?: boolean;
    tradeType?: "firm" | "option";
    endpoint?: string;
    placeholder?: string;
  }
>;

type TradableProductTreeWithDataType = Merge<
  TradableProductTreeNormal,
  {
    onChange: (e: number[], values: number[], checkedKeys: string[]) => void;
    data: TradableProductType[];
    ref?: React.ForwardedRef<unknown>;
  }
>;

const maxLenValuesRender: number = 3;

export const TradableProductTreeSelect = React.forwardRef(
  (
    {
      onChange,
      onlyCurrent,
      showOnlyCurrentToggle,
      testID,
      ...otherProps
    }: TradableProductTreeType,
    ref
  ) => (
    <CORETreeSelect
      valuesRenderer={(values) =>
        values.slice(0, maxLenValuesRender).map((value, i) => (
          <div key={value} className={"tree-select-item"}>
            <Tag>
              <TradableProductTooltip key={value} id={value} />
            </Tag>
            {values.length > maxLenValuesRender &&
              i + 1 === maxLenValuesRender && (
                <Tag key={"tag:others"}>
                  +{values.length - maxLenValuesRender}{" "}
                </Tag>
              )}
          </div>
        ))
      }
      onChange={onChange}
      ref={ref}
    >
      <TradableProductTree
        testID={testID}
        onlyCurrent={onlyCurrent}
        showOnlyCurrentToggle={showOnlyCurrentToggle}
        onChange={onChange}
        {...otherProps}
      />
    </CORETreeSelect>
  )
);

const TradableProductTreeWithData = ({
  testID,
  data,
  onChange: parentOnChange,
  initialValue,
  multiple,
  value,
  isStrip,
  selectable,
  ref,
  region,
  hideNonMethodspecificTradableProduct,
  ...otherProps
}: TradableProductTreeWithDataType) => {
  const cfdProducts = data
    .filter((d) => d.hasOwnProperty("cfdProduct") && d.cfdProduct)
    .map((cfdp) => ({
      name: cfdp.isCap
        ? cfdp.cfdProduct.shape.type === "flat"
          ? "$300 Cap"
          : capitaliseEachWord(cfdp.cfdProduct.shape.type) + " cap"
        : capitaliseEachWord(cfdp.cfdProduct.shape.type),
      tenor: cfdp.cfdProduct.tenor.name,
      vintage: cfdp.vintage,
      region: cfdp.cfdProduct.shape.region.short_name,
      id: cfdp.id,
    }));
  const cfdTree = generateTree(
    cfdProducts,
    region
      ? ["name", "vintage", "tenor"]
      : ["name", "region", "vintage", "tenor"],
    "id"
  );

  const environmentProducts = data
    .filter((d) => {
      return d.hasOwnProperty("environmentalProduct") && d.environmentalProduct;
    })
    .sort((a, b) => a.id - b.id)
    .map((environment) => ({
      name: environment.environmentalProduct.name,
      tenor: environment.environmentalProduct.tenor.name,
      vintage: environment.vintage,
      strip: environment.isStrip ? "strip" : "non strip",
      id: environment.id,
    }));
  const environmentColumnOrder =
    isStrip === undefined
      ? ["name", "strip", "vintage", "tenor"]
      : ["name", "vintage", "tenor"];
  const environmentTree = generateTree(
    environmentProducts,
    environmentColumnOrder,
    "id"
  );

  const projectMethodTypes = data
    .filter(
      (detail) =>
        detail.hasOwnProperty("project_method_types") &&
        detail.project_method_types
    )
    .map((product) => {
      return {
        name: `${product.environmentalProduct.name} ${product.project_method_types.name}`,
        tenor: product.environmentalProduct.tenor.name,
        vintage: product.vintage,
        strip: product.isStrip ? "strip" : "non strip",
        id: product.id,
      };
    });
  const projectMethodColumnOrder =
    isStrip === undefined
      ? ["name", "strip", "vintage", "tenor"]
      : ["name", "vintage", "tenor"];
  const projectMethodTree = generateTree(
    projectMethodTypes,
    projectMethodColumnOrder,
    "id"
  );

  const treeData: CORETreeData<TradableTreeData>[] =
    hideNonMethodspecificTradableProduct
      ? [...environmentTree, ...cfdTree]
      : [...environmentTree, ...projectMethodTree];
  const treeDataResult = hideNonMethodspecificTradableProduct
    ? treeData
    : sortArrayData(treeData, "asc", "key");
  const tree = useCORETree(treeDataResult as never, {
    onChange: parentOnChange,
    value: value,
    initialRootValues: initialValue,
  });

  return (
    <CORETree
      multiple={multiple}
      selectable={selectable}
      ref={ref || null}
      {...tree}
      {...otherProps}
      testID={`${testID}-tradable-product-tree`}
      searchPlaceholder={"Search products"}
    />
  );
};

export const TradableProductTree = ({
  onlyCurrent: oc = true,
  setOnlyCurrent: parentSetOnlyCurrent,
  showOnlyCurrentToggle = false,
  onChange: parentOnChange,
  isASXTraded,
  isStrip,
  tradeType,
  initialValue,
  multiple,
  value,
  selectable,
  region,
  endpoint,
  hideNonMethodspecificTradableProduct,
  ...otherProps
}: TradableProductTreeType) => {
  const [onlyCurrent, setOnlyCurrent] = useState(oc);
  const isNotNull = "not.is.null";
  const isNull = "is.null";

  let params = {};
  if (onlyCurrent) params["is_active"] = `eq.true`;

  switch (hideNonMethodspecificTradableProduct) {
    case true:
      params["non_methodspecific_tradable_product"] = isNull;
      break;
    case false:
      params["non_methodspecific_tradable_product"] = isNotNull;
      break;
    default:
      break;
  }

  if (isASXTraded !== undefined) params["is_asx_traded"] = `is.${isASXTraded}`;
  if (isStrip !== undefined) params["is_strip"] = `is.${isStrip}`;

  if (endpoint === "cfd") params["cfd_product"] = isNotNull;
  else if (endpoint === "environmental")
    params["environmental_product"] = isNotNull;

  if (tradeType !== undefined) params["trade_type"] = `eq.${tradeType}`;

  if (region) params["region"] = `eq.${region}`;

  params["certificate_project"] = isNull;

  const { data, error, loading, sync } = useAPIQueryPagination(
    "tradable_products",
    { params },
    {
      perPage: 9000,
      shouldLoadAll: true,
    }
  );

  const Tree = (component) => (
    <Space
      direction={"vertical"}
      size={12}
      className={"tree-select-full-width"}
    >
      {showOnlyCurrentToggle && (
        <Space size={8} direction={"horizontal"}>
          <CORESwitch
            size={"lg"}
            testID={generateTestId("tradetracker", "search-box")}
            checked={!onlyCurrent}
            onChange={(e) => {
              setOnlyCurrent(!e);
              parentSetOnlyCurrent && parentSetOnlyCurrent(!e);
            }}
          />
          <CORETypographyInput
            type={"label"}
            testID={generateTestId(
              "tradetracker",
              "show-all-historic-products-label"
            )}
          >
            Show all historic products
          </CORETypographyInput>
        </Space>
      )}
      {component}
    </Space>
  );

  if (loading && !sync) return Tree(<CORELoading size={"lg"} />);
  if (error) {
    console.error(error);
    return Tree(
      <Alert type={"error"} message={"Error loading tradable products"} />
    );
  }

  const hasNoData = data === undefined;
  if (hasNoData) return Tree(<Empty />);

  return Tree(
    <TradableProductTreeWithData
      isStrip={isStrip}
      data={data}
      initialValue={initialValue}
      multiple={multiple}
      value={value}
      selectable={selectable}
      onChange={(e, values, checkedKeys) =>
        parentOnChange(
          e,
          values,
          checkedKeys,
          data.filter((f) => e.includes(f.id))
        )
      }
      hideNonMethodspecificTradableProduct={
        hideNonMethodspecificTradableProduct
      }
      {...otherProps}
    />
  );
};
