import { Moment } from "moment";
import React from "react";
import { TestID } from "../../../shared/testids/testids";
import { DailyCurveTableFilterType } from "../widget/SetupDailyCurveWidget";
import { DailyCurveProductType } from "../../../shared/customHoooks/useProductTypes";
import moment from "moment";
import {
  Certificate,
  dataSnapshotMap,
  tableRow,
} from "./EnvironmentalDailyCurve";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { COREError } from "../../../COREDesignSystem/Content/COREError";
import { EnviroTable } from "./EnviroTable";
import { Col, Row } from "antd";
import { COREDatePicker } from "../../../COREDesignSystem/Form/COREDatePicker";
import { momentFormat } from "../../../shared/date/DateFormatContext";
import {
  useEnvironProductCurvePivots,
  useEnvironSnapshot,
} from "./useEnvironCurve";
import { keysToSnake } from "../../../shared/global";
import { useUserIsAdmin } from "../../../shared/state/user";
import { disabledDate } from "../CarbonDailyCurvePageWrapper";
import { useAPIQuery } from "../../../shared/customHoooks/useAPITypes";
import "./EnviroTable.less";

type CurvePublishData = {
  status: string;
  date: string;
};

export const EnviroTableForWidget: React.FC<{
  productType: DailyCurveProductType;
  testID: TestID;
  widgetFilters?: DailyCurveTableFilterType;
  onWidgetFilterChange?: (filter?: DailyCurveTableFilterType) => void;
}> = ({ productType, testID, widgetFilters, onWidgetFilterChange }) => {
  const isAdmin = useUserIsAdmin();
  // load curve publish
  const {
    loading: curvePublishLoading,
    sync: curvePublishSync,
    data: curvePublishData,
  } = useAPIQuery<CurvePublishData[]>(
    "getCurvePublicationStatuses",
    keysToSnake({ productClass: `eq.environmental`, status: "eq.published" })
  );

  if (curvePublishLoading && !curvePublishSync)
    return (
      <div className={"display-loading-center"}>
        <CORELoading delay={0} layout="horizontal" />
      </div>
    );

  if (!curvePublishSync) return null;

  const publishedDateArray: string[] = curvePublishData
    .filter((d) => d.status === "published")
    .map((d) => d.date)
    .sort();
  const recentPublishedDate = publishedDateArray[publishedDateArray.length - 1];

  const newCurveDate = isAdmin
    ? widgetFilters?.curveDate
    : widgetFilters?.curveDate ?? recentPublishedDate;

  return (
    <EnviroTableWithFilter
      curveDate={moment(newCurveDate).startOf("day")}
      productType={productType}
      testID={testID}
      onWidgetFilterChange={onWidgetFilterChange}
      publishedDateArray={publishedDateArray}
    />
  );
};

export const EnviroTableWithFilter: React.FC<{
  productType: DailyCurveProductType;
  curveDate: Moment;
  testID: TestID;
  onWidgetFilterChange?: (filter?: DailyCurveTableFilterType) => void;
  publishedDateArray?: (string | undefined)[];
}> = ({
  productType,
  curveDate,
  testID,
  onWidgetFilterChange,
  publishedDateArray,
}) => {
  const date = moment(curveDate).clone();
  const futureDate = date.clone().add(1, "day");
  const isAdmin = useUserIsAdmin();
  const {
    data: { data: dataSnapshot },
    loading: loadingSnapshot,
    sync: syncSnapshot,
    error: errorSnapshot,
  } = useEnvironSnapshot({
    futureDate,
    productType,
  });
  const {
    loading: loadingDailyCurvesDate,
    error: errorDailyCurvesDate,
    sync: syncDailyCurvesDate,
    data: { data: dailyCurvesData },
  } = useEnvironProductCurvePivots({
    date: date,
    productType,
  });

  if (errorSnapshot || errorDailyCurvesDate) {
    return <COREError title="Error loading table data" />;
  }

  const isLoading =
    (loadingSnapshot && !syncSnapshot) ||
    (loadingDailyCurvesDate && !syncDailyCurvesDate);
  const dataSource: tableRow[] = isLoading
    ? []
    : dataSnapshotMap(dataSnapshot, dailyCurvesData);

  return (
    <>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <COREDatePicker
            {...(!isAdmin &&
              publishedDateArray && {
                disabledDate: (current: moment.Moment) =>
                  disabledDate(current, publishedDateArray),
              })}
            testID={`${testID}-date-picker-${productType}`}
            size="lg"
            widthSize="lg"
            value={moment(curveDate)}
            onChange={(v) => {
              const dateSelected = moment(v).format(momentFormat.server.format);
              onWidgetFilterChange &&
                onWidgetFilterChange({
                  curveDate: dateSelected,
                });
            }}
            allowClear={false}
          />
        </Col>
        <Col span={24}>
          <CORELoading loading={isLoading} delay={0}>
            <EnviroTable
              certificate={productType.toUpperCase() as Certificate}
              dataSource={dataSource}
              date={date}
              loading={loadingSnapshot || !!loadingDailyCurvesDate}
              futureDate={futureDate}
              isPublished={false}
            />
          </CORELoading>
        </Col>
      </Row>
    </>
  );
};
