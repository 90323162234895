import React, { useRef } from "react";
import { NonEmptyRange } from "../../../shared/date/ranges";
import {
  ChartProps,
  COREAggregatesContainerChart,
  COREAggregatesPanelChart,
  COREBasicContainerChart,
  COREBasicPanelChart,
  PlotRefType,
} from "../../../COREDesignSystem/Chart/ChartHOC";
import { useFeatureFlags } from "../../../shared/customHoooks/useFeatureFlags";
import { CORECard } from "../../../COREDesignSystem/Content/CORECard";
import { generateTestId } from "../../../shared/testids/testids";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { COREError } from "../../../COREDesignSystem/Content/COREError";
import {
  AggregatesChartProps,
  Trace,
} from "../../tools/chartBuilder/AggregatesChartV2";
import {
  BasicChart,
  BasicTrace,
} from "../../../COREDesignSystem/Chart/COREBasicChart";
import { ContentWithChartProps } from "../../dashboards/WidgetPanel";
import { COREDivider } from "../../../COREDesignSystem/Layout/COREDivider";
import { EnvironmentalForwardCurveChartFilter } from "./EnvironmentalChartFilter";
import { BrokerChartFilter } from "../carbonComponent/BrokerChartWithFilter";

export type EnvironmentalChartProps = {
  chartId: string;
  chartName: string;
  certificate: string;
  date: NonEmptyRange;
  displayPanel?: boolean;
  isWidget?: boolean;
  empty: React.ReactElement;
} & Partial<ContentWithChartProps>;

export type EnvironmentalForwardCurveChartProps = EnvironmentalChartProps & {
  publishedDateArray?: (string | undefined)[];
  filterValue?: BrokerChartFilter;
  onFilterChange?: (filter: BrokerChartFilter) => void;
  data: BasicTrace[];
};

export type EnvironmentProductData = {
  id: number;
  shortName: string;
};

const certificationName = "$ / Certificate";

export const EnvironmentalForwardCurveChart: React.FC<
  EnvironmentalForwardCurveChartProps
> = ({
  certificate,
  data,
  date,
  chartId,
  chartName,
  displayPanel,
  isWidget,
  empty,
  setOnDownloadPNG,
  setOnDownloadCSV,
  setOnResetChart,
  setProgressPercent,
  publishedDateArray,
  filterValue,
  onFilterChange,
}) => {
  const chartLegendRef = useRef();
  const plotRef = useRef<PlotRefType>();

  const {
    sync: syncFeatureList,
    loading: loadingFeatureList,
    error: errorFeatureList,
    features: featureList,
  } = useFeatureFlags();

  if (loadingFeatureList && !syncFeatureList)
    return (
      <CORECard
        testID={generateTestId("dailycurve", "loading-forward-chart-panel")}
        hoverable={false}
        elevation="none"
      >
        <CORELoading size="lg" />
      </CORECard>
    );

  if (!syncFeatureList) return null;
  if (errorFeatureList) {
    return <COREError />;
  }

  const chartLayout: AggregatesChartProps["layout"] = {
    xaxis: {
      title: {
        text: "Time Starting",
      },
    },
    yaxis: {
      showline: true,
      title: {
        text: certificationName,
      },
    },
    margin: {
      t: 20,
      l: 60,
      r: 0,
      b: 80,
    },
  };

  const displayDownloadCsvButton =
    featureList &&
    featureList.includes(`chart-builder-csv-environmentalProductTree`);

  const args = {
    chartId: chartId,
    chartTitle: chartName,
    traces: data,
    chartFilter: {
      hasTodayBreak: false,
      todayDate: date.from.format(),
    },
    adjustHeightOfChart: true,
    enableEmptyIcon: true,
    legendFilter: true,
    showlegend: false,
    testID: generateTestId("dailycurve", `${certificate}-trades-chart`),
    layout: chartLayout,
    empty: empty,
  };

  if (isWidget)
    return (
      <>
        <EnvironmentalForwardCurveChartFilter
          testID={generateTestId(
            "dailycurve",
            `${certificate}-trades-chart-filter`
          )}
          filterValue={filterValue}
          onFilterChange={onFilterChange}
          publishedDateArray={publishedDateArray}
        />
        <COREDivider dashed space="md" />
        <BasicChart
          {...args}
          chartLegendRef={chartLegendRef}
          plotRef={plotRef}
          chartPanel
          downloadPng={false}
          downloadCsv={false}
          setOnDownloadPNG={setOnDownloadPNG}
          setOnDownloadCSV={setOnDownloadCSV}
          setOnResetChart={setOnResetChart}
          setProgressPercent={setProgressPercent}
        />
      </>
    );

  return (
    <>
      {displayPanel ? (
        <COREBasicPanelChart
          downloadPng={true}
          downloadCsv={displayDownloadCsvButton}
          {...args}
        />
      ) : (
        <COREBasicContainerChart
          downloadPng={true}
          downloadCsv={true}
          {...args}
        />
      )}
    </>
  );
};

export const EnvironmentalSpotChart: React.FC<
  EnvironmentalChartProps & { data: EnvironmentProductData[] }
> = ({
  certificate,
  data,
  date,
  chartId,
  chartName,
  displayPanel = true,
  empty,
}) => {
  const {
    sync: syncFeatureList,
    loading: loadingFeatureList,
    error: errorFeatureList,
    features: featureList,
  } = useFeatureFlags();

  if (loadingFeatureList && !syncFeatureList)
    return (
      <CORECard
        testID={generateTestId("dailycurve", "loading-spot-chart-panel")}
        hoverable={false}
        elevation="none"
      >
        <CORELoading size="lg" />
      </CORECard>
    );

  if (!syncFeatureList) return null;
  if (errorFeatureList) {
    return <COREError />;
  }

  const chartTraces: Trace[] = data.map((t) => ({
    aggFunction: "avg",
    aggPeriod: "half-hour",
    dateRange: {
      isRelative: false,
      ...date,
    },
    type: "scatter",
    axis: {
      side: "left",
      title: certificationName,
    },
    columnName: "curve",
    tableName: "trading",
    title: {
      text: t.shortName,
    },
    name: t.shortName,
    selectedValue: t.id ? t.id.toString() : "",
  }));

  const chartLayout: AggregatesChartProps["layout"] = {
    xaxis: {
      title: {
        text: "Time Starting",
      },
    },
    yaxis: {
      showline: true,
      title: {
        text: certificationName,
      },
    },
    margin: {
      t: 20,
      l: 60,
      r: 0,
      b: 80,
    },
  };

  const displayDownloadCsvButton =
    featureList &&
    featureList.includes(`chart-builder-csv-environmentalProductTree`);

  const chartProps: Omit<
    AggregatesChartProps & ChartProps,
    "size" | "downloadCsv"
  > = {
    chartId: chartId,
    chartTitle: chartName,
    traces: chartTraces,
    chartFilter: {
      hasTodayBreak: false,
      todayDate: date.from.format(),
    },
    layout: chartLayout,
    adjustHeightOfChart: true,
    enableEmptyIcon: true,
    legendFilter: true,
    showlegend: false,
    testID: generateTestId("dailycurve", `${certificate}-spot-price-chart`),
    empty: empty,
  };

  return (
    <>
      {displayPanel ? (
        <COREAggregatesPanelChart
          {...chartProps}
          downloadPng={true}
          downloadCsv={displayDownloadCsvButton}
        />
      ) : (
        <COREAggregatesContainerChart
          {...chartProps}
          downloadPng={true}
          downloadCsv={true}
        />
      )}
    </>
  );
};
