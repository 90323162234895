import {
  TableOptions,
  Updatables,
  useUpdatables,
  useUpdatablesWithDatePaginate,
} from "./useUpdatable";
import { buildQuerySelect, keysToCamelCase, keysToSnake } from "../global";
import { NewsAndAlert } from "../../openapi-typescript/common/newsAndAlert";
import { NewsAndAlertFilters } from "../../modules/admin/contentManagement/newsAndAlerts/NewsAndAlertsTableWithFilters";
import {
  dateFormatWithTimeZone,
  formatToLocalDateTimeFilter,
} from "../date/DateTime";
import { convertRelativeRangeToNonEmptyRange } from "../../COREDesignSystem/Form/CORERangePicker";
import { NonEmptyRange } from "../date/ranges";
import { DurationInputArg2 } from "moment";

type FilterItems = {
  id?: number;
  skip?: boolean;
} & NewsAndAlertFilters;

const newsAndAlertsArgs = (args?: FilterItems): Updatables<NewsAndAlert> => {
  return {
    get: [
      "getCMSNewsAndAlert",
      {
        select:
          "*," +
          buildQuerySelect({
            categoryObjects: "alert_categories(category)",
          }),
        ...keysToSnake({ deletedAt: `is.null` }),
        order: args?.orderBy
          ? `alert_timestamp.${args.orderBy}`
          : "alert_timestamp.desc",
        ...(args && buildParams(args)),
      },

      args?.skip,
    ],
    add: (newItem) => ({
      queryKey: [
        "addCMSNewsAndAlert",
        {
          action: "addCMSNewsAndAlert",
          enabled: true,
          body: JSON.stringify(newItem),
        },
      ],
    }),
    update: (newItem, oldItem) => ({
      queryKey: [
        "updateCMSNewsAndAlert",
        {
          action: "updateCMSNewsAndAlert",
          enabled: true,
          body: JSON.stringify({
            ...newItem,
          }),
          params: {
            id: `eq.${oldItem.id}`,
          },
        },
      ],
    }),
    delete: (item) => ({
      queryKey: [
        "deleteCMSNewsAndAlert",
        {
          action: "deleteCMSNewsAndAlert",
          enabled: true,
          params: {
            id: `eq.${item.id}`,
          },
        },
      ],
    }),
    transformToRichTypes: (record) => {
      return {
        ...keysToCamelCase(record),
        categoryIds: record.categoryObjects?.map((i) => i.category),
      };
    },
    invalidateQueries: ["getCMSNewsAndAlert"],
  };
};

const buildParams = ({
  id,
  alertTypes,
  categories,
  alertTimestamp,
}: FilterItems) => {
  return {
    ...keysToSnake({
      ...(id && { id: `eq.${id}` }),
      ...(alertTypes &&
        alertTypes.length > 0 && {
          alertType: `in.(${alertTypes.join()})`,
        }),
    }),
    ...(categories &&
      categories.length > 0 && {
        "categoryObjects.category": `in.(${categories.join()})`,
        categoryObjects: `not.is.null`,
      }),
    ...(alertTimestamp && {
      and: formatToLocalDateTimeFilter(
        convertRelativeRangeToNonEmptyRange(alertTimestamp),
        "alert_timestamp",
        dateFormatWithTimeZone
      ),
    }),
  };
};

export const useNewsAndAlertsWithDatePaginate = (
  args?: FilterItems,
  tableOption?: TableOptions,
  loadedRange?: NonEmptyRange,
  unit?: DurationInputArg2
) => {
  return useUpdatablesWithDatePaginate<NewsAndAlert>(
    newsAndAlertsArgs(args),
    "alertTimestamp",
    tableOption,
    loadedRange,
    unit
  );
};

export const useNewsAndAlerts = (
  args?: FilterItems,
  tableOptions?: TableOptions
) => {
  return useUpdatables<NewsAndAlert>(newsAndAlertsArgs(args), tableOptions);
};

export const useNewsAndAlert = (id?: number) => {
  const { updatable: updatables, ...rest } = useNewsAndAlerts({
    id: id,
    skip: !id,
  });
  const updatableItem = updatables?.find((i) => i.data?.id === id);

  return {
    ...rest,
    updatable: updatableItem,
  };
};
