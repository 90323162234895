/* eslint-disable */
import React, { useRef, useState } from "react";
import { CORECard } from "../../../COREDesignSystem/Content/CORECard";
import { generateTestId } from "../../../shared/testids/testids";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { COREEmpty } from "../../../COREDesignSystem/Content/COREEmpty";
import { Col, Row } from "antd";
import { COREButton } from "../../../COREDesignSystem/Action/COREButton";
import { ModalFeedback } from "../../pages/feedback/ModalFeedback";
import { ProductType } from "../ProjectMarketplacePageWrapper";
import { useFeatureFlags } from "../../../shared/customHoooks/useFeatureFlags";
import { COREError } from "../../../COREDesignSystem/Content/COREError";
import {
  getLabelFromProduct,
  isCandleStickChart,
  Product,
  ZoomLevel,
} from "./PriceHistoryChart";
import {
  AggregatesChartProps,
  AggregatesChartV2,
  getTodayDate,
  Trace,
} from "../chartBuilder/AggregatesChartV2";
import moment from "moment/moment";
import { PlotType } from "plotly.js/dist/plotly-myPlotly.js";
import {
  ChartProps,
  COREAggregatesPanelChart,
  PlotRefType,
} from "../../../COREDesignSystem/Chart/ChartHOC";
import { COREIcon } from "../../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ContentWithChartProps } from "../../dashboards/WidgetPanel";

export type PriceHistoryProps = {
  productType: ProductType;
  zoomLevel: ZoomLevel;
  products?: Product[];
  empty: React.ReactElement;
  isWidget: boolean;
} & Partial<ContentWithChartProps>;

const chartLayout = (type: ProductType): AggregatesChartProps["layout"] => {
  const config = {
    height: 450,
    modebar: {
      remove: ["lasso", "select"],
    },
    xaxis: {
      autorange: true,
      showgrid: true,
      title: {
        text: "Time Period",
        family: "Lato",
        size: 16,
      },
      rangeslider: { visible: false },
    },
    yaxis: {
      domain: [0.1, 1],
      showticklabels: false,
      autorange: true,
      title: {
        text: "$ / Certificate",
        family: "Lato",
        size: 16,
      },
      showline: true,
      showgrid: false,
    },
    showlegend: false,
    hoverlabel: {
      bgcolor: "#232848",
      font: {
        color: "#FFF",
      },
    },
    margin: {
      t: 0,
      l: 35,
      r: 0,
      b: 20,
    },
  };

  if (isCandleStickChart(type)) {
    config["yaxis"]["domain"] = [0.45, 1];
    config["yaxis"]["title"]["text"] = "OHLC";
    config["yaxis2"] = {
      domain: [0, 0.3],
      showticklabels: false,
      showgrid: true,
      showline: true,
      autorange: true,
      title: {
        text: "Volume",
        family: "Lato",
        size: 16,
      },
    };
  }

  return config;
};

const getChartTraces = (
  date: { from: moment.Moment; to: moment.Moment },
  type: ProductType,
  products?: Product[]
): Trace[] => {
  const aggFunc: string[] = isCandleStickChart(type)
    ? ["first", "max", "min", "last", "sum"]
    : ["avg"];
  const candleStickTrace: Trace[] = [];

  aggFunc.forEach((d) => {
    products?.forEach((product: Product) => {
      const title = getLabelFromProduct(product);
      let chartType = "candlestick";
      let columnName = "trade_price";
      if (d === "sum") {
        chartType = "bar";
        columnName = "trade_volume";
      }
      if (d === "avg") {
        chartType = "scatter";
        columnName = "curve";
      }
      candleStickTrace.push({
        aggFunction: d,
        aggPeriod: "day",
        dateRange: {
          isRelative: false,
          bounds: "[)",
          ...date,
        },
        type: chartType as PlotType,
        axis: {
          side: "left",
          title: "MW",
        },
        name: "",
        title: { text: title },

        columnName: columnName,
        tableName: "trading",
        selectedValue: product.id,
        convertDataTo: "positive",
      });
    });
  });
  return candleStickTrace;
};

export const PriceHistoryChartPanel: React.FC<PriceHistoryProps> = ({
  productType,
  zoomLevel,
  products,
  empty,
  isWidget,
  setOnDownloadPNG,
  setOnResetChart,
  setProgressPercent,
}) => {
  const [feedbackModal, setFeedbackModal] = useState<boolean>(false);
  const chartRef = useRef(null);
  const chartLegendRef = useRef();
  const plotRef = useRef<PlotRefType>();
  const titleRef = useRef<HTMLDivElement>(null);

  const {
    sync: syncFeatureList,
    loading: loadingFeatureList,
    error: errorFeatureList,
    features: featureList,
  } = useFeatureFlags();

  if (loadingFeatureList && !syncFeatureList)
    return (
      <CORECard
        testID={generateTestId("projectmarketplace", "loading-chart-panel")}
        hoverable={false}
        elevation={"none"}
      >
        <CORELoading size={"lg"} />
      </CORECard>
    );

  if (!syncFeatureList) return null;
  if (errorFeatureList) {
    return <COREError />;
  }

  const todayDate: string = getTodayDate("none").format();

  const dateRange = {
    from: moment(todayDate).subtract(zoomLevel),
    to: moment(todayDate),
  };

  if (products && products.length <= 0)
    return (
      <CORECard
        testID={generateTestId("projectmarketplace", "no-select-chart-panel")}
        hoverable={false}
        elevation={"none"}
      >
        <COREEmpty
          description="No current price selection"
          hint="Make a selection from Type dropdown to display its price history"
          testID={generateTestId("projectmarketplace", "no-select-chart")}
        />
      </CORECard>
    );

  if (
    !featureList.includes("daily-curves-carbon") &&
    !isCandleStickChart(productType)
  )
    return (
      <CORECard
        testID={generateTestId(
          "projectmarketplace",
          "no-permission-chart-panel"
        )}
        hoverable={false}
        elevation={"none"}
      >
        <COREEmpty
          description="No chart available"
          hint="Add Daily Curves to your CORE Markets subscription to gain insights into this product's historical price data and analysis"
          testID={generateTestId("projectmarketplace", "no-permission-chart")}
        />
        <Row justify="center">
          <Col>
            <COREButton
              onClick={() => setFeedbackModal(true)}
              icon={
                <COREIcon icon={icon({ name: "envelope", style: "regular" })} />
              }
              type="primary"
            >
              Contact us
            </COREButton>
          </Col>
        </Row>
        <ModalFeedback
          isFeedbackModalOpen={feedbackModal}
          setIsFeedbackModalOpen={setFeedbackModal}
        />
      </CORECard>
    );

  const displayDownloadCsvButton =
    featureList &&
    featureList.includes(`chart-builder-csv-environmentalProductTree`);
  const chartProps: Omit<AggregatesChartProps & ChartProps, "size"> = {
    chartId: "project marketplace - price history",
    traces: getChartTraces(dateRange, productType, products),
    chartFilter: {
      hasTodayBreak: false,
      todayDate: todayDate,
    },
    downloadCsv: false,
    adjustHeightOfChart: true,
    downloadPng: displayDownloadCsvButton,
    layout: chartLayout(productType),
    testID: generateTestId(
      "projectmarketplace",
      "project-marketplace-price-history"
    ),
    hoverColor: "#00E9AC",
    showlegend: !isCandleStickChart(productType),
    empty: empty,
    chartTitle: `${dateRange.from.format(
      "MMM D, YYYY"
    )} - ${dateRange.to.format("MMM D, YYYY")}`,
  };

  if (isWidget)
    return (
      <AggregatesChartV2
        {...chartProps}
        chartLegendRef={chartLegendRef}
        plotRef={plotRef}
        ref={chartRef}
        chartPanel
        downloadPng={false}
        setOnDownloadPNG={setOnDownloadPNG}
        setOnResetChart={setOnResetChart}
        setProgressPercent={setProgressPercent}
      />
    );

  return (
    <COREAggregatesPanelChart
      {...chartProps}
      roundCorner
      setOnDownloadPNG={setOnDownloadPNG}
      setOnResetChart={setOnResetChart}
      setProgressPercent={setProgressPercent}
      ref={chartRef}
      plotRef={plotRef}
      chartLegendRef={chartLegendRef}
    />
  );
};
