import React, { useRef } from "react";
import { generateTestId } from "../../../shared/testids/testids";
import {
  AggregatesChartProps,
  AggregatesChartV2,
  Trace,
} from "../../tools/chartBuilder/AggregatesChartV2";
import { TabData } from "./CertificateCurve";
import { CertificateCurveData } from "../CarbonDailyCurvePageWrapper";
import {
  ChartProps,
  COREAggregatesContainerChart,
  COREAggregatesPanelChart,
  PlotRefType,
} from "../../../COREDesignSystem/Chart/ChartHOC";
import { useFeatureFlags } from "../../../shared/customHoooks/useFeatureFlags";
import { NonEmptyRange } from "../../../shared/date/ranges";
import { ContentWithChartProps } from "../../dashboards/WidgetPanel";
import { CORECard } from "../../../COREDesignSystem/Content/CORECard";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { COREError } from "../../../COREDesignSystem/Content/COREError";

export type BrokerChartProps = {
  data: TabData;
  productType: CertificateCurveData["productType"];
  productTypeName: CertificateCurveData["productTypeName"];
  date: NonEmptyRange;
  displayByPanel?: boolean;
  empty: React.ReactElement;
  isWidget: boolean;
} & Partial<ContentWithChartProps>;

export const BrokerChart: React.FC<BrokerChartProps> = ({
  data,
  productType,
  productTypeName,
  date,
  displayByPanel = true,
  empty,
  isWidget,
  setOnDownloadPNG,
  setOnDownloadCSV,
  setOnResetChart,
  setProgressPercent,
}) => {
  const chartRef = useRef(null);
  const chartLegendRef = useRef();
  const plotRef = useRef<PlotRefType>();
  const chartRefName = `${productTypeName} - ${data.name}`;

  const {
    sync: syncFeatureList,
    loading: loadingFeatureList,
    error: errorFeatureList,
    features: featureList,
  } = useFeatureFlags();

  if (loadingFeatureList && !syncFeatureList)
    return (
      <CORECard
        testID={generateTestId("carbondailycurve", "loading-chart-panel")}
        hoverable={false}
        elevation={"none"}
      >
        <CORELoading size={"lg"} />
      </CORECard>
    );

  if (!syncFeatureList) return null;
  if (errorFeatureList) {
    return <COREError />;
  }

  const chartTraces: Trace[] = data.tradableProduct.map((t) => ({
    aggFunction: "avg",
    aggPeriod: "none",
    dateRange: {
      isRelative: false,
      ...date,
    },
    type: "scatter",
    axis: {
      side: "left",
      title: "$ / Certificate",
    },
    columnName: "curve",
    tableName: "trading",
    title: {
      text: t.shortName,
    },
    name: t.shortName,
    selectedValue: t.id ? t.id.toString() : "",
  }));

  const chartLayout: AggregatesChartProps["layout"] = {
    xaxis: {
      title: {
        text: "Time Starting",
      },
    },
    yaxis: {
      showline: true,
      title: {
        text: "$ / Certificate",
      },
    },
    margin: {
      t: 20,
      l: 60,
      r: 0,
      b: 45,
    },
  };

  const displayDownloadCsvButton =
    featureList &&
    featureList.includes(`chart-builder-csv-environmentalProductTree`);

  const chartProps: Omit<
    AggregatesChartProps & ChartProps,
    "size" | "downloadCsv"
  > = {
    chartId: chartRefName,
    chartTitle: chartRefName,
    traces: chartTraces,
    chartFilter: {
      hasTodayBreak: false,
      todayDate: date.from.format(),
    },
    layout: chartLayout,
    adjustHeightOfChart: true,
    enableEmptyIcon: true,
    legendFilter: true,
    testID: generateTestId("carbondailycurve", `broker-chart-${productType}`),
    empty: empty,
  };

  if (isWidget)
    return (
      <AggregatesChartV2
        {...chartProps}
        ref={chartRef}
        chartLegendRef={chartLegendRef}
        plotRef={plotRef}
        chartPanel
        downloadPng={false}
        downloadCsv={false}
        setOnDownloadPNG={setOnDownloadPNG}
        setOnDownloadCSV={setOnDownloadCSV}
        setOnResetChart={setOnResetChart}
        setProgressPercent={setProgressPercent}
      />
    );

  return (
    <>
      {displayByPanel ? (
        <COREAggregatesPanelChart
          {...chartProps}
          downloadPng={true}
          downloadCsv={displayDownloadCsvButton}
        />
      ) : (
        <COREAggregatesContainerChart
          {...chartProps}
          downloadPng={true}
          downloadCsv={true}
        />
      )}
    </>
  );
};
